.p-access {
  &-panel {
    font-family: $sans-serif;
    border: 2px solid $color-blue;
    height: 200px;

    @include view-at(sp) {
      height: auto;
    }

    &-contents {
      padding: 30px 24px;
      display: flex;

      flex-direction: column;
      height: 100%;

      @include view-at(sp) {
        padding: 20px 16px;
        height: auto;
      }
    }

    &-h {
      font-size: rem(24px);
      line-height: 1.5;
      margin-bottom: 10px;

      @include view-at(sp) {
        font-size: rem(18px);
      }
    }

    &.icon {
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 200px;
        height: 160px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-position: bottom right;
        background-size: contain;
        background-repeat: no-repeat;

        @include view-at(sp) {
          width: 150px;
          height: 110px;
        }
      }

      &--car {
        &::after {
          background-image: url(../images/common/icon-car.png);
        }
      }

      &--train {
        &::after {
          background-image: url(../images/common/icon-train.png);
        }
      }
    }
  }
}
