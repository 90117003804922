.logistics {
  &-nav {
    margin-bottom: 50px;

    @include view-at(sp) {
      margin-bottom: 25px;
    }

    &-list {
      display: flex;
      justify-content: space-between;
      margin: -10px;

      @include view-at(sp) {
        flex-wrap: wrap;
        margin: -8px;
      }

      > li {
        width: 100%;
        padding: 10px;

        @include view-at(sp) {
          width: 50%;
          padding: 8px;
        }

        a {
          display: block;
          color: $color-white;
          letter-spacing: 0.02em;
          padding: 10px;
          background: #999;
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }

  &-tab {
    &-nav {
      display: flex;
      justify-content: center;

      &-item {
        width: 300px;
        margin-right: 50px;

        &.current {
          a {
            color: $color-blue;
            background-color: $color-white;
            box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1);
          }
        }

        @include view-at(sp) {
          width: calc(100% / 3);
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          display: block;
          font-size: rem(20px);
          line-height: 1.2;
          padding: 20px;
          background-color: $color-gray-snow;
          text-align: center;
          text-decoration: none;
          color: $color-white;

          @include view-at(sp) {
            font-size: rem(14px);
            padding: 12px 8px;
          }

          &[aria-selected="true"] {
            color: $color-blue;
            background-color: $color-white;
            box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    &-contents {
      padding: 50px;
      background-color: $color-white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

      @include view-at(sp) {
        padding: 25px;
      }

      &-item {
        display: none;

        &[aria-hidden="false"] {
          display: block;
        }
      }
    }
  }

  &-section {
    margin-bottom: 50px;

    &-title {
      font-size: rem(18px);
      line-height: 1.2;
      padding-bottom: 0.5em;
      border-bottom: 1px solid $color-blue;
      margin-bottom: 20px;
    }
  }

  &-link {
    display: block;

    &-image {
      position: relative;
      margin-bottom: 10px;

      img {
        display: block;
      }

      &-pickup {
        width: 70px;
        position: absolute;
        top: -16px;
        right: -10px;

        @include view-at(sp) {
          width: 45px;
        }
      }
    }

    &-name {
      text-align: left;
      word-break: break-all;

      &::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7.5px 0 7.5px 10px;
        border-color: transparent transparent transparent $color-blue;
        margin-right: 0.5em;
      }
    }
  }

  &-map {
    width: 100%;
    height: 500px;
    // background: $color-blue;

    &-list {
      border-top: 1px solid $color-gray;

      > li {
        padding: 0.5em 0;
        line-height: 1.2;
        border-bottom: 1px solid $color-gray;
        position: relative;
        counter-increment: number;

        &::before {
          content: counter(number, decimal-leading-zero);
          margin-right: 0.5em;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 1em;
          height: 1em;
          background-image: url(../images/logistics/icon_access.png);
          background-position: center center;
          background-repeat: no-repeat;
          margin-left: 1em;
          position: absolute;
          top: 0.5em;
          right: 0;
        }

        a {
          span {
            margin-right: 0.5em;
          }
        }
      }

      > li {
        &.undone {
          &::after {
            background-image: url(../images/logistics/icon_access--undone.png);
          }
        }
      }
    }

    &-icon {
      position: relative;
      padding-left: 1.5em;
      &::before {
        content: "";
        display: block;
        width: 1em;
        height: 1em;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
      }
      &.blue {
        &::before {
          background-image: url(../images/logistics/icon_access.png);
        }
      }
      &.red {
        &::before {
          background-image: url(../images/logistics/icon_access--undone.png);
        }
      }
    }
  }

  &-popup {
    width: 320px;
    background: $color-blue;

    @include view-at(sp) {
      width: 100%;
    }

    &-image {
      width: 320px;

      @include view-at(sp) {
        width: 100%;
      }

      img {
        display: block;
        width: 320px;
        height: auto;

        @include view-at(sp) {
          width: 100%;
        }
      }
    }

    &-contents {
      padding: 16px 8px;
      color: $color-white;
      text-align: center;

      h3 {
        font-size: rem(18px);
        letter-spacing: 0;
        margin-bottom: 0;
      }
    }

    &-button {
      display: inline-block;
      font-size: rem(14px);
      color: $color-blue;
      background: $color-white;
      border-radius: 20px;
      padding: 0 20px;
      line-height: 1.5;
      margin-top: 0.5em;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.gm-style .gm-style-iw-c {
  background: $color-blue;
  border-radius: 0;
  padding: 0;
}

.gm-style .gm-style-iw-c .gm-style-iw-chr {
  display: none;
}

.gm-style .gm-style-iw-t::after {
  background: linear-gradient(
    45deg,
    rgba(1, 64, 153, 1) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
}
.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}
