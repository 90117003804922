.top {
  &-keyvisual {
    position: relative;

    @include view-at(tab) {
      margin-top: 50px;
    }

    &-wrapper {
      max-width: 1920px;
      margin: 0 auto;
    }
  }

  &-search {
    width: 60%;
    height: 140px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $color-blue;
    border-radius: 0 100px 100px 0;

    @include view-at(tab) {
      position: static;
      width: 100%;
      height: auto;
      border-radius: 0;
    }

    &-contents {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 15px 2vw;

      @include view-at(tab) {
        padding: 15px;
      }

      @include view-at(sp) {
        flex-wrap: wrap;
      }
    }

    &-item {
      flex-grow: 1;
      width: 35%;
      margin-right: 20px;

      @include view-at(tab) {
        width: 100%;
        margin-right: 0;
      }
    }

    &-title {
      color: $color-white;
      padding-left: 15%;
      font-size: rem(18px);
      line-height: 1.5;

      @include view-at(sp) {
        padding-left: 0;
        text-align: center;
        margin-bottom: 10px;
      }

      &.large {
        font-size: rem(28px);
        border-bottom: 1px solid $color-white;
        padding-bottom: 10px;
        margin-bottom: 20px;

        @include view-at(sp) {
          font-size: rem(24px);
          margin-bottom: 10px;
        }
      }
    }

    &-nav {
      flex-grow: 1;
      width: 65%;

      &-list {
        display: flex;

        &-item {
          a {
            display: block;
            font-size: rem(14px);
            color: $color-white;
            text-align: center;

            &:hover {
              text-decoration: none;
            }

            img {
              width: 75%;
            }

            @include view-at(tab) {
              img {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  &-h2 {
    font-size: rem(24px);
    line-height: 1.5;
    letter-spacing: 0.05em;
    padding-left: 5%;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-black;
    margin-bottom: 20px;

    span {
      font-size: rem(16px);
      padding-left: 1.5em;
    }
  }

  &-cta {
    display: flex;

    @include view-at(tab) {
      flex-wrap: wrap;
    }

    &-item {
      width: 50%;

      @include view-at(tab) {
        width: 100%;
      }

      &--left {
        padding-right: 15px;

        .top-cta-contents {
          padding-left: 100px;
          border-radius: 0 50px 50px 0;
          background-image: url(../images/top/mflp-ichikawashiohama.png);
          background-position: center center;

          @include view-at(sp) {
            padding-left: 15px;
            height: auto;
          }
        }
      }
      &--right {
        padding-left: 15px;

        .top-cta-contents {
          background-color: #003d8b;
          padding-right: 100px;
          border-radius: 50px 0 0 50px;

          @include view-at(sp) {
            padding-right: 15px;
          }
        }
      }
    }

    &-contents {
      color: $color-white;
      background: $color-gray;
      height: 350px;
      padding: 30px 0 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include view-at(sp) {
        height: auto;
        padding: 20px 15px;
      }

      .top-h2 {
        border-color: $color-white;
      }

      &-inner {
        // height: 100%;
        padding-left: 5%;
        padding-right: 100px;

        @include view-at(sp) {
          padding-right: 15px;
        }
      }
    }

    &-tel {
      display: inline-block;
      font-size: rem(48px);
      line-height: 1.5;
      color: $color-white;
      margin-bottom: 20px;
      pointer-events: none;

      &:hover {
        text-decoration: none;
      }

      @include view-at(sp) {
        font-size: rem(32px);
        pointer-events: painted;
      }
    }

    &-button {
      display: inline-block;
      font-size: rem(24px);
      line-height: 1.5;
      color: $color-white;
      padding: 8px 32px;
      background: #000f4c;
      border-radius: 30px;
      box-shadow: 0px 0 3px rgba(0, 0, 0, 0.5);

      &:hover {
        text-decoration: none;
      }

      @include view-at(sp) {
        font-size: rem(20px);
      }
    }
  }

  &-pickup {
    &-card {
      display: block;
      color: $color-black;
      transition: all 0.3s;

      img {
        display: block;
      }

      &:hover {
        color: $color-black;
        text-decoration: none;
      }

      &-contents {
        padding: 15px 25px;
        background: $color-white;
        position: relative;
      }

      &-inner {
        position: relative;
      }

      &-title {
        font-size: rem(20px);
        line-height: 1.5;
        margin-bottom: 10px;
      }
    }
    &-eyecatch {
      position: absolute;
      bottom: -20%;
      right: 15px;

      @include view-at(tab) {
        width: 30%;
        bottom: -10%;
      }

      @include view-at(sp) {
        width: 20%;
        bottom: 0;
      }
    }

    &-icon {
      width: 20%;
      position: absolute;
      top: 50%;
      right: 15px;
      z-index: 2;
    }
  }

  &-panel {
    display: block;
    height: 400px;
    background-color: $color-black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(35, 24, 21, 0.3);
      position: absolute;
      top: 0;
      left: 0;
    }

    @include view-at(tab) {
      height: 200px;
    }

    &.statement {
      background-image: url(../images/top/statement.jpg);
    }
    &.logistics {
      background-image: url(../images/top/logistics.jpg);
    }
    &.labo {
      background-image: url(../images/top/labo.jpg);
    }

    &.solution {
      background-image: url(../images/top/labo.jpg);
    }

    &.decarbonization {
      background-image: url(../images/top/decarbonization.jpg);
    }
    &.contact {
      background-image: url(../images/top/contact.jpg);
    }
    &.quality {
      background-image: url(../images/top/quality.jpg);
    }

    &:hover {
      text-decoration: none;
    }

    &-contents {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      background-size: 85%;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
      z-index: 2;

      &.statement {
        background-image: url(../images/top/statement-bg.png);
      }
      &.logistics {
        background-image: url(../images/top/logistics-bg.png);
      }
      &.labo {
        background-image: url(../images/top/labo-bg.png);
      }

      &.solution {
        background-image: url(../images/top/labo-bg.png);
      }
      &.decarbonization {
        background-image: url(../images/top/decarbonization-bg.png);
      }
      &.contact {
        background-image: url(../images/top/contact-bg.png);
      }
      &.quality {
        background-image: url(../images/top/quality-bg.png);
      }
    }

    &-text {
      &--ja {
        font-size: rem(32px);
        line-height: 1.5;
        letter-spacing: 0.1em;
      }

      &--en {
        font-size: rem(20px);
        letter-spacing: 0.1em;
      }
    }
  }

  &-news {
    &-card {
      display: flex;
      flex-direction: column;
      height: 100%;

      &:hover {
        text-decoration: none;
      }

      &-contents {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 16px;
        background: #efefef;
      }

      &-wrapper {
        flex: 1;
        height: 100%;
        min-height: 0%;

        margin-bottom: 1em;
      }

      &-title {
        font-size: rem(16px);
        line-height: 1.5;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include view-at(tab) {
          flex-wrap: wrap;
        }
      }

      &-view {
        margin-top: auto;
      }
    }
    &-label {
      display: inline-block;
      font-size: rem(14px);
      line-height: 1.5;
      letter-spacing: 0.1em;
      color: $color-white;
      padding: 4px 8px;
      background: $color-blue;
      text-align: center;

      @include view-at(tab) {
        width: 100%;
        margin-top: 0.5em;
      }
    }
    &-arrow {
      width: 35px;
      height: 100px;
      position: absolute;
      top: calc(50% - 50px);
      z-index: 10;
      cursor: pointer;

      &--prev {
        left: -35px;

        @include view-at(tab) {
          left: 0;
        }
      }

      &--next {
        right: -35px;

        @include view-at(tab) {
          right: 0;
        }
      }
    }
  }

  &-recruiting {
    &-item {
      a {
        display: block;
        color: $color-black;
        background: $color-white;
        position: relative;
        transition: all 0.5s;

        &:hover {
          text-decoration: none;
          color: $color-white;

          .top-recruiting {
            &-thumb img {
              transform: scale(1.2);
            }

            &-contents {
              &::before {
                transform: scaleX(1);
              }
            }

            &-inner {
              &::after {
                background-image: url(../images/top/movie-play--over.png);
              }
            }
          }
        }
      }
    }

    &-thumb {
      overflow: hidden;

      img {
        transition: all 0.5s;
      }
    }

    &-contents {
      padding: 16px;

      position: relative;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: $color-blue;
        position: absolute;
        top: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: left;
        transition: all 0.5s;
      }
    }

    &-inner {
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 4em;
        height: 100%;
        background-image: url(../images/top/movie-play.png);
        background-size: contain;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &-name {
      font-size: rem(20px);
      line-height: 1.5;
    }
  }

  // &-info {
  //   &-item {
  //     a {
  //       display: block;
  //       transition: all 0.5s;

  //       &:hover {
  //         text-decoration: none;

  //         .top-info {
  //           &-thumb img {
  //             transform: scale(1.2);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   &-thumb {
  //     overflow: hidden;
  //     position: relative;
  //     padding-top: calc((300 / 550) * 100%);

  //     img {
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       right: 0;
  //       bottom: 0;
  //       margin: auto;
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //       transition: all 0.5s;
  //     }
  //   }

  //   &-contents {
  //     height: 9em;
  //     padding: 16px;
  //     background: $color-white;
  //     position: relative;

  //     @include view-at(tab){
  //       height: 15em;
  //     }

  //   }

  //   &-label {
  //     display: inline-block;
  //     width: 10em;
  //     font-size: rem(14px);
  //     line-height: 1.5;
  //     letter-spacing: 0.1em;
  //     margin-right: 2em;
  //     color: $color-white;
  //     padding: 4px;
  //     background: $color-blue;
  //     text-align: center;

  //     @include view-at(tab){
  //       margin-right: 0;
  //       display: block;
  //       margin-bottom: 0.5em;

  //     }
  //   }
  // }
}
