.notice {
  &-list {
    &-item {
      margin-bottom: 24px;

      p {
        padding: 0 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-link {
    padding: 0 20px;
    margin-bottom: 1em;

    &--row {
      display: flex;
      justify-content: center;
      flex-direction: row;

      @include view-at(sp) {
        flex-wrap: wrap;
      }

      .notice-link-item {
        margin-bottom: 0;
        margin: 0 20px;

        @include view-at(sp) {
          margin: 0;
          width: 100%;
          padding: 10px 0;
        }

        &::before {
          content: "▶︎";
          display: inline-block;
          font-size: 80%;
          color: $color-blue;
        }

        &.current {
          a {
            font-weight: $bold;
          }
        }
      }
    }

    &-item {
      font-size: rem(14px);
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-title {
    font-size: rem(16px);
    line-height: 1.2;
    letter-spacing: 0.1em;
    color: $color-blue;
    border-left: 4px solid $color-blue;
    padding: 8px 8px 8px 16px;
    background: $color-gray-light;
    margin-bottom: 8px;
  }

  &-order {
    padding: 0 20px;

    &-list {
      list-style: none;
      counter-reset: number 0;

      li {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        h4 {
          font-size: rem(16px);
          line-height: 1.2;
          letter-spacing: 0.1em;

          border-left: 4px solid $color-gray;
          padding: 8px 8px 8px 16px;
          margin-bottom: 8px;

          &::before {
            counter-increment: number 1;
            content: counter(number) " ";
            display: inline-block;
            padding-right: 0.5em;
          }
        }
      }
    }
  }
}
