.andlogi {
  &-h2 {
    font-family: "nobel", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: rem(40px);
    line-height: 1.5;
    color: #006390;
    border-bottom: 2px solid #006390;
    margin-bottom: 32px;
    text-align: center;

    @include view-at(sp) {
      font-size: rem(32px);
      margin-bottom: 24px;
    }
  }

  &-h3 {
    font-size: rem(24px);
    line-height: 1.5;
    margin-bottom: 16px;
    text-align: center;

    @include view-at(sp) {
      font-size: rem(20px);
    }
  }

  &-h4 {
    font-feature-settings: "palt";
    font-size: rem(16px);
    font-weight: $bold;
    line-height: 1.5;
    color: #006390;
    margin-bottom: 8px;
  }

  &-bg {
    background: #eef1f9;
  }

  &-button {
    display: inline-block;
    font-size: rem(14px);
    line-height: 1.25;
    letter-spacing: 0.05em;
    color: $color-white;
    padding: 8px 16px;
    border: 1px solid $color-white;
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }

    &.mfls {
      background: #006390;
    }

    &--case {
      padding: 16px 32px;
      border: none;
      position: relative;
      z-index: 2;
      background: #006390;
    }

    @include view-at(sp) {
      &--sp {
        border: none;
      }
    }
  }

  &-anchor {
    margin-top: 40px;

    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 1em;

      @include view-at(tab) {
        flex-wrap: wrap;
        margin: 1em 0;
      }

      > li {
        padding: 0 1em;
        border-right: 1px solid #006390;

        @include view-at(tab) {
          padding: 1em 0;
          border-right: none;
          border-bottom: 1px solid #006390;
          width: 100%;
          text-align: center;
        }

        &:last-child {
          border-right: none;

          @include view-at(tab) {
            border-bottom: none;
          }
        }

        a {
          display: inline-block;
          font-weight: $bold;
          letter-spacing: 0.1em;
          color: #006390;
        }
      }
    }
  }

  &-mfls {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    @include view-at(sp) {
      display: none;
    }
  }

  &-modal {
    padding: 24px 40px 40px 40px;

    &-logo {
      max-height: 70%;
      height: 100%;

      @include view-at(sp) {
        max-height: auto;
      }
    }

    @include view-at(sp) {
      padding: 24px;
    }

    &-bg {
      background: radial-gradient(#fff, #e5e5e5);
    }

    &-image {
      height: 100%;

      @include view-at(sp) {
        height: auto;
      }
      img {
        display: block;
        object-fit: cover;
        height: 100%;

        @include view-at(sp) {
          object-fit: none;
          height: auto;
        }
      }
    }

    &-h {
      font-size: rem(24px);
      font-weight: $bold;
      line-height: 1.5;
      color: #006390;
      margin-bottom: 8px;

      &.base {
        color: #5ea829;
      }

      &.efficient {
        color: #dc4833;
      }

      &.delivery {
        color: #d85803;
      }

      &.sustainability {
        color: #008771;
      }

      &.automation {
        color: #78519e;
      }

      &.human {
        color: #1f62bf;
      }
    }

    &-sub {
      font-size: rem(20px);
      font-weight: $bold;
      line-height: 1.25;
      color: #006390;
      margin-bottom: 8px;

      &.base {
        color: #5ea829;
      }

      &.efficient {
        color: #dc4833;
      }

      &.delivery {
        color: #d85803;
      }

      &.sustainability {
        color: #008771;
      }

      &.automation {
        color: #78519e;
      }

      &.human {
        color: #1f62bf;
      }
    }
  }

  &-contents {
    display: block;
    height: 200px;
    filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.5));
    text-align: center;
    transition: all 0.3s;

    @include view-at(sp) {
      height: 120px;
    }

    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }

    &-item {
      &:nth-child(-n + 3) {
        margin-bottom: 40px;
      }

      @include view-at(tab) {
        &:nth-child(-n + 4) {
          margin-bottom: 40px;
        }
      }

      @include view-at(sp) {
        &:nth-child(-n + 6) {
          margin-bottom: 40px;
        }
      }
    }

    &-wrapper {
      height: 100%;
      background: $color-white;
      clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 0% 50%);
      position: relative;
    }

    &-clip {
      height: 100%;
      background: radial-gradient(#fff, #e5e5e5);
      clip-path: polygon(
        calc(15% + 4px) 0%,
        85% 0%,
        100% 50%,
        85% 100%,
        calc(15% + 4px) 100%,
        4px 50%
      );

      &-image {
        height: 100%;
        overflow: hidden;
        img {
          height: 100%;
        }
      }

      &--initiative {
        padding-top: 8px;
      }

      &--solution {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        clip-path: polygon(
          calc(15% + 4px) 8px,
          85% 8px,
          calc(100% - 8px) 50%,
          85% calc(100% - 8px),
          calc(15% + 4px) calc(100% - 8px),
          8px 50%
        );
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.75;
          z-index: -1;
        }

        &.base {
          background-image: url(../images/andlogi/support-base-modal.jpg);

          &::before {
            background: #5ea829;
          }
        }

        &.efficient {
          background-image: url(../images/andlogi/support-efficient-modal.jpg);
          &::before {
            background: #dc4833;
          }
        }

        &.delivery {
          background-image: url(../images/andlogi/support-delivery-modal.jpg);
          &::before {
            background: #d85803;
          }
        }

        &.sustainability {
          background-image: url(../images/andlogi/support-sustainability-modal.jpg);
          &::before {
            background: #008771;
          }
        }

        &.automation {
          background-image: url(../images/andlogi/support-automation-modal.jpg);
          &::before {
            background: #78519e;
          }
        }

        &.human {
          background-image: url(../images/andlogi/support-human-modal.jpg);
          &::before {
            background: #1f62bf;
          }
        }
      }
    }
  }

  &-parent {
    background-image: url(../images/andlogi/solution-eyecatch.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30%;

    @include view-at(tab) {
      background-image: none;
    }
    &-item1 {
      margin-top: 80px;

      @include view-at(tab) {
        margin-top: 0;
      }
    }

    &-item3 {
      margin-top: 80px;
      @include view-at(tab) {
        margin-top: 0;
      }
    }

    &-item5 {
      margin-top: 80px;
      @include view-at(tab) {
        margin-top: 0;
      }
    }

    a {
      display: block;
      text-align: center;
      transition: all 0.3s;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }
  }

  // &-parent {
  //   display: grid;
  //   grid-template-columns: 0.25fr 1fr 0.25fr 1fr 0.25fr 1fr 0.25fr;
  //   grid-template-rows: repeat(5, 1fr);
  //   grid-column-gap: 0px;
  //   grid-row-gap: 0px;

  //   @include view-at(sp) {
  //     display: block;
  //   }

  //   &-item1 {
  //     grid-area: 1 / 1 / 3 / 4;
  //     margin: 8px;
  //   }
  //   &-item2 {
  //     grid-area: 3 / 1 / 5 / 4;
  //     margin: 8px;
  //   }
  //   &-item3 {
  //     grid-area: 2 / 3 / 4 / 6;
  //     margin: 8px;
  //   }
  //   &-item4 {
  //     grid-area: 4 / 3 / 6 / 6;
  //     margin: 8px;
  //   }
  //   &-item5 {
  //     grid-area: 1 / 5 / 3 / 8;
  //     margin: 8px;
  //   }
  //   &-item6 {
  //     grid-area: 3 / 5 / 5 / 8;
  //     margin: 8px;
  //   }
  // }

  &-support {
    &-h {
      font-size: rem(32px);
      line-height: 1.5;
      font-weight: $bold;
      color: #006390;
      // text-shadow: 4px 4px 4px rgba($color-black, 0.5);
      margin-bottom: 8px;
      text-align: center;

      @include view-at(sp) {
        font-size: rem(16px);
      }
    }

    &-list {
      margin-top: 24px;
      > li {
        line-height: 1.4;
        padding-left: 1.5em;
        margin-bottom: 0.5em;
        position: relative;

        &::before {
          content: "●";
          position: absolute;
          top: 0;
          left: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.base {
          &::before {
            color: #5ea829;
          }
        }

        &.efficient {
          &::before {
            color: #dc4833;
          }
        }

        &.delivery {
          &::before {
            color: #d85803;
          }
        }

        &.sustainability {
          &::before {
            color: #008771;
          }
        }

        &.automation {
          &::before {
            color: #78519e;
          }
        }

        &.human {
          &::before {
            color: #1f62bf;
          }
        }
      }
    }
  }

  &-archive {
    display: block;
    position: relative;

    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to top, $color-white, $color-black);
      opacity: 0.6;
      mix-blend-mode: multiply;

      @include view-at(sp) {
        content: none;
      }
    }

    @include view-at(sp) {
      margin-bottom: 16px;
    }

    &-contents {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @include view-at(sp) {
        display: block;
        background: #006390;
        position: static;
        padding: 16px 0;

        &.sp {
          display: none;
        }
      }
    }

    &-title {
      font-size: rem(32px);
      line-height: 1.5;
      font-weight: $bold;
      color: $color-white;
      letter-spacing: 0.1em;
      text-align: center;
      margin-bottom: 24px;

      @include view-at(sp) {
        font-size: rem(24px);
        margin-bottom: 16px;
      }
    }

    &-text {
      font-size: rem(16px);
      line-height: 1.5;
      font-weight: $bold;
      color: $color-white;
      letter-spacing: 0.1em;
      text-align: center;
    }

    &-case {
      box-shadow: 4px 4px 4px rgba($color-black, 0.5);

      &-header {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        padding: 16px 8px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        &.a {
          background-image: url(../images/andlogi/case01-header.jpg);
        }

        &.b {
          background-image: url(../images/andlogi/case02-header.jpg);
        }

        &.c {
          background-image: url(../images/andlogi/case03-header.jpg);
        }

        &.d {
          background-image: url(../images/andlogi/case04-header.jpg);
        }

        .number {
          font-family: "nobel", sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: rem(40px);
          margin-right: 1em;

          @include view-at(sp) {
            font-size: rem(32px);
            margin-right: 0.5em;
          }
        }

        .headline {
          font-size: rem(24px);
          line-height: 1.5;
          font-weight: $bold;

          @include view-at(sp) {
            font-size: rem(20px);
          }
        }

        .detail {
          font-size: rem(16px);
          font-weight: $bold;

          @include view-at(sp) {
            font-size: rem(14px);
          }
        }
      }

      &-contents {
        padding: 32px 40px;
        background: $color-white;

        @include view-at(sp) {
          padding: 32px 16px;
        }
      }

      &-wrapper {
        display: flex;
        align-items: center; /* 線を中央揃えにしている */
        justify-content: flex-end;

        &::before {
          content: "";
          height: 1px;
          flex-grow: 1;
          background: #045f89;
        }
      }

      &-link {
        text-decoration: none;

        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }

      &-top {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem(24px);
        text-decoration: none;

        @include view-at(sp) {
          flex-wrap: wrap;
        }

        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }

        img {
          height: 40px;
          align-self: baseline;
        }
      }
    }
  }

  &-fixed {
    width: 160px;
    height: 160px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 10;

    @include view-at(sp) {
      bottom: 20px;
      right: 20px;
    }

    &-button {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(16px);
      line-height: 1.5;
      font-weight: $bold;
      color: $color-white;
      text-align: center;
      background: #e60012;
      border-radius: 50%;
      position: relative;
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        opacity: 0.7;
        text-decoration: none;
      }

      // &::before {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   height: 100%;
      //   border: 10px solid #ff0000;
      //   border-radius: 50%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   z-index: -1;
      // }

      &::after {
        content: "";
        display: block;
        width: calc(100% - 7px);
        height: calc(100% - 7px);
        border: 1px solid $color-white;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 3px;
        z-index: 1;
      }

      span {
        font-family: "nobel", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: rem(24px);
      }
    }
  }

  &-task {
    display: block;
    height: 200px;
    filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.5));
    text-align: center;
    transition: all 0.3s;

    @include view-at(sp) {
      height: auto;

      .andlogi-h4 {
        filter: none !important;
      }
    }

    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }

    &-wrapper {
      height: 100%;
      background: $color-white;
      clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 0% 50%);
      position: relative;

      @include view-at(sp) {
        height: 120px;

        &.mflp {
          height: 150px;
        }
      }
    }

    &-contents {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      padding-top: 8px;
      background: radial-gradient(#fff, #e5e5e5);
      clip-path: polygon(
        calc(15% + 4px) 0%,
        85% 0%,
        100% 50%,
        85% 100%,
        calc(15% + 4px) 100%,
        4px 50%
      );

      &.mflp {
        padding-top: 0;
        display: block;
      }

      @include view-at(sp) {
        display: block;
        padding-top: 0;

        &-image {
          height: 100%;

          img {
            height: 100%;
          }
        }
      }
    }

    &-mflp {
      height: 200px;
    }

    &-button {
      display: inline-block;
      font-size: rem(14px);
      line-height: 1.25;
      letter-spacing: 0.05em;
      color: $color-white;
      padding: 8px 16px;
      border: 1px solid $color-white;
      transition: all 0.3s;
      position: absolute;
      bottom: 16px;
      right: calc(15% + 4px);
      background: #006390;

      @include view-at(sp) {
        position: static;
        margin-top: 8px;
      }
    }
  }
}
