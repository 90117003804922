/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
  display: none;

  @include view-at(tab) {
    display: block;
    width: 25px;
    height: 25px;
    position: relative;
    z-index: 11;
  }

  &-trigger,
  &-trigger span {
    display: inline-block;
    transition: all 0.2s;
    box-sizing: border-box;
  }

  &-trigger {
    width: 25px;
    height: 25px;
    position: relative;

    &-label {
      display: block;
      font-size: 8px;
      letter-spacing: 1.1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      width: 100%;
      background-color: transparent;
      height: auto;
      color: $color-black;
    }
  }

  &-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-black;
  }

  &-trigger span:nth-of-type(1) {
    top: 4px;
  }

  &-trigger span:nth-of-type(2) {
    top: 11px;
  }

  &-trigger span:nth-of-type(3) {
    top: 18px;
  }

  &-trigger.active span:nth-of-type(1) {
    transform: translateY(7px) rotate(-45deg);
  }

  &-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }

  &-trigger.active span:nth-of-type(3) {
    transform: translateY(-7px) rotate(45deg);
  }
}
