/* --------------------------
リンク
-------------------------- */

.c-link {
  color: $color-black;

  &:hover {
    color: $color-blue;
    text-decoration: underline;
  }

  &--blue {
    color: $color-blue;
  }

  &--border {
    text-decoration: underline;
  }

  &--target {
    &::after {
      content: "";
      display: inline-block;
      width: 9px;
      height: 7px;
      background: url(../images/common/icon_blank.png) no-repeat left top;
      vertical-align: middle;
      margin-left: 3px;
    }

    &:hover {
      &::after {
        background: url(../images/common/icon_blank_over.png);
      }
    }
  }
}
