/* --------------------------
パララックス
-------------------------- */

.c-parallax {
  position: relative;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &-inner {
    position: relative;
    z-index: 1;
  }

  &-contents {
    background: rgba(0, 0, 0, 0.25);
    padding: nth($space, 1) 0;

    @include view-at(sp) {
      padding: nth($space-sp, 1) 0;
    }
  }
}
