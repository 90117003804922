.new {
  &-search {
    position: fixed;
    bottom: 100px;
    right: 0;
    width: 240px;
    background: $color-blue;
    border-radius: 8px 0 0 8px;
    z-index: 100;

    @include view-at(sp) {
      position: static;
      width: 100%;
      border-radius: 0;
    }

    &-contents {
      padding: 16px;

      @include view-at(sp) {
        padding: 16px 8px;
      }
    }

    &-title {
      font-size: rem(16px);
      line-height: 1.5;
      color: $color-white;
      text-align: center;
      padding-bottom: 0.5em;
      border-bottom: 1px solid $color-white;
      margin-bottom: 0.5em;

      &-small {
        font-size: rem(14px);
        line-height: 1.25;
        color: $color-white;
        text-align: center;
        margin-bottom: 1em;
      }
    }

    &-nav {
      &-list {
        @include view-at(sp) {
          display: flex;
          margin: -8px;
        }

        &-item {
          margin-bottom: 16px;
          padding-bottom: 8px;
          border-bottom: 1px solid rgba($color-white, 0.5);

          @include view-at(sp) {
            width: calc(100% / 3);
            padding: 8px;
            margin-bottom: 0;
            border-bottom: none;
            border-right: 1px solid rgba($color-white, 0.5);
          }

          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;

            @include view-at(sp) {
              border-right: none;
            }
          }

          a {
            display: flex;
            align-items: center;
            font-size: rem(16px);
            line-height: 1.25;
            color: $color-white;
            transition: all 0.3s;

            @include view-at(sp) {
              display: block;
              text-align: center;
              font-size: rem(14px);
            }

            &:hover {
              opacity: 0.7;
              text-decoration: none;
            }
          }
        }
      }

      &-icon {
        margin-right: 16px;

        @include view-at(sp) {
          margin-right: 0;
          margin-bottom: 8px;
        }
        img {
          width: 75px;
        }
      }
    }

    &-icon {
      margin-right: 16px;

      @include view-at(sp) {
        margin-right: 0;
        margin-bottom: 8px;
      }
      img {
        width: 75px;
      }
    }
  }
}
