/* --------------------------
color
-------------------------- */

$color-white: #fff;
$color-black: #333;

$color-gray: #a2a2a2;
$color-gray-light: #f4f4f4;
$color-gray-dark: #666;
$color-gray-snow: #ccc;

$color-blue: #014099;
$color-blue-dark: #6782a6;

$color-section: #edeee1;

/* --------------------------
spacing
-------------------------- */
$space: 100px, 80px, 40px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 40px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$bold: 700;
$normal: 300;
$serif: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "Yu Mincho",
  "游明朝体", "YuMincho", "ＭＳ Ｐ明朝", "MS PMincho", serif;
$sans-serif: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック",
  "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック",
  "MS PGothic", sans-serif;
/* --------------------------
contents width
-------------------------- */
$contents-width: 1240px;
$contents-middle-width: 1040px;
$contents-padding: 20px;
$contents-padding-sp: 15px;

/* --------------------------
z-index order
-------------------------- */
$z: (contents, arrow, gnav, leftbar, header, footer, modal);
