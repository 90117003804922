/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  flex-shrink: 1;
  width: 100%;
  margin: auto 1em 0;

  @include view-at(tab) {
    display: none;
    margin: 0;
    width: 100%;
    height: 100vh;
    background: rgba(230, 230, 230, 0.8);
    position: absolute;
    top: 46px;
    right: 0;
    z-index: 100;
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include view-at(tab) {
      flex-wrap: wrap;
      flex-direction: column;
      background: rgba(230, 230, 230, 1);
    }

    &-item {
      flex-grow: 1;
      margin: 0 0.5vw;

      @include view-at(tab) {
        margin: 0;
        border-bottom: 1px solid $color-gray;
        width: 100%;
      }

      &.new {
        a {
          position: relative;

          &::before {
            content: "NEW";
            width: 4em;
            display: block;
            font-size: rem(10px);
            font-family: sans-serif;
            font-weight: $bold;
            color: $color-white;
            padding: 0.25em 0.5em;
            line-height: 1.2;
            background: #e60012;
            position: absolute;
            top: -24px;
            left: 0;
            right: 0;
            margin: 0 auto;

            @include view-at(tab) {
              top: 0;
              left: 0;
              right: auto;
            }
          }
        }
      }

      &.current {
        a {
          color: $color-blue;
          border-bottom: 2px solid $color-blue;

          @include view-at(tab) {
            border-bottom: none;
          }
        }
      }

      a {
        display: block;
        font-size: clamp(14px, 1vw, 16px);
        letter-spacing: 0.1em;
        text-align: center;
        padding-bottom: 9px;
        border-bottom: 2px solid transparent;
        transition: all 0.3s;

        @include view-at(tab) {
          font-size: rem(12px);
          border-bottom: none;
          padding: 12px 16px;
          position: relative;

          &[target="_blank"] {
            &::after {
              content: "";
              display: block;
              width: 9px;
              height: 7px;
              background: url(../images/common/icon_blank.png) no-repeat left
                top;
              position: absolute;
              top: calc(50% - 3.5px);
              right: 20px;
              border: none;
              transform: rotate(0);
            }
          }

          &::after {
            content: "";
            display: block;
            width: 7px;
            height: 7px;
            border-top: 2px solid $color-gray;
            border-right: 2px solid $color-gray;
            transform: rotate(45deg);
            position: absolute;
            top: calc(50% - 3.5px);
            right: 20px;
          }
        }

        &:hover {
          text-decoration: none;
          border-bottom: 2px solid $color-blue;

          @include view-at(tab) {
            border-bottom: none;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1240px) {
    .p-gnav-hide {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    .p-gnav-hide {
      display: none;
    }
  }
}
