/* --------------------------
背景色
-------------------------- */
.u-bg-section {
  background-color: $color-section;
}

.u-bg-image {
  padding-top: 150px;
  background-image: url(../images/common/section-bg.png);
  background-position: top 0 center;
  background-repeat: no-repeat;
  background-size: contain;

  @include view-at(sp) {
    padding-top: 100px;
  }

  &--white {
    background-image: url(../images/common/section-bg--white.png);
  }
}

.u-bg-light {
  background-color: $color-gray-snow;
}

.u-bg-white {
  background-color: $color-white;
}

.u-bg-black {
  background-color: $color-black;
}

.u-bg-blue{
  background: rgba($color-blue,.2);
}