/* --------------------------
テキスト装飾
-------------------------- */

.u-text-accent {
  color: $color-blue;
}

.u-text-important {
  color: $color-black;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-sub {
  color: $color-gray;
}

.u-text-sansserif {
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic,
    "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka,
    "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, $color-gray-light 70%);
}

.u-text-x-small {
  font-size: rem(12px);
}

.u-text-small {
  font-size: rem(14px);
}

.u-text-default {
  font-size: rem(16px);
  line-height: 1.625;
}

.u-text-large {
  font-size: rem(18px);
  line-height: 1.8;
}

.u-text-x-large {
  font-size: rem(20px);
}
