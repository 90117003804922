.p-modal {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.5);
  }

  &-body {
    width: 1152px;
    height: 480px;
    position: relative;

    @include view-at(sp) {
      width: 80%;
      height: 75%;
      margin-bottom: 25%;
    }
  }

  &-contents {
    width: 100%;
    height: 100%;
    background: $color-white;
    overflow: scroll;
  }

  &-close {
    display: block;
    width: 40px;
    height: 40px;
    font-size: rem(40px);
    line-height: 1;
    text-align: center;
    color: $color-white;
    background: #006390;
    text-decoration: none;
    position: absolute;
    bottom: 0;
    right: 0;

    &:hover {
      text-decoration: none;
    }
  }
}
// .p-modal {
//   display: none;
//   width: 100%;
//   height: 100vh;
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 1000;
//   background: rgba(0, 0, 0, 0.5);

//   &-bg {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     overscroll-behavior: none;
//   }

//   &-body {
//     width: 60%;
//     position: relative;

//     @include view-at(sp) {
//       width: 90%;
//       height: 70%;
//       overflow: hidden;
//     }
//   }

//   &-contents {
//     background: $color-white;

//     @include view-at(sp) {
//       height: 100%;
//     }
//   }

//   &-close {
//     display: block;
//     width: 40px;
//     height: 40px;
//     font-size: rem(40px);
//     line-height: 1;
//     text-align: center;
//     color: $color-white;
//     background: #006390;
//     text-decoration: none;
//     position: absolute;
//     bottom: 0;
//     right: 0;

//     &:hover {
//       text-decoration: none;
//     }
//   }
// }
