.c-splash{
  position: fixed;
  z-index: 100001;
  width: 100%;
  height: 100%;
  background: $color-gray-light;

  &-bar{
    
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: rem(32px);
    text-align: center;
    
  }
}