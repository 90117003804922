.archive {
  &-h1 {
    font-size: rem(40px);
    line-height: 1.5;
    color: #006390;
    margin-bottom: 32px;
    text-align: center;

    @include view-at(sp) {
      font-size: rem(24px);
      margin-bottom: 24px;
    }

    span {
      font-family: "nobel", sans-serif;
      font-weight: 400;
      font-style: normal;
      border-bottom: 2px solid #006390;
      padding: 0 0.5em;
      margin-right: 0.5em;
    }
  }

  &-h3 {
    font-size: rem(24px);
    line-height: 1.5;
    font-weight: $bold;
    color: #006390;
    padding-bottom: 0.25em;
    border-bottom: 2px solid #006390;
    margin-bottom: 16px;

    @include view-at(sp) {
      font-size: rem(20px);
      margin-bottom: 8px;
    }
  }

  &-interview {
    position: relative;

    &-text {
      color: #006390;
    }

    img {
      display: block;
    }

    &-name {
      position: absolute;
      z-index: 3;
      bottom: 16px;
      color: $color-white;

      span {
        font-size: rem(24px);
      }

      &--left {
        left: 16px;
        text-align: left;
      }

      &--right {
        right: 16px;
        text-align: right;
      }
    }
  }

  &-mv {
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to top, $color-white, $color-black);
      opacity: 0.6;
      mix-blend-mode: multiply;
      z-index: 1;

      @include view-at(sp) {
        content: none;
      }
    }

    &-contents {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 100px;
      flex-direction: column;
      z-index: 2;

      @include view-at(tab) {
        position: static;
        display: block;
        background: #006390;
        padding: 16px 0;

        &.tab {
          display: block;
        }

        &.sp {
          display: none;
        }
      }

      p {
        font-size: rem(24px);
        line-height: 1.5;
        color: $color-white;
        font-weight: $bold;

        @include view-at(sp) {
          font-size: rem(16px);
        }
      }
    }

    &-title {
      font-size: rem(32px);
      line-height: 1.5;
      font-weight: $bold;
      color: $color-white;
      letter-spacing: 0.1em;
      text-align: center;
      margin-bottom: 24px;

      @include view-at(sp) {
        font-size: rem(24px);
        margin-bottom: 16px;
      }
    }
  }

  &-bg {
    background: #eef1f9;
  }

  &-flow {
    margin-top: 40px;

    &-list {
      display: flex;
      margin: -5px;

      @include view-at(sp) {
        flex-wrap: wrap;
      }

      &-item {
        padding: 5px;
        width: 25%;

        @include view-at(sp) {
          width: 100%;
        }
      }
    }

    &-label {
      font-size: rem(24px);
      line-height: 1.5;
      font-weight: $bold;
      color: #006390;
      text-align: center;
    }

    &-contents {
      font-size: rem(16px);
      line-height: 1.5;
      color: $color-white;
      padding: 16px 32px;
      background: linear-gradient(
        to right,
        #006390 0%,
        #01628e 30%,
        #00a0e9 100%
      );
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 100%;
        background: linear-gradient(
              to bottom left,
              transparent 50%,
              #eef1f9 50%
            )
            top left/ 100% 50% no-repeat,
          linear-gradient(to top left, transparent 50%, #eef1f9 50%) bottom left /
            100% 50% no-repeat;

        position: absolute;
        top: 0;
        left: 0;

        @include view-at(sp) {
          width: 10px;
        }
      }

      &::after {
        content: "";
        display: block;
        width: 16px;
        height: 100%;
        background: linear-gradient(
              to bottom left,
              transparent 50%,
              #00a0e9 50%
            )
            top left/ 100% 50% no-repeat,
          linear-gradient(to top left, transparent 50%, #00a0e9 50%) bottom left /
            100% 50% no-repeat;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 1;

        @include view-at(sp) {
          width: 10px;
        }
      }
    }
  }

  &-tab {
    &-nav {
      display: flex;
      margin: -10px;

      &-item {
        width: calc(100% / 3);
        padding: 10px;

        &.current {
          a {
            background: $color-white;
            box-shadow: 0px -4px 3px rgba(112, 132, 160, 0.3);
          }
        }

        a {
          display: block;
          font-size: rem(24px);
          line-height: 1.5;
          font-weight: $bold;
          color: #006390;
          padding: 16px 8px;
          background: #eef1f9;
          text-align: center;
          transition: all 0.3s;

          &:hover {
            background: $color-white;
            box-shadow: 0px -4px 3px rgba(112, 132, 160, 0.3);
            text-decoration: none;
          }
        }
      }
    }

    &-contents {
      padding-top: 40px;
      box-shadow: 0 -8px 3px -3px rgba(112, 132, 160, 0.3);
    }
  }

  &-button {
    display: inline-block;
    font-size: rem(16px);
    font-weight: $bold;
    line-height: 1.5;
    color: $color-white;
    padding: 16px 32px;
    background: #006390;
    transition: all.3s;

    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
}
