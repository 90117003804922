.labo {
  &-keyvisual {
    height: 1400px;
    background-image: url(../images/labo/mv.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    @include view-at(sp) {
      height: 600px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 0;
      /* (画像の高さ / 画像の横幅) × 100 */
      padding-top: calc((650 / 1920) * 100%);
      background-image: url(../images/labo/section-bg.png);
      background-position: bottom 100% center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &-mv {
      background-color: transparent;
    }

    &-catchcopy {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1200px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;

      @include view-at(sp) {
        padding: 250px 15px 100px;
        height: 600px;
      }
    }
  }
  &-section {
    margin-top: calc(((650 / 1920) * 100%) * -1);
    padding-top: 150px;
    position: relative;

    @include view-at(sp) {
      padding-top: 100px;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: $color-section;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  &-title {
    font-size: rem(36px);
    text-align: center;
    margin-bottom: 10px;

    @include view-at(sp) {
      font-size: rem(30px);
    }

    span {
      font-family: trajan-pro-3, serif;
      font-weight: 400;
      font-style: normal;
      font-size: rem(48px);
      line-height: 1;
      color: $color-blue;
    }
  }

  &-point {
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
    padding: 40px;

    @include view-at(sp) {
      padding: 16px;
    }

    &-list {
      width: 100%;
      max-width: 60%;
      margin: 0 auto;

      @include view-at(sp) {
        max-width: 100%;
      }

      &-item {
        font-family: $sans-serif;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-number {
      width: 64px;
      flex-shrink: 0;
      margin-right: 20px;

      @include view-at(sp) {
        width: 36px;
        margin-right: 10px;
      }
    }
  }

  &-area {
    &-title {
      font-size: rem(28px);
      line-height: 1.5;
      color: $color-blue;
      letter-spacing: 0.1em;
      margin-bottom: 16px;

      @include view-at(sp) {
        font-size: rem(22px);
      }
    }

    &-contents {
      margin-left: 10%;

      @include view-at(sp) {
        margin: 10px 10% 0;
      }
    }
  }

  &-bg {
    background: #e6e6e6;
  }

  &-access-panel {
    font-family: $sans-serif;
    color: $color-white;
    background: #204099;
    border: 1.5px solid $color-black;
    height: 200px;

    @include view-at(sp) {
      height: auto;
    }

    &-contents {
      padding: 40px 24px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      @include view-at(sp) {
        padding: 20px 16px;
        height: auto;
      }
    }

    &-h {
      font-size: rem(24px);
      line-height: 1.5;
      margin-bottom: 10px;

      @include view-at(sp) {
        font-size: rem(18px);
      }
    }

    &.icon {
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 200px;
        height: 160px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-position: bottom right;
        background-size: contain;
        background-repeat: no-repeat;

        @include view-at(sp) {
          width: 150px;
          height: 110px;
        }
      }

      &--car {
        &::after {
          background-image: url(../images/labo/icon-car.png);
        }
      }

      &--train {
        &::after {
          background-image: url(../images/labo/icon-train.png);
        }
      }
    }
  }
}
