.p-mv {
  background-image: url(../images/common/p-mv-bg.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color-section;
  height: 400px;

  @include view-at(sp) {
    height: 250px;
  }

  &--white {
    background-color: $color-white;
  }

  &-contents {
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    @include view-at(sp) {
      padding-top: 46px;
    }
  }

  &-large {
    font-size: rem(48px);
    color: $color-white;
    letter-spacing: 0.2em;
    margin-bottom: 60px;

    @include view-at(sp) {
      font-size: rem(28px);
      margin-bottom: 30px;
    }
  }

  &-small {
    font-size: rem(32px);
    line-height: 1.5;
    color: $color-white;
    letter-spacing: 0.15em;

    @include view-at(sp) {
      font-size: rem(20px);
    }
  }
}
