@charset "UTF-8";
/* --------------------------
color
-------------------------- */
/* --------------------------
spacing
-------------------------- */
/* --------------------------
font
-------------------------- */
/* --------------------------
contents width
-------------------------- */
/* --------------------------
z-index order
-------------------------- */
/* --------------------------
media query
-------------------------- */
/* --------------------------
h with center-line
-------------------------- */
/* --------------------------
pxからvwを計算するmixin
-------------------------- */
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 300;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

html {
  font-size: 16px;
}

body {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #333;
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: 0.02em;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  font-weight: 300;
  line-height: 1.625;
  letter-spacing: 0.02em;
  text-align: justify;
  margin-bottom: 1em;
}

p:last-child {
  margin-bottom: 0;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main--base {
  padding-top: 100px;
}

@media screen and (max-width: 1024px) {
  .main--base {
    padding-top: 50px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../images/common/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* --------------------------
パンくずナビ
-------------------------- */
.c-breadcrumb {
  background-color: #f4f4f4;
}

.c-breadcrumb-list {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  .c-breadcrumb-list {
    flex-wrap: wrap;
  }
}

.c-breadcrumb-list li {
  font-size: 0.75rem;
}

.c-breadcrumb-list li::after {
  content: ">";
  padding: 0 1em;
}

.c-breadcrumb-list li:last-child::after {
  display: none;
}

/* --------------------------
ボタン
-------------------------- */
.c-button {
  display: block;
  font-size: 1.75rem;
  line-height: 1.75;
  color: #fff;
  letter-spacing: 0.1em;
  padding: 8px 16px;
  background: #003d8b;
  border-radius: 50px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-button {
    font-size: 1.375rem;
  }
}

.c-button:hover {
  opacity: 0.7;
  color: #fff;
}

.c-button--large {
  width: 100%;
}

/* --------------------------
フォーム
-------------------------- */
.c-form-w0 {
  width: 0%;
}

.c-form-w10 {
  width: 10%;
}

.c-form-w20 {
  width: 20%;
}

.c-form-w30 {
  width: 30%;
}

.c-form-w40 {
  width: 40%;
}

.c-form-w50 {
  width: 50%;
}

.c-form-w60 {
  width: 60%;
}

.c-form-w70 {
  width: 70%;
}

.c-form-w80 {
  width: 80%;
}

.c-form-w90 {
  width: 90%;
}

.c-form-w100 {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .c-form-w0--sp {
    width: 0%;
  }
  .c-form-w10--sp {
    width: 10%;
  }
  .c-form-w20--sp {
    width: 20%;
  }
  .c-form-w30--sp {
    width: 30%;
  }
  .c-form-w40--sp {
    width: 40%;
  }
  .c-form-w50--sp {
    width: 50%;
  }
  .c-form-w60--sp {
    width: 60%;
  }
  .c-form-w70--sp {
    width: 70%;
  }
  .c-form-w80--sp {
    width: 80%;
  }
  .c-form-w90--sp {
    width: 90%;
  }
  .c-form-w100--sp {
    width: 100%;
  }
}

.c-form-text {
  border: 1px solid #f4f4f4;
  padding: 6px 5px;
}

.c-form-text:invalid {
  border: 2px solid #b11010;
  background: rgba(177, 16, 16, 0.25);
  background-image: url(../images/common/icon-invalid.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.c-form-textarea {
  border: 1px solid #f4f4f4;
  width: 100%;
  padding: 6px 5px;
  height: 100px;
}

.c-form-checkbox-input {
  display: none;
}

.c-form-checkbox-input:checked + .c-form-checkbox-span::after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 6px;
  width: 8px;
  height: 12px;
  transform: rotate(40deg);
  border-bottom: 3px solid #333;
  border-right: 3px solid #333;
}

.c-form-checkbox-span {
  display: inline-block;
  padding-left: calc(20px + 0.5em);
  padding-right: 0.5em;
  position: relative;
}

.c-form-checkbox-span::before {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #f4f4f4;
  background-color: #fff;
}

.c-form-radio-input {
  display: none;
}

.c-form-radio-input:checked + .c-form-radio-span::after {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333;
}

.c-form-radio-span,
.c-form-radio .mwform-radio-field-text {
  display: inline-block;
  padding-left: calc(20px + 0.5em);
  padding-right: 0.5em;
  position: relative;
}

.c-form-radio-span::before,
.c-form-radio .mwform-radio-field-text::before {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #f4f4f4;
  background-color: #fff;
}

.c-form-select {
  display: block;
  padding: 6px 5px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  appearance: none;
  border: 0;
  border: 1px solid #a2a2a2;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' class='svg-inline--fa fa-angle-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.c-form-select::-ms-expand {
  display: none;
}

.c-form-select:hover {
  border-color: #888;
}

.c-form-select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

.c-form-select--small {
  width: 50%;
  display: inline-block;
}

.c-form-select option {
  font-weight: normal;
}

/* --------------------------
見出し
-------------------------- */
.c-h1 {
  font-size: 2.25rem;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .c-h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

.c-h2 {
  font-size: 2rem;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .c-h2 {
    font-size: 1.25rem;
    margin-bottom: 24px;
  }
}

.c-h3 {
  font-size: 1.75rem;
  line-height: 1.75;
  color: #fff;
  letter-spacing: 0.1em;
  padding: 8px 16px;
  background: #003d8b;
  border-radius: 50px;
  box-shadow: 7px 7px 5px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .c-h3 {
    font-size: 1.375rem;
    line-height: 1.5;
  }
}

.c-h4 {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #204099;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .c-h4 {
    font-size: 1.125rem;
  }
}

.c-h5 {
  font-size: 1.125rem;
  line-height: 1.5;
  border-bottom: 1px solid #333;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .c-h5 {
    font-size: 1rem;
  }
}

.c-h--center-line {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.c-h--center-line::before, .c-h--center-line::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: #4f574d;
  display: block;
}

.c-h--center-line::before {
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .c-h--center-line::before {
    margin-right: 15px;
  }
}

.c-h--center-line::after {
  margin-left: 30px;
}

@media screen and (max-width: 767px) {
  .c-h--center-line::after {
    margin-left: 15px;
  }
}

/* --------------------------
ラベル
-------------------------- */
.c-label {
  display: inline-block;
  padding: 5px 15px;
  line-height: 1;
  background-color: #333;
  color: #fff;
  font-size: 0.875rem;
  letter-spacing: 1px;
}

/* --------------------------
線
-------------------------- */
.c-line--dashed {
  border-top: 2px dashed #7D6A00;
}

/* --------------------------
リンク
-------------------------- */
.c-link {
  color: #333;
}

.c-link:hover {
  color: #014099;
  text-decoration: underline;
}

.c-link--blue {
  color: #014099;
}

.c-link--border {
  text-decoration: underline;
}

.c-link--target::after {
  content: "";
  display: inline-block;
  width: 9px;
  height: 7px;
  background: url(../images/common/icon_blank.png) no-repeat left top;
  vertical-align: middle;
  margin-left: 3px;
}

.c-link--target:hover::after {
  background: url(../images/common/icon_blank_over.png);
}

/* --------------------------
リスト
-------------------------- */
.c-list {
  font-size: 1rem;
}

.c-list > li {
  margin-bottom: 0.5em;
  line-height: 1.25;
  position: relative;
}

.c-list > li:last-child {
  margin-bottom: 0;
}

.c-list--point > li {
  padding-left: 15px;
  margin-bottom: 5px;
  line-height: 32px;
  position: relative;
}

.c-list--point > li::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 3px;
  display: inline-block;
  background-color: #333;
  position: absolute;
  top: 12px;
  left: 0;
}

.c-list--point > li:last-child {
  margin-bottom: 0;
}

.c-list--link li {
  padding-left: 15px;
  margin-bottom: 5px;
  line-height: 24px;
  position: relative;
}

.c-list--link li::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6.9px;
  border-color: transparent transparent transparent #333;
  position: absolute;
  top: 9px;
  left: 2px;
}

.c-list--link li a {
  text-decoration: none;
}

.c-list--link li a:hover {
  text-decoration: underline;
}

.c-list--horizontal {
  display: flex;
  align-items: center;
}

.c-list--horizontal li {
  margin-left: 25px;
  margin-bottom: 0;
}

.c-list--horizontal li:first-child {
  margin-left: 0;
}

.c-list--border li {
  background-position: left 16px;
  padding: 14px;
  border-bottom: 1px dashed #d4d4d4;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 1;
}

.c-list--border li:last-child {
  border-bottom: 0;
}

.c-list--border li a {
  color: #333;
  text-decoration: none;
}

.c-list--border li a:hover {
  color: #333;
}

.c-list--order {
  padding-left: 15px;
  margin-bottom: 10px;
  line-height: 24px;
}

.c-list--note li {
  font-size: 13px;
  color: #666;
  line-height: 1.5;
}

.c-list--note li::before {
  content: "※";
  margin-right: 3px;
}

/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */
.c-menu {
  display: none;
}

@media screen and (max-width: 1024px) {
  .c-menu {
    display: block;
    width: 25px;
    height: 25px;
    position: relative;
    z-index: 11;
  }
}

.c-menu-trigger,
.c-menu-trigger span {
  display: inline-block;
  transition: all 0.2s;
  box-sizing: border-box;
}

.c-menu-trigger {
  width: 25px;
  height: 25px;
  position: relative;
}

.c-menu-trigger-label {
  display: block;
  font-size: 8px;
  letter-spacing: 1.1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  background-color: transparent;
  height: auto;
  color: #333;
}

.c-menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
}

.c-menu-trigger span:nth-of-type(1) {
  top: 4px;
}

.c-menu-trigger span:nth-of-type(2) {
  top: 11px;
}

.c-menu-trigger span:nth-of-type(3) {
  top: 18px;
}

.c-menu-trigger.active span:nth-of-type(1) {
  transform: translateY(7px) rotate(-45deg);
}

.c-menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}

.c-menu-trigger.active span:nth-of-type(3) {
  transform: translateY(-7px) rotate(45deg);
}

/* --------------------------
パネル
-------------------------- */
.c-panel {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  background-color: #204099;
  border: 2px solid #333;
}

.c-panel.height {
  display: flex;
  height: 195px;
}

.c-panel.height .c-panel-contents {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.c-panel-h {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

.c-panel-contents {
  color: #fff;
  padding: 40px 24px;
}

@media screen and (max-width: 767px) {
  .c-panel-contents {
    padding: 20px;
  }
}

.c-panel-contents--narrow {
  padding: 15px;
}

/* --------------------------
セクション
-------------------------- */
@media screen and (min-width: 768px) {
  .c-section {
    margin: 100px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section {
    margin: 40px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--middle {
    margin: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--middle {
    margin: 30px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--narrow {
    margin: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--narrow {
    margin: 20px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg {
    padding: 100px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--bg {
    padding: 40px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg--middle {
    padding: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--bg--middle {
    padding: 30px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg--narrow {
    padding: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--bg--narrow {
    padding: 20px 0;
  }
}

.c-section-container {
  max-width: 1240px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .c-section-container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1024px) {
  .c-section-container {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-section-container {
    max-width: 100%;
    padding: 0 15px;
  }
}

.c-section-container--middle {
  max-width: 1040px;
  margin: auto;
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  .c-section-container--fill-sp {
    padding: 0;
    overflow: hidden;
  }
}

/* --------------------------
タブ
-------------------------- */
.c-tab-nav {
  display: flex;
  justify-content: center;
  margin: -10px;
}

@media screen and (max-width: 1024px) {
  .c-tab-nav {
    justify-content: flex-start;
    margin: -8px;
    flex-wrap: wrap;
  }
}

.c-tab-nav-item {
  width: 25%;
  padding: 10px;
}

@media screen and (max-width: 1024px) {
  .c-tab-nav-item {
    width: 50%;
    padding: 8px;
  }
}

.c-tab-nav-item a {
  display: block;
  font-size: 1rem;
  color: #333;
  line-height: 1.2;
  padding: 8px 16px;
  border: 1px solid #333;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .c-tab-nav-item a {
    font-size: 0.875rem;
    padding: 12px 8px;
  }
}

.c-tab-nav-item a[aria-selected="true"] {
  color: #fff;
  background-color: #204099;
}

.c-tab-contents {
  padding: 40px;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .c-tab-contents {
    padding: 20px;
  }
}

.c-tab-contents-item {
  display: none;
}

.c-tab-contents-item[aria-hidden="false"] {
  display: block;
}

/* --------------------------
テーブル
-------------------------- */
.c-table {
  border-top: 1px solid #a2a2a2;
  width: 100%;
  font-size: 1rem;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .c-table {
    font-size: 14px;
    line-height: 1.5;
  }
}

.c-table tbody tr {
  border-bottom: 1px solid #a2a2a2;
}

@media screen and (max-width: 767px) {
  .c-table tbody tr {
    border-bottom: 0;
    width: 100%;
  }
}

.c-table tbody th {
  padding: 15px 40px;
  font-weight: 300;
  width: 300px;
  background-color: #ccc;
  vertical-align: top;
}

@media screen and (max-width: 767px) {
  .c-table tbody th {
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 15px;
  }
}

.c-table tbody td {
  padding: 15px 40px;
}

@media screen and (max-width: 767px) {
  .c-table tbody td {
    display: block;
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
    padding: 10px 15px;
  }
}

.c-table--narrow {
  border-color: #f4f4f4;
}

.c-table--narrow tbody tr {
  border-color: #f4f4f4;
}

.c-table--narrow tbody th {
  padding: 8px 10px;
  line-height: 1.5;
  font-weight: normal;
  width: 120px;
  font-weight: 700;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .c-table--narrow tbody th {
    padding: 8px;
    width: 80px;
  }
}

.c-table--narrow tbody td {
  padding: 8px 10px 8px 20px;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .c-table--narrow tbody td {
    padding: 8px 10px 8px 15px;
  }
}

.c-splash {
  position: fixed;
  z-index: 100001;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
}

.c-splash-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  text-align: center;
}

/* --------------------------
パララックス
-------------------------- */
.c-parallax {
  position: relative;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.c-parallax-inner {
  position: relative;
  z-index: 1;
}

.c-parallax-contents {
  background: rgba(0, 0, 0, 0.25);
  padding: 100px 0;
}

@media screen and (max-width: 767px) {
  .c-parallax-contents {
    padding: 40px 0;
  }
}

/* --------------------------
テキスト整列
-------------------------- */
.u-align-left {
  text-align: left;
}

.u-align-right {
  text-align: right;
}

.u-align-center {
  text-align: center;
}

.u-align-justify {
  text-align: justify;
  word-wrap: break-word;
}

@media screen and (min-width: 768px) {
  .u-align-left--pc {
    text-align: left;
  }
  .u-align-right--pc {
    text-align: right;
  }
  .u-align-center--pc {
    text-align: center;
  }
}

@media screen and (max-width: 1180px) {
  .u-align-left--laptop {
    text-align: left;
  }
  .u-align-right--laptop {
    text-align: right;
  }
  .u-align-center--laptop {
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .u-align-left--tab {
    text-align: left;
  }
  .u-align-right--tab {
    text-align: right;
  }
  .u-align-center--tab {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .u-align-left--sp {
    text-align: left;
  }
  .u-align-right--sp {
    text-align: right;
  }
  .u-align-center--sp {
    text-align: center;
  }
}

/* --------------------------
背景色
-------------------------- */
.u-bg-section {
  background-color: #edeee1;
}

.u-bg-image {
  padding-top: 150px;
  background-image: url(../images/common/section-bg.png);
  background-position: top 0 center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .u-bg-image {
    padding-top: 100px;
  }
}

.u-bg-image--white {
  background-image: url(../images/common/section-bg--white.png);
}

.u-bg-light {
  background-color: #ccc;
}

.u-bg-white {
  background-color: #fff;
}

.u-bg-black {
  background-color: #333;
}

.u-bg-blue {
  background: rgba(1, 64, 153, 0.2);
}

/* --------------------------
枠線
-------------------------- */
.u-border-bottom {
  border-bottom: 2px solid #014099;
}

.u-border-top {
  border-top: 2px solid #014099;
}

.u-border-right {
  border-right: 2px solid #014099;
}

.u-border-left {
  border-left: 2px solid #014099;
}

.u-border--white {
  border-color: #fff;
}

/* --------------------------
Float解除
-------------------------- */
.u-clearfix::after {
  content: "";
  clear: both;
  font-size: 0;
  height: 0;
  display: block;
  visibility: hidden;
}

/* --------------------------
グリッド
-------------------------- */
.u-row {
  margin: -20px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .u-row {
    margin: -10px;
  }
}

.u-row-align-center {
  align-items: center;
}

.u-row-reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  .u-row-reverse--sp {
    flex-direction: column-reverse;
  }
}

.u-row--nomargin {
  margin: 0;
}

.u-row--narrow {
  margin: -10px;
}

.u-col-1 {
  width: 8.3333333333%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-1 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-1 {
  margin-left: 8.3333333333%;
}

.u-row--nomargin > .u-col-1 {
  padding: 0;
}

.u-row--narrow > .u-col-1 {
  padding: 10px;
}

.u-col-2 {
  width: 16.6666666667%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-2 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-2 {
  margin-left: 16.6666666667%;
}

.u-row--nomargin > .u-col-2 {
  padding: 0;
}

.u-row--narrow > .u-col-2 {
  padding: 10px;
}

.u-col-3 {
  width: 25%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-3 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-3 {
  margin-left: 25%;
}

.u-row--nomargin > .u-col-3 {
  padding: 0;
}

.u-row--narrow > .u-col-3 {
  padding: 10px;
}

.u-col-4 {
  width: 33.3333333333%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-4 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-4 {
  margin-left: 33.3333333333%;
}

.u-row--nomargin > .u-col-4 {
  padding: 0;
}

.u-row--narrow > .u-col-4 {
  padding: 10px;
}

.u-col-5 {
  width: 41.6666666667%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-5 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-5 {
  margin-left: 41.6666666667%;
}

.u-row--nomargin > .u-col-5 {
  padding: 0;
}

.u-row--narrow > .u-col-5 {
  padding: 10px;
}

.u-col-6 {
  width: 50%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-6 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-6 {
  margin-left: 50%;
}

.u-row--nomargin > .u-col-6 {
  padding: 0;
}

.u-row--narrow > .u-col-6 {
  padding: 10px;
}

.u-col-7 {
  width: 58.3333333333%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-7 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-7 {
  margin-left: 58.3333333333%;
}

.u-row--nomargin > .u-col-7 {
  padding: 0;
}

.u-row--narrow > .u-col-7 {
  padding: 10px;
}

.u-col-8 {
  width: 66.6666666667%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-8 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-8 {
  margin-left: 66.6666666667%;
}

.u-row--nomargin > .u-col-8 {
  padding: 0;
}

.u-row--narrow > .u-col-8 {
  padding: 10px;
}

.u-col-9 {
  width: 75%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-9 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-9 {
  margin-left: 75%;
}

.u-row--nomargin > .u-col-9 {
  padding: 0;
}

.u-row--narrow > .u-col-9 {
  padding: 10px;
}

.u-col-10 {
  width: 83.3333333333%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-10 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-10 {
  margin-left: 83.3333333333%;
}

.u-row--nomargin > .u-col-10 {
  padding: 0;
}

.u-row--narrow > .u-col-10 {
  padding: 10px;
}

.u-col-11 {
  width: 91.6666666667%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-11 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-11 {
  margin-left: 91.6666666667%;
}

.u-row--nomargin > .u-col-11 {
  padding: 0;
}

.u-row--narrow > .u-col-11 {
  padding: 10px;
}

.u-col-12 {
  width: 100%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .u-col-12 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-12 {
  margin-left: 100%;
}

.u-row--nomargin > .u-col-12 {
  padding: 0;
}

.u-row--narrow > .u-col-12 {
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .u-col-1--pc {
    width: 8.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-1--pc {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin > .u-col-1--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-1--pc {
    padding: 10px;
  }
  .u-col-2--pc {
    width: 16.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-2--pc {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin > .u-col-2--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-2--pc {
    padding: 10px;
  }
  .u-col-3--pc {
    width: 25%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-3--pc {
    margin-left: 25%;
  }
  .u-row--nomargin > .u-col-3--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-3--pc {
    padding: 10px;
  }
  .u-col-4--pc {
    width: 33.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-4--pc {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin > .u-col-4--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-4--pc {
    padding: 10px;
  }
  .u-col-5--pc {
    width: 41.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-5--pc {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin > .u-col-5--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-5--pc {
    padding: 10px;
  }
  .u-col-6--pc {
    width: 50%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-6--pc {
    margin-left: 50%;
  }
  .u-row--nomargin > .u-col-6--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-6--pc {
    padding: 10px;
  }
  .u-col-7--pc {
    width: 58.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-7--pc {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin > .u-col-7--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-7--pc {
    padding: 10px;
  }
  .u-col-8--pc {
    width: 66.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-8--pc {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin > .u-col-8--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-8--pc {
    padding: 10px;
  }
  .u-col-9--pc {
    width: 75%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-9--pc {
    margin-left: 75%;
  }
  .u-row--nomargin > .u-col-9--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-9--pc {
    padding: 10px;
  }
  .u-col-10--pc {
    width: 83.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-10--pc {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin > .u-col-10--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-10--pc {
    padding: 10px;
  }
  .u-col-11--pc {
    width: 91.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-11--pc {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin > .u-col-11--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-11--pc {
    padding: 10px;
  }
  .u-col-12--pc {
    width: 100%;
    padding: 20px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-12--pc {
    margin-left: 100%;
  }
  .u-row--nomargin > .u-col-12--pc {
    padding: 0;
  }
  .u-row--narrow > .u-col-12--pc {
    padding: 10px;
  }
}

@media screen and (max-width: 1180px) {
  .u-col-1--laptop {
    width: 8.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-1--laptop {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin > .u-col-1--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-1--laptop {
    padding: 10px;
  }
  .u-col-2--laptop {
    width: 16.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-2--laptop {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin > .u-col-2--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-2--laptop {
    padding: 10px;
  }
  .u-col-3--laptop {
    width: 25%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-3--laptop {
    margin-left: 25%;
  }
  .u-row--nomargin > .u-col-3--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-3--laptop {
    padding: 10px;
  }
  .u-col-4--laptop {
    width: 33.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-4--laptop {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin > .u-col-4--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-4--laptop {
    padding: 10px;
  }
  .u-col-5--laptop {
    width: 41.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-5--laptop {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin > .u-col-5--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-5--laptop {
    padding: 10px;
  }
  .u-col-6--laptop {
    width: 50%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-6--laptop {
    margin-left: 50%;
  }
  .u-row--nomargin > .u-col-6--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-6--laptop {
    padding: 10px;
  }
  .u-col-7--laptop {
    width: 58.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-7--laptop {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin > .u-col-7--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-7--laptop {
    padding: 10px;
  }
  .u-col-8--laptop {
    width: 66.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-8--laptop {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin > .u-col-8--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-8--laptop {
    padding: 10px;
  }
  .u-col-9--laptop {
    width: 75%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-9--laptop {
    margin-left: 75%;
  }
  .u-row--nomargin > .u-col-9--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-9--laptop {
    padding: 10px;
  }
  .u-col-10--laptop {
    width: 83.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-10--laptop {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin > .u-col-10--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-10--laptop {
    padding: 10px;
  }
  .u-col-11--laptop {
    width: 91.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-11--laptop {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin > .u-col-11--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-11--laptop {
    padding: 10px;
  }
  .u-col-12--laptop {
    width: 100%;
    padding: 20px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-12--laptop {
    margin-left: 100%;
  }
  .u-row--nomargin > .u-col-12--laptop {
    padding: 0;
  }
  .u-row--narrow > .u-col-12--laptop {
    padding: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .u-col-1--tab {
    width: 8.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-1--tab {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin > .u-col-1--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-1--tab {
    padding: 10px;
  }
  .u-col-2--tab {
    width: 16.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-2--tab {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin > .u-col-2--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-2--tab {
    padding: 10px;
  }
  .u-col-3--tab {
    width: 25%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-3--tab {
    margin-left: 25%;
  }
  .u-row--nomargin > .u-col-3--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-3--tab {
    padding: 10px;
  }
  .u-col-4--tab {
    width: 33.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-4--tab {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin > .u-col-4--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-4--tab {
    padding: 10px;
  }
  .u-col-5--tab {
    width: 41.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-5--tab {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin > .u-col-5--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-5--tab {
    padding: 10px;
  }
  .u-col-6--tab {
    width: 50%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-6--tab {
    margin-left: 50%;
  }
  .u-row--nomargin > .u-col-6--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-6--tab {
    padding: 10px;
  }
  .u-col-7--tab {
    width: 58.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-7--tab {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin > .u-col-7--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-7--tab {
    padding: 10px;
  }
  .u-col-8--tab {
    width: 66.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-8--tab {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin > .u-col-8--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-8--tab {
    padding: 10px;
  }
  .u-col-9--tab {
    width: 75%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-9--tab {
    margin-left: 75%;
  }
  .u-row--nomargin > .u-col-9--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-9--tab {
    padding: 10px;
  }
  .u-col-10--tab {
    width: 83.3333333333%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-10--tab {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin > .u-col-10--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-10--tab {
    padding: 10px;
  }
  .u-col-11--tab {
    width: 91.6666666667%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-11--tab {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin > .u-col-11--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-11--tab {
    padding: 10px;
  }
  .u-col-12--tab {
    width: 100%;
    padding: 20px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-12--tab {
    margin-left: 100%;
  }
  .u-row--nomargin > .u-col-12--tab {
    padding: 0;
  }
  .u-row--narrow > .u-col-12--tab {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-1--sp {
    width: 8.3333333333%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-1--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-1--sp {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin > .u-col-1--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-1--sp {
    padding: 10px;
  }
  .u-col-2--sp {
    width: 16.6666666667%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-2--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-2--sp {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin > .u-col-2--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-2--sp {
    padding: 10px;
  }
  .u-col-3--sp {
    width: 25%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-3--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-3--sp {
    margin-left: 25%;
  }
  .u-row--nomargin > .u-col-3--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-3--sp {
    padding: 10px;
  }
  .u-col-4--sp {
    width: 33.3333333333%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-4--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-4--sp {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin > .u-col-4--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-4--sp {
    padding: 10px;
  }
  .u-col-5--sp {
    width: 41.6666666667%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-5--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-5--sp {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin > .u-col-5--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-5--sp {
    padding: 10px;
  }
  .u-col-6--sp {
    width: 50%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-6--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-6--sp {
    margin-left: 50%;
  }
  .u-row--nomargin > .u-col-6--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-6--sp {
    padding: 10px;
  }
  .u-col-7--sp {
    width: 58.3333333333%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-7--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-7--sp {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin > .u-col-7--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-7--sp {
    padding: 10px;
  }
  .u-col-8--sp {
    width: 66.6666666667%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-8--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-8--sp {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin > .u-col-8--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-8--sp {
    padding: 10px;
  }
  .u-col-9--sp {
    width: 75%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-9--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-9--sp {
    margin-left: 75%;
  }
  .u-row--nomargin > .u-col-9--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-9--sp {
    padding: 10px;
  }
  .u-col-10--sp {
    width: 83.3333333333%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-10--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-10--sp {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin > .u-col-10--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-10--sp {
    padding: 10px;
  }
  .u-col-11--sp {
    width: 91.6666666667%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-11--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-11--sp {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin > .u-col-11--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-11--sp {
    padding: 10px;
  }
  .u-col-12--sp {
    width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-12--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-12--sp {
    margin-left: 100%;
  }
  .u-row--nomargin > .u-col-12--sp {
    padding: 0;
  }
  .u-row--narrow > .u-col-12--sp {
    padding: 10px;
  }
}

/* --------------------------
非表示切り替え
-------------------------- */
@media screen and (min-width: 768px) {
  .u-hide--pc {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .u-hide--tab {
    display: none;
  }
}

@media screen and (max-width: 1180px) {
  .u-hide--laptop {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .u-hide--sp {
    display: none;
  }
}

@media screen and (max-width: 1330px) {
  .u-hide--mdpi {
    display: none;
  }
}

/* --------------------------
margin、padding指定
-------------------------- */
.u-mt0 {
  margin-top: 0px !important;
}

.u-mr0 {
  margin-right: 0px !important;
}

.u-ml0 {
  margin-left: 0px !important;
}

.u-mb0 {
  margin-bottom: 0px !important;
}

.u-pt0 {
  padding-top: 0px !important;
}

.u-pr0 {
  padding-right: 0px !important;
}

.u-pl0 {
  padding-left: 0px !important;
}

.u-pb0 {
  padding-bottom: 0px !important;
}

.u-mt5 {
  margin-top: 5px !important;
}

.u-mr5 {
  margin-right: 5px !important;
}

.u-ml5 {
  margin-left: 5px !important;
}

.u-mb5 {
  margin-bottom: 5px !important;
}

.u-pt5 {
  padding-top: 5px !important;
}

.u-pr5 {
  padding-right: 5px !important;
}

.u-pl5 {
  padding-left: 5px !important;
}

.u-pb5 {
  padding-bottom: 5px !important;
}

.u-mt10 {
  margin-top: 10px !important;
}

.u-mr10 {
  margin-right: 10px !important;
}

.u-ml10 {
  margin-left: 10px !important;
}

.u-mb10 {
  margin-bottom: 10px !important;
}

.u-pt10 {
  padding-top: 10px !important;
}

.u-pr10 {
  padding-right: 10px !important;
}

.u-pl10 {
  padding-left: 10px !important;
}

.u-pb10 {
  padding-bottom: 10px !important;
}

.u-mt15 {
  margin-top: 15px !important;
}

.u-mr15 {
  margin-right: 15px !important;
}

.u-ml15 {
  margin-left: 15px !important;
}

.u-mb15 {
  margin-bottom: 15px !important;
}

.u-pt15 {
  padding-top: 15px !important;
}

.u-pr15 {
  padding-right: 15px !important;
}

.u-pl15 {
  padding-left: 15px !important;
}

.u-pb15 {
  padding-bottom: 15px !important;
}

.u-mt20 {
  margin-top: 20px !important;
}

.u-mr20 {
  margin-right: 20px !important;
}

.u-ml20 {
  margin-left: 20px !important;
}

.u-mb20 {
  margin-bottom: 20px !important;
}

.u-pt20 {
  padding-top: 20px !important;
}

.u-pr20 {
  padding-right: 20px !important;
}

.u-pl20 {
  padding-left: 20px !important;
}

.u-pb20 {
  padding-bottom: 20px !important;
}

.u-mt25 {
  margin-top: 25px !important;
}

.u-mr25 {
  margin-right: 25px !important;
}

.u-ml25 {
  margin-left: 25px !important;
}

.u-mb25 {
  margin-bottom: 25px !important;
}

.u-pt25 {
  padding-top: 25px !important;
}

.u-pr25 {
  padding-right: 25px !important;
}

.u-pl25 {
  padding-left: 25px !important;
}

.u-pb25 {
  padding-bottom: 25px !important;
}

.u-mt30 {
  margin-top: 30px !important;
}

.u-mr30 {
  margin-right: 30px !important;
}

.u-ml30 {
  margin-left: 30px !important;
}

.u-mb30 {
  margin-bottom: 30px !important;
}

.u-pt30 {
  padding-top: 30px !important;
}

.u-pr30 {
  padding-right: 30px !important;
}

.u-pl30 {
  padding-left: 30px !important;
}

.u-pb30 {
  padding-bottom: 30px !important;
}

.u-mt35 {
  margin-top: 35px !important;
}

.u-mr35 {
  margin-right: 35px !important;
}

.u-ml35 {
  margin-left: 35px !important;
}

.u-mb35 {
  margin-bottom: 35px !important;
}

.u-pt35 {
  padding-top: 35px !important;
}

.u-pr35 {
  padding-right: 35px !important;
}

.u-pl35 {
  padding-left: 35px !important;
}

.u-pb35 {
  padding-bottom: 35px !important;
}

.u-mt40 {
  margin-top: 40px !important;
}

.u-mr40 {
  margin-right: 40px !important;
}

.u-ml40 {
  margin-left: 40px !important;
}

.u-mb40 {
  margin-bottom: 40px !important;
}

.u-pt40 {
  padding-top: 40px !important;
}

.u-pr40 {
  padding-right: 40px !important;
}

.u-pl40 {
  padding-left: 40px !important;
}

.u-pb40 {
  padding-bottom: 40px !important;
}

.u-mt45 {
  margin-top: 45px !important;
}

.u-mr45 {
  margin-right: 45px !important;
}

.u-ml45 {
  margin-left: 45px !important;
}

.u-mb45 {
  margin-bottom: 45px !important;
}

.u-pt45 {
  padding-top: 45px !important;
}

.u-pr45 {
  padding-right: 45px !important;
}

.u-pl45 {
  padding-left: 45px !important;
}

.u-pb45 {
  padding-bottom: 45px !important;
}

.u-mt50 {
  margin-top: 50px !important;
}

.u-mr50 {
  margin-right: 50px !important;
}

.u-ml50 {
  margin-left: 50px !important;
}

.u-mb50 {
  margin-bottom: 50px !important;
}

.u-pt50 {
  padding-top: 50px !important;
}

.u-pr50 {
  padding-right: 50px !important;
}

.u-pl50 {
  padding-left: 50px !important;
}

.u-pb50 {
  padding-bottom: 50px !important;
}

.u-mt55 {
  margin-top: 55px !important;
}

.u-mr55 {
  margin-right: 55px !important;
}

.u-ml55 {
  margin-left: 55px !important;
}

.u-mb55 {
  margin-bottom: 55px !important;
}

.u-pt55 {
  padding-top: 55px !important;
}

.u-pr55 {
  padding-right: 55px !important;
}

.u-pl55 {
  padding-left: 55px !important;
}

.u-pb55 {
  padding-bottom: 55px !important;
}

.u-mt60 {
  margin-top: 60px !important;
}

.u-mr60 {
  margin-right: 60px !important;
}

.u-ml60 {
  margin-left: 60px !important;
}

.u-mb60 {
  margin-bottom: 60px !important;
}

.u-pt60 {
  padding-top: 60px !important;
}

.u-pr60 {
  padding-right: 60px !important;
}

.u-pl60 {
  padding-left: 60px !important;
}

.u-pb60 {
  padding-bottom: 60px !important;
}

.u-mt65 {
  margin-top: 65px !important;
}

.u-mr65 {
  margin-right: 65px !important;
}

.u-ml65 {
  margin-left: 65px !important;
}

.u-mb65 {
  margin-bottom: 65px !important;
}

.u-pt65 {
  padding-top: 65px !important;
}

.u-pr65 {
  padding-right: 65px !important;
}

.u-pl65 {
  padding-left: 65px !important;
}

.u-pb65 {
  padding-bottom: 65px !important;
}

.u-mt70 {
  margin-top: 70px !important;
}

.u-mr70 {
  margin-right: 70px !important;
}

.u-ml70 {
  margin-left: 70px !important;
}

.u-mb70 {
  margin-bottom: 70px !important;
}

.u-pt70 {
  padding-top: 70px !important;
}

.u-pr70 {
  padding-right: 70px !important;
}

.u-pl70 {
  padding-left: 70px !important;
}

.u-pb70 {
  padding-bottom: 70px !important;
}

.u-mt75 {
  margin-top: 75px !important;
}

.u-mr75 {
  margin-right: 75px !important;
}

.u-ml75 {
  margin-left: 75px !important;
}

.u-mb75 {
  margin-bottom: 75px !important;
}

.u-pt75 {
  padding-top: 75px !important;
}

.u-pr75 {
  padding-right: 75px !important;
}

.u-pl75 {
  padding-left: 75px !important;
}

.u-pb75 {
  padding-bottom: 75px !important;
}

.u-mt80 {
  margin-top: 80px !important;
}

.u-mr80 {
  margin-right: 80px !important;
}

.u-ml80 {
  margin-left: 80px !important;
}

.u-mb80 {
  margin-bottom: 80px !important;
}

.u-pt80 {
  padding-top: 80px !important;
}

.u-pr80 {
  padding-right: 80px !important;
}

.u-pl80 {
  padding-left: 80px !important;
}

.u-pb80 {
  padding-bottom: 80px !important;
}

.u-mt85 {
  margin-top: 85px !important;
}

.u-mr85 {
  margin-right: 85px !important;
}

.u-ml85 {
  margin-left: 85px !important;
}

.u-mb85 {
  margin-bottom: 85px !important;
}

.u-pt85 {
  padding-top: 85px !important;
}

.u-pr85 {
  padding-right: 85px !important;
}

.u-pl85 {
  padding-left: 85px !important;
}

.u-pb85 {
  padding-bottom: 85px !important;
}

.u-mt90 {
  margin-top: 90px !important;
}

.u-mr90 {
  margin-right: 90px !important;
}

.u-ml90 {
  margin-left: 90px !important;
}

.u-mb90 {
  margin-bottom: 90px !important;
}

.u-pt90 {
  padding-top: 90px !important;
}

.u-pr90 {
  padding-right: 90px !important;
}

.u-pl90 {
  padding-left: 90px !important;
}

.u-pb90 {
  padding-bottom: 90px !important;
}

.u-mt95 {
  margin-top: 95px !important;
}

.u-mr95 {
  margin-right: 95px !important;
}

.u-ml95 {
  margin-left: 95px !important;
}

.u-mb95 {
  margin-bottom: 95px !important;
}

.u-pt95 {
  padding-top: 95px !important;
}

.u-pr95 {
  padding-right: 95px !important;
}

.u-pl95 {
  padding-left: 95px !important;
}

.u-pb95 {
  padding-bottom: 95px !important;
}

.u-mt100 {
  margin-top: 100px !important;
}

.u-mr100 {
  margin-right: 100px !important;
}

.u-ml100 {
  margin-left: 100px !important;
}

.u-mb100 {
  margin-bottom: 100px !important;
}

.u-pt100 {
  padding-top: 100px !important;
}

.u-pr100 {
  padding-right: 100px !important;
}

.u-pl100 {
  padding-left: 100px !important;
}

.u-pb100 {
  padding-bottom: 100px !important;
}

@media screen and (max-width: 767px) {
  .u-mt0--sp {
    margin-top: 0px !important;
  }
  .u-mr0--sp {
    margin-right: 0px !important;
  }
  .u-ml0--sp {
    margin-left: 0px !important;
  }
  .u-mb0--sp {
    margin-bottom: 0px !important;
  }
  .u-pt0--sp {
    padding-top: 0px !important;
  }
  .u-pr0--sp {
    padding-right: 0px !important;
  }
  .u-pl0--sp {
    padding-left: 0px !important;
  }
  .u-pb0--sp {
    padding-bottom: 0px !important;
  }
  .u-mt5--sp {
    margin-top: 5px !important;
  }
  .u-mr5--sp {
    margin-right: 5px !important;
  }
  .u-ml5--sp {
    margin-left: 5px !important;
  }
  .u-mb5--sp {
    margin-bottom: 5px !important;
  }
  .u-pt5--sp {
    padding-top: 5px !important;
  }
  .u-pr5--sp {
    padding-right: 5px !important;
  }
  .u-pl5--sp {
    padding-left: 5px !important;
  }
  .u-pb5--sp {
    padding-bottom: 5px !important;
  }
  .u-mt10--sp {
    margin-top: 10px !important;
  }
  .u-mr10--sp {
    margin-right: 10px !important;
  }
  .u-ml10--sp {
    margin-left: 10px !important;
  }
  .u-mb10--sp {
    margin-bottom: 10px !important;
  }
  .u-pt10--sp {
    padding-top: 10px !important;
  }
  .u-pr10--sp {
    padding-right: 10px !important;
  }
  .u-pl10--sp {
    padding-left: 10px !important;
  }
  .u-pb10--sp {
    padding-bottom: 10px !important;
  }
  .u-mt15--sp {
    margin-top: 15px !important;
  }
  .u-mr15--sp {
    margin-right: 15px !important;
  }
  .u-ml15--sp {
    margin-left: 15px !important;
  }
  .u-mb15--sp {
    margin-bottom: 15px !important;
  }
  .u-pt15--sp {
    padding-top: 15px !important;
  }
  .u-pr15--sp {
    padding-right: 15px !important;
  }
  .u-pl15--sp {
    padding-left: 15px !important;
  }
  .u-pb15--sp {
    padding-bottom: 15px !important;
  }
  .u-mt20--sp {
    margin-top: 20px !important;
  }
  .u-mr20--sp {
    margin-right: 20px !important;
  }
  .u-ml20--sp {
    margin-left: 20px !important;
  }
  .u-mb20--sp {
    margin-bottom: 20px !important;
  }
  .u-pt20--sp {
    padding-top: 20px !important;
  }
  .u-pr20--sp {
    padding-right: 20px !important;
  }
  .u-pl20--sp {
    padding-left: 20px !important;
  }
  .u-pb20--sp {
    padding-bottom: 20px !important;
  }
  .u-mt25--sp {
    margin-top: 25px !important;
  }
  .u-mr25--sp {
    margin-right: 25px !important;
  }
  .u-ml25--sp {
    margin-left: 25px !important;
  }
  .u-mb25--sp {
    margin-bottom: 25px !important;
  }
  .u-pt25--sp {
    padding-top: 25px !important;
  }
  .u-pr25--sp {
    padding-right: 25px !important;
  }
  .u-pl25--sp {
    padding-left: 25px !important;
  }
  .u-pb25--sp {
    padding-bottom: 25px !important;
  }
  .u-mt30--sp {
    margin-top: 30px !important;
  }
  .u-mr30--sp {
    margin-right: 30px !important;
  }
  .u-ml30--sp {
    margin-left: 30px !important;
  }
  .u-mb30--sp {
    margin-bottom: 30px !important;
  }
  .u-pt30--sp {
    padding-top: 30px !important;
  }
  .u-pr30--sp {
    padding-right: 30px !important;
  }
  .u-pl30--sp {
    padding-left: 30px !important;
  }
  .u-pb30--sp {
    padding-bottom: 30px !important;
  }
  .u-mt35--sp {
    margin-top: 35px !important;
  }
  .u-mr35--sp {
    margin-right: 35px !important;
  }
  .u-ml35--sp {
    margin-left: 35px !important;
  }
  .u-mb35--sp {
    margin-bottom: 35px !important;
  }
  .u-pt35--sp {
    padding-top: 35px !important;
  }
  .u-pr35--sp {
    padding-right: 35px !important;
  }
  .u-pl35--sp {
    padding-left: 35px !important;
  }
  .u-pb35--sp {
    padding-bottom: 35px !important;
  }
  .u-mt40--sp {
    margin-top: 40px !important;
  }
  .u-mr40--sp {
    margin-right: 40px !important;
  }
  .u-ml40--sp {
    margin-left: 40px !important;
  }
  .u-mb40--sp {
    margin-bottom: 40px !important;
  }
  .u-pt40--sp {
    padding-top: 40px !important;
  }
  .u-pr40--sp {
    padding-right: 40px !important;
  }
  .u-pl40--sp {
    padding-left: 40px !important;
  }
  .u-pb40--sp {
    padding-bottom: 40px !important;
  }
  .u-mt45--sp {
    margin-top: 45px !important;
  }
  .u-mr45--sp {
    margin-right: 45px !important;
  }
  .u-ml45--sp {
    margin-left: 45px !important;
  }
  .u-mb45--sp {
    margin-bottom: 45px !important;
  }
  .u-pt45--sp {
    padding-top: 45px !important;
  }
  .u-pr45--sp {
    padding-right: 45px !important;
  }
  .u-pl45--sp {
    padding-left: 45px !important;
  }
  .u-pb45--sp {
    padding-bottom: 45px !important;
  }
  .u-mt50--sp {
    margin-top: 50px !important;
  }
  .u-mr50--sp {
    margin-right: 50px !important;
  }
  .u-ml50--sp {
    margin-left: 50px !important;
  }
  .u-mb50--sp {
    margin-bottom: 50px !important;
  }
  .u-pt50--sp {
    padding-top: 50px !important;
  }
  .u-pr50--sp {
    padding-right: 50px !important;
  }
  .u-pl50--sp {
    padding-left: 50px !important;
  }
  .u-pb50--sp {
    padding-bottom: 50px !important;
  }
  .u-mt55--sp {
    margin-top: 55px !important;
  }
  .u-mr55--sp {
    margin-right: 55px !important;
  }
  .u-ml55--sp {
    margin-left: 55px !important;
  }
  .u-mb55--sp {
    margin-bottom: 55px !important;
  }
  .u-pt55--sp {
    padding-top: 55px !important;
  }
  .u-pr55--sp {
    padding-right: 55px !important;
  }
  .u-pl55--sp {
    padding-left: 55px !important;
  }
  .u-pb55--sp {
    padding-bottom: 55px !important;
  }
  .u-mt60--sp {
    margin-top: 60px !important;
  }
  .u-mr60--sp {
    margin-right: 60px !important;
  }
  .u-ml60--sp {
    margin-left: 60px !important;
  }
  .u-mb60--sp {
    margin-bottom: 60px !important;
  }
  .u-pt60--sp {
    padding-top: 60px !important;
  }
  .u-pr60--sp {
    padding-right: 60px !important;
  }
  .u-pl60--sp {
    padding-left: 60px !important;
  }
  .u-pb60--sp {
    padding-bottom: 60px !important;
  }
  .u-mt65--sp {
    margin-top: 65px !important;
  }
  .u-mr65--sp {
    margin-right: 65px !important;
  }
  .u-ml65--sp {
    margin-left: 65px !important;
  }
  .u-mb65--sp {
    margin-bottom: 65px !important;
  }
  .u-pt65--sp {
    padding-top: 65px !important;
  }
  .u-pr65--sp {
    padding-right: 65px !important;
  }
  .u-pl65--sp {
    padding-left: 65px !important;
  }
  .u-pb65--sp {
    padding-bottom: 65px !important;
  }
  .u-mt70--sp {
    margin-top: 70px !important;
  }
  .u-mr70--sp {
    margin-right: 70px !important;
  }
  .u-ml70--sp {
    margin-left: 70px !important;
  }
  .u-mb70--sp {
    margin-bottom: 70px !important;
  }
  .u-pt70--sp {
    padding-top: 70px !important;
  }
  .u-pr70--sp {
    padding-right: 70px !important;
  }
  .u-pl70--sp {
    padding-left: 70px !important;
  }
  .u-pb70--sp {
    padding-bottom: 70px !important;
  }
  .u-mt75--sp {
    margin-top: 75px !important;
  }
  .u-mr75--sp {
    margin-right: 75px !important;
  }
  .u-ml75--sp {
    margin-left: 75px !important;
  }
  .u-mb75--sp {
    margin-bottom: 75px !important;
  }
  .u-pt75--sp {
    padding-top: 75px !important;
  }
  .u-pr75--sp {
    padding-right: 75px !important;
  }
  .u-pl75--sp {
    padding-left: 75px !important;
  }
  .u-pb75--sp {
    padding-bottom: 75px !important;
  }
  .u-mt80--sp {
    margin-top: 80px !important;
  }
  .u-mr80--sp {
    margin-right: 80px !important;
  }
  .u-ml80--sp {
    margin-left: 80px !important;
  }
  .u-mb80--sp {
    margin-bottom: 80px !important;
  }
  .u-pt80--sp {
    padding-top: 80px !important;
  }
  .u-pr80--sp {
    padding-right: 80px !important;
  }
  .u-pl80--sp {
    padding-left: 80px !important;
  }
  .u-pb80--sp {
    padding-bottom: 80px !important;
  }
  .u-mt85--sp {
    margin-top: 85px !important;
  }
  .u-mr85--sp {
    margin-right: 85px !important;
  }
  .u-ml85--sp {
    margin-left: 85px !important;
  }
  .u-mb85--sp {
    margin-bottom: 85px !important;
  }
  .u-pt85--sp {
    padding-top: 85px !important;
  }
  .u-pr85--sp {
    padding-right: 85px !important;
  }
  .u-pl85--sp {
    padding-left: 85px !important;
  }
  .u-pb85--sp {
    padding-bottom: 85px !important;
  }
  .u-mt90--sp {
    margin-top: 90px !important;
  }
  .u-mr90--sp {
    margin-right: 90px !important;
  }
  .u-ml90--sp {
    margin-left: 90px !important;
  }
  .u-mb90--sp {
    margin-bottom: 90px !important;
  }
  .u-pt90--sp {
    padding-top: 90px !important;
  }
  .u-pr90--sp {
    padding-right: 90px !important;
  }
  .u-pl90--sp {
    padding-left: 90px !important;
  }
  .u-pb90--sp {
    padding-bottom: 90px !important;
  }
  .u-mt95--sp {
    margin-top: 95px !important;
  }
  .u-mr95--sp {
    margin-right: 95px !important;
  }
  .u-ml95--sp {
    margin-left: 95px !important;
  }
  .u-mb95--sp {
    margin-bottom: 95px !important;
  }
  .u-pt95--sp {
    padding-top: 95px !important;
  }
  .u-pr95--sp {
    padding-right: 95px !important;
  }
  .u-pl95--sp {
    padding-left: 95px !important;
  }
  .u-pb95--sp {
    padding-bottom: 95px !important;
  }
  .u-mt100--sp {
    margin-top: 100px !important;
  }
  .u-mr100--sp {
    margin-right: 100px !important;
  }
  .u-ml100--sp {
    margin-left: 100px !important;
  }
  .u-mb100--sp {
    margin-bottom: 100px !important;
  }
  .u-pt100--sp {
    padding-top: 100px !important;
  }
  .u-pr100--sp {
    padding-right: 100px !important;
  }
  .u-pl100--sp {
    padding-left: 100px !important;
  }
  .u-pb100--sp {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 1024px) {
  .u-mt0--tab {
    margin-top: 0px !important;
  }
  .u-mr0--tab {
    margin-right: 0px !important;
  }
  .u-ml0--tab {
    margin-left: 0px !important;
  }
  .u-mb0--tab {
    margin-bottom: 0px !important;
  }
  .u-pt0--tab {
    padding-top: 0px !important;
  }
  .u-pr0--tab {
    padding-right: 0px !important;
  }
  .u-pl0--tab {
    padding-left: 0px !important;
  }
  .u-pb0--tab {
    padding-bottom: 0px !important;
  }
  .u-mt5--tab {
    margin-top: 5px !important;
  }
  .u-mr5--tab {
    margin-right: 5px !important;
  }
  .u-ml5--tab {
    margin-left: 5px !important;
  }
  .u-mb5--tab {
    margin-bottom: 5px !important;
  }
  .u-pt5--tab {
    padding-top: 5px !important;
  }
  .u-pr5--tab {
    padding-right: 5px !important;
  }
  .u-pl5--tab {
    padding-left: 5px !important;
  }
  .u-pb5--tab {
    padding-bottom: 5px !important;
  }
  .u-mt10--tab {
    margin-top: 10px !important;
  }
  .u-mr10--tab {
    margin-right: 10px !important;
  }
  .u-ml10--tab {
    margin-left: 10px !important;
  }
  .u-mb10--tab {
    margin-bottom: 10px !important;
  }
  .u-pt10--tab {
    padding-top: 10px !important;
  }
  .u-pr10--tab {
    padding-right: 10px !important;
  }
  .u-pl10--tab {
    padding-left: 10px !important;
  }
  .u-pb10--tab {
    padding-bottom: 10px !important;
  }
  .u-mt15--tab {
    margin-top: 15px !important;
  }
  .u-mr15--tab {
    margin-right: 15px !important;
  }
  .u-ml15--tab {
    margin-left: 15px !important;
  }
  .u-mb15--tab {
    margin-bottom: 15px !important;
  }
  .u-pt15--tab {
    padding-top: 15px !important;
  }
  .u-pr15--tab {
    padding-right: 15px !important;
  }
  .u-pl15--tab {
    padding-left: 15px !important;
  }
  .u-pb15--tab {
    padding-bottom: 15px !important;
  }
  .u-mt20--tab {
    margin-top: 20px !important;
  }
  .u-mr20--tab {
    margin-right: 20px !important;
  }
  .u-ml20--tab {
    margin-left: 20px !important;
  }
  .u-mb20--tab {
    margin-bottom: 20px !important;
  }
  .u-pt20--tab {
    padding-top: 20px !important;
  }
  .u-pr20--tab {
    padding-right: 20px !important;
  }
  .u-pl20--tab {
    padding-left: 20px !important;
  }
  .u-pb20--tab {
    padding-bottom: 20px !important;
  }
  .u-mt25--tab {
    margin-top: 25px !important;
  }
  .u-mr25--tab {
    margin-right: 25px !important;
  }
  .u-ml25--tab {
    margin-left: 25px !important;
  }
  .u-mb25--tab {
    margin-bottom: 25px !important;
  }
  .u-pt25--tab {
    padding-top: 25px !important;
  }
  .u-pr25--tab {
    padding-right: 25px !important;
  }
  .u-pl25--tab {
    padding-left: 25px !important;
  }
  .u-pb25--tab {
    padding-bottom: 25px !important;
  }
  .u-mt30--tab {
    margin-top: 30px !important;
  }
  .u-mr30--tab {
    margin-right: 30px !important;
  }
  .u-ml30--tab {
    margin-left: 30px !important;
  }
  .u-mb30--tab {
    margin-bottom: 30px !important;
  }
  .u-pt30--tab {
    padding-top: 30px !important;
  }
  .u-pr30--tab {
    padding-right: 30px !important;
  }
  .u-pl30--tab {
    padding-left: 30px !important;
  }
  .u-pb30--tab {
    padding-bottom: 30px !important;
  }
  .u-mt35--tab {
    margin-top: 35px !important;
  }
  .u-mr35--tab {
    margin-right: 35px !important;
  }
  .u-ml35--tab {
    margin-left: 35px !important;
  }
  .u-mb35--tab {
    margin-bottom: 35px !important;
  }
  .u-pt35--tab {
    padding-top: 35px !important;
  }
  .u-pr35--tab {
    padding-right: 35px !important;
  }
  .u-pl35--tab {
    padding-left: 35px !important;
  }
  .u-pb35--tab {
    padding-bottom: 35px !important;
  }
  .u-mt40--tab {
    margin-top: 40px !important;
  }
  .u-mr40--tab {
    margin-right: 40px !important;
  }
  .u-ml40--tab {
    margin-left: 40px !important;
  }
  .u-mb40--tab {
    margin-bottom: 40px !important;
  }
  .u-pt40--tab {
    padding-top: 40px !important;
  }
  .u-pr40--tab {
    padding-right: 40px !important;
  }
  .u-pl40--tab {
    padding-left: 40px !important;
  }
  .u-pb40--tab {
    padding-bottom: 40px !important;
  }
  .u-mt45--tab {
    margin-top: 45px !important;
  }
  .u-mr45--tab {
    margin-right: 45px !important;
  }
  .u-ml45--tab {
    margin-left: 45px !important;
  }
  .u-mb45--tab {
    margin-bottom: 45px !important;
  }
  .u-pt45--tab {
    padding-top: 45px !important;
  }
  .u-pr45--tab {
    padding-right: 45px !important;
  }
  .u-pl45--tab {
    padding-left: 45px !important;
  }
  .u-pb45--tab {
    padding-bottom: 45px !important;
  }
  .u-mt50--tab {
    margin-top: 50px !important;
  }
  .u-mr50--tab {
    margin-right: 50px !important;
  }
  .u-ml50--tab {
    margin-left: 50px !important;
  }
  .u-mb50--tab {
    margin-bottom: 50px !important;
  }
  .u-pt50--tab {
    padding-top: 50px !important;
  }
  .u-pr50--tab {
    padding-right: 50px !important;
  }
  .u-pl50--tab {
    padding-left: 50px !important;
  }
  .u-pb50--tab {
    padding-bottom: 50px !important;
  }
  .u-mt55--tab {
    margin-top: 55px !important;
  }
  .u-mr55--tab {
    margin-right: 55px !important;
  }
  .u-ml55--tab {
    margin-left: 55px !important;
  }
  .u-mb55--tab {
    margin-bottom: 55px !important;
  }
  .u-pt55--tab {
    padding-top: 55px !important;
  }
  .u-pr55--tab {
    padding-right: 55px !important;
  }
  .u-pl55--tab {
    padding-left: 55px !important;
  }
  .u-pb55--tab {
    padding-bottom: 55px !important;
  }
  .u-mt60--tab {
    margin-top: 60px !important;
  }
  .u-mr60--tab {
    margin-right: 60px !important;
  }
  .u-ml60--tab {
    margin-left: 60px !important;
  }
  .u-mb60--tab {
    margin-bottom: 60px !important;
  }
  .u-pt60--tab {
    padding-top: 60px !important;
  }
  .u-pr60--tab {
    padding-right: 60px !important;
  }
  .u-pl60--tab {
    padding-left: 60px !important;
  }
  .u-pb60--tab {
    padding-bottom: 60px !important;
  }
  .u-mt65--tab {
    margin-top: 65px !important;
  }
  .u-mr65--tab {
    margin-right: 65px !important;
  }
  .u-ml65--tab {
    margin-left: 65px !important;
  }
  .u-mb65--tab {
    margin-bottom: 65px !important;
  }
  .u-pt65--tab {
    padding-top: 65px !important;
  }
  .u-pr65--tab {
    padding-right: 65px !important;
  }
  .u-pl65--tab {
    padding-left: 65px !important;
  }
  .u-pb65--tab {
    padding-bottom: 65px !important;
  }
  .u-mt70--tab {
    margin-top: 70px !important;
  }
  .u-mr70--tab {
    margin-right: 70px !important;
  }
  .u-ml70--tab {
    margin-left: 70px !important;
  }
  .u-mb70--tab {
    margin-bottom: 70px !important;
  }
  .u-pt70--tab {
    padding-top: 70px !important;
  }
  .u-pr70--tab {
    padding-right: 70px !important;
  }
  .u-pl70--tab {
    padding-left: 70px !important;
  }
  .u-pb70--tab {
    padding-bottom: 70px !important;
  }
  .u-mt75--tab {
    margin-top: 75px !important;
  }
  .u-mr75--tab {
    margin-right: 75px !important;
  }
  .u-ml75--tab {
    margin-left: 75px !important;
  }
  .u-mb75--tab {
    margin-bottom: 75px !important;
  }
  .u-pt75--tab {
    padding-top: 75px !important;
  }
  .u-pr75--tab {
    padding-right: 75px !important;
  }
  .u-pl75--tab {
    padding-left: 75px !important;
  }
  .u-pb75--tab {
    padding-bottom: 75px !important;
  }
  .u-mt80--tab {
    margin-top: 80px !important;
  }
  .u-mr80--tab {
    margin-right: 80px !important;
  }
  .u-ml80--tab {
    margin-left: 80px !important;
  }
  .u-mb80--tab {
    margin-bottom: 80px !important;
  }
  .u-pt80--tab {
    padding-top: 80px !important;
  }
  .u-pr80--tab {
    padding-right: 80px !important;
  }
  .u-pl80--tab {
    padding-left: 80px !important;
  }
  .u-pb80--tab {
    padding-bottom: 80px !important;
  }
  .u-mt85--tab {
    margin-top: 85px !important;
  }
  .u-mr85--tab {
    margin-right: 85px !important;
  }
  .u-ml85--tab {
    margin-left: 85px !important;
  }
  .u-mb85--tab {
    margin-bottom: 85px !important;
  }
  .u-pt85--tab {
    padding-top: 85px !important;
  }
  .u-pr85--tab {
    padding-right: 85px !important;
  }
  .u-pl85--tab {
    padding-left: 85px !important;
  }
  .u-pb85--tab {
    padding-bottom: 85px !important;
  }
  .u-mt90--tab {
    margin-top: 90px !important;
  }
  .u-mr90--tab {
    margin-right: 90px !important;
  }
  .u-ml90--tab {
    margin-left: 90px !important;
  }
  .u-mb90--tab {
    margin-bottom: 90px !important;
  }
  .u-pt90--tab {
    padding-top: 90px !important;
  }
  .u-pr90--tab {
    padding-right: 90px !important;
  }
  .u-pl90--tab {
    padding-left: 90px !important;
  }
  .u-pb90--tab {
    padding-bottom: 90px !important;
  }
  .u-mt95--tab {
    margin-top: 95px !important;
  }
  .u-mr95--tab {
    margin-right: 95px !important;
  }
  .u-ml95--tab {
    margin-left: 95px !important;
  }
  .u-mb95--tab {
    margin-bottom: 95px !important;
  }
  .u-pt95--tab {
    padding-top: 95px !important;
  }
  .u-pr95--tab {
    padding-right: 95px !important;
  }
  .u-pl95--tab {
    padding-left: 95px !important;
  }
  .u-pb95--tab {
    padding-bottom: 95px !important;
  }
  .u-mt100--tab {
    margin-top: 100px !important;
  }
  .u-mr100--tab {
    margin-right: 100px !important;
  }
  .u-ml100--tab {
    margin-left: 100px !important;
  }
  .u-mb100--tab {
    margin-bottom: 100px !important;
  }
  .u-pt100--tab {
    padding-top: 100px !important;
  }
  .u-pr100--tab {
    padding-right: 100px !important;
  }
  .u-pl100--tab {
    padding-left: 100px !important;
  }
  .u-pb100--tab {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 1180px) {
  .u-mt0--laptop {
    margin-top: 0px !important;
  }
  .u-mr0--laptop {
    margin-right: 0px !important;
  }
  .u-ml0--laptop {
    margin-left: 0px !important;
  }
  .u-mb0--laptop {
    margin-bottom: 0px !important;
  }
  .u-pt0--laptop {
    padding-top: 0px !important;
  }
  .u-pr0--laptop {
    padding-right: 0px !important;
  }
  .u-pl0--laptop {
    padding-left: 0px !important;
  }
  .u-pb0--laptop {
    padding-bottom: 0px !important;
  }
  .u-mt5--laptop {
    margin-top: 5px !important;
  }
  .u-mr5--laptop {
    margin-right: 5px !important;
  }
  .u-ml5--laptop {
    margin-left: 5px !important;
  }
  .u-mb5--laptop {
    margin-bottom: 5px !important;
  }
  .u-pt5--laptop {
    padding-top: 5px !important;
  }
  .u-pr5--laptop {
    padding-right: 5px !important;
  }
  .u-pl5--laptop {
    padding-left: 5px !important;
  }
  .u-pb5--laptop {
    padding-bottom: 5px !important;
  }
  .u-mt10--laptop {
    margin-top: 10px !important;
  }
  .u-mr10--laptop {
    margin-right: 10px !important;
  }
  .u-ml10--laptop {
    margin-left: 10px !important;
  }
  .u-mb10--laptop {
    margin-bottom: 10px !important;
  }
  .u-pt10--laptop {
    padding-top: 10px !important;
  }
  .u-pr10--laptop {
    padding-right: 10px !important;
  }
  .u-pl10--laptop {
    padding-left: 10px !important;
  }
  .u-pb10--laptop {
    padding-bottom: 10px !important;
  }
  .u-mt15--laptop {
    margin-top: 15px !important;
  }
  .u-mr15--laptop {
    margin-right: 15px !important;
  }
  .u-ml15--laptop {
    margin-left: 15px !important;
  }
  .u-mb15--laptop {
    margin-bottom: 15px !important;
  }
  .u-pt15--laptop {
    padding-top: 15px !important;
  }
  .u-pr15--laptop {
    padding-right: 15px !important;
  }
  .u-pl15--laptop {
    padding-left: 15px !important;
  }
  .u-pb15--laptop {
    padding-bottom: 15px !important;
  }
  .u-mt20--laptop {
    margin-top: 20px !important;
  }
  .u-mr20--laptop {
    margin-right: 20px !important;
  }
  .u-ml20--laptop {
    margin-left: 20px !important;
  }
  .u-mb20--laptop {
    margin-bottom: 20px !important;
  }
  .u-pt20--laptop {
    padding-top: 20px !important;
  }
  .u-pr20--laptop {
    padding-right: 20px !important;
  }
  .u-pl20--laptop {
    padding-left: 20px !important;
  }
  .u-pb20--laptop {
    padding-bottom: 20px !important;
  }
  .u-mt25--laptop {
    margin-top: 25px !important;
  }
  .u-mr25--laptop {
    margin-right: 25px !important;
  }
  .u-ml25--laptop {
    margin-left: 25px !important;
  }
  .u-mb25--laptop {
    margin-bottom: 25px !important;
  }
  .u-pt25--laptop {
    padding-top: 25px !important;
  }
  .u-pr25--laptop {
    padding-right: 25px !important;
  }
  .u-pl25--laptop {
    padding-left: 25px !important;
  }
  .u-pb25--laptop {
    padding-bottom: 25px !important;
  }
  .u-mt30--laptop {
    margin-top: 30px !important;
  }
  .u-mr30--laptop {
    margin-right: 30px !important;
  }
  .u-ml30--laptop {
    margin-left: 30px !important;
  }
  .u-mb30--laptop {
    margin-bottom: 30px !important;
  }
  .u-pt30--laptop {
    padding-top: 30px !important;
  }
  .u-pr30--laptop {
    padding-right: 30px !important;
  }
  .u-pl30--laptop {
    padding-left: 30px !important;
  }
  .u-pb30--laptop {
    padding-bottom: 30px !important;
  }
  .u-mt35--laptop {
    margin-top: 35px !important;
  }
  .u-mr35--laptop {
    margin-right: 35px !important;
  }
  .u-ml35--laptop {
    margin-left: 35px !important;
  }
  .u-mb35--laptop {
    margin-bottom: 35px !important;
  }
  .u-pt35--laptop {
    padding-top: 35px !important;
  }
  .u-pr35--laptop {
    padding-right: 35px !important;
  }
  .u-pl35--laptop {
    padding-left: 35px !important;
  }
  .u-pb35--laptop {
    padding-bottom: 35px !important;
  }
  .u-mt40--laptop {
    margin-top: 40px !important;
  }
  .u-mr40--laptop {
    margin-right: 40px !important;
  }
  .u-ml40--laptop {
    margin-left: 40px !important;
  }
  .u-mb40--laptop {
    margin-bottom: 40px !important;
  }
  .u-pt40--laptop {
    padding-top: 40px !important;
  }
  .u-pr40--laptop {
    padding-right: 40px !important;
  }
  .u-pl40--laptop {
    padding-left: 40px !important;
  }
  .u-pb40--laptop {
    padding-bottom: 40px !important;
  }
  .u-mt45--laptop {
    margin-top: 45px !important;
  }
  .u-mr45--laptop {
    margin-right: 45px !important;
  }
  .u-ml45--laptop {
    margin-left: 45px !important;
  }
  .u-mb45--laptop {
    margin-bottom: 45px !important;
  }
  .u-pt45--laptop {
    padding-top: 45px !important;
  }
  .u-pr45--laptop {
    padding-right: 45px !important;
  }
  .u-pl45--laptop {
    padding-left: 45px !important;
  }
  .u-pb45--laptop {
    padding-bottom: 45px !important;
  }
  .u-mt50--laptop {
    margin-top: 50px !important;
  }
  .u-mr50--laptop {
    margin-right: 50px !important;
  }
  .u-ml50--laptop {
    margin-left: 50px !important;
  }
  .u-mb50--laptop {
    margin-bottom: 50px !important;
  }
  .u-pt50--laptop {
    padding-top: 50px !important;
  }
  .u-pr50--laptop {
    padding-right: 50px !important;
  }
  .u-pl50--laptop {
    padding-left: 50px !important;
  }
  .u-pb50--laptop {
    padding-bottom: 50px !important;
  }
  .u-mt55--laptop {
    margin-top: 55px !important;
  }
  .u-mr55--laptop {
    margin-right: 55px !important;
  }
  .u-ml55--laptop {
    margin-left: 55px !important;
  }
  .u-mb55--laptop {
    margin-bottom: 55px !important;
  }
  .u-pt55--laptop {
    padding-top: 55px !important;
  }
  .u-pr55--laptop {
    padding-right: 55px !important;
  }
  .u-pl55--laptop {
    padding-left: 55px !important;
  }
  .u-pb55--laptop {
    padding-bottom: 55px !important;
  }
  .u-mt60--laptop {
    margin-top: 60px !important;
  }
  .u-mr60--laptop {
    margin-right: 60px !important;
  }
  .u-ml60--laptop {
    margin-left: 60px !important;
  }
  .u-mb60--laptop {
    margin-bottom: 60px !important;
  }
  .u-pt60--laptop {
    padding-top: 60px !important;
  }
  .u-pr60--laptop {
    padding-right: 60px !important;
  }
  .u-pl60--laptop {
    padding-left: 60px !important;
  }
  .u-pb60--laptop {
    padding-bottom: 60px !important;
  }
  .u-mt65--laptop {
    margin-top: 65px !important;
  }
  .u-mr65--laptop {
    margin-right: 65px !important;
  }
  .u-ml65--laptop {
    margin-left: 65px !important;
  }
  .u-mb65--laptop {
    margin-bottom: 65px !important;
  }
  .u-pt65--laptop {
    padding-top: 65px !important;
  }
  .u-pr65--laptop {
    padding-right: 65px !important;
  }
  .u-pl65--laptop {
    padding-left: 65px !important;
  }
  .u-pb65--laptop {
    padding-bottom: 65px !important;
  }
  .u-mt70--laptop {
    margin-top: 70px !important;
  }
  .u-mr70--laptop {
    margin-right: 70px !important;
  }
  .u-ml70--laptop {
    margin-left: 70px !important;
  }
  .u-mb70--laptop {
    margin-bottom: 70px !important;
  }
  .u-pt70--laptop {
    padding-top: 70px !important;
  }
  .u-pr70--laptop {
    padding-right: 70px !important;
  }
  .u-pl70--laptop {
    padding-left: 70px !important;
  }
  .u-pb70--laptop {
    padding-bottom: 70px !important;
  }
  .u-mt75--laptop {
    margin-top: 75px !important;
  }
  .u-mr75--laptop {
    margin-right: 75px !important;
  }
  .u-ml75--laptop {
    margin-left: 75px !important;
  }
  .u-mb75--laptop {
    margin-bottom: 75px !important;
  }
  .u-pt75--laptop {
    padding-top: 75px !important;
  }
  .u-pr75--laptop {
    padding-right: 75px !important;
  }
  .u-pl75--laptop {
    padding-left: 75px !important;
  }
  .u-pb75--laptop {
    padding-bottom: 75px !important;
  }
  .u-mt80--laptop {
    margin-top: 80px !important;
  }
  .u-mr80--laptop {
    margin-right: 80px !important;
  }
  .u-ml80--laptop {
    margin-left: 80px !important;
  }
  .u-mb80--laptop {
    margin-bottom: 80px !important;
  }
  .u-pt80--laptop {
    padding-top: 80px !important;
  }
  .u-pr80--laptop {
    padding-right: 80px !important;
  }
  .u-pl80--laptop {
    padding-left: 80px !important;
  }
  .u-pb80--laptop {
    padding-bottom: 80px !important;
  }
  .u-mt85--laptop {
    margin-top: 85px !important;
  }
  .u-mr85--laptop {
    margin-right: 85px !important;
  }
  .u-ml85--laptop {
    margin-left: 85px !important;
  }
  .u-mb85--laptop {
    margin-bottom: 85px !important;
  }
  .u-pt85--laptop {
    padding-top: 85px !important;
  }
  .u-pr85--laptop {
    padding-right: 85px !important;
  }
  .u-pl85--laptop {
    padding-left: 85px !important;
  }
  .u-pb85--laptop {
    padding-bottom: 85px !important;
  }
  .u-mt90--laptop {
    margin-top: 90px !important;
  }
  .u-mr90--laptop {
    margin-right: 90px !important;
  }
  .u-ml90--laptop {
    margin-left: 90px !important;
  }
  .u-mb90--laptop {
    margin-bottom: 90px !important;
  }
  .u-pt90--laptop {
    padding-top: 90px !important;
  }
  .u-pr90--laptop {
    padding-right: 90px !important;
  }
  .u-pl90--laptop {
    padding-left: 90px !important;
  }
  .u-pb90--laptop {
    padding-bottom: 90px !important;
  }
  .u-mt95--laptop {
    margin-top: 95px !important;
  }
  .u-mr95--laptop {
    margin-right: 95px !important;
  }
  .u-ml95--laptop {
    margin-left: 95px !important;
  }
  .u-mb95--laptop {
    margin-bottom: 95px !important;
  }
  .u-pt95--laptop {
    padding-top: 95px !important;
  }
  .u-pr95--laptop {
    padding-right: 95px !important;
  }
  .u-pl95--laptop {
    padding-left: 95px !important;
  }
  .u-pb95--laptop {
    padding-bottom: 95px !important;
  }
  .u-mt100--laptop {
    margin-top: 100px !important;
  }
  .u-mr100--laptop {
    margin-right: 100px !important;
  }
  .u-ml100--laptop {
    margin-left: 100px !important;
  }
  .u-mb100--laptop {
    margin-bottom: 100px !important;
  }
  .u-pt100--laptop {
    padding-top: 100px !important;
  }
  .u-pr100--laptop {
    padding-right: 100px !important;
  }
  .u-pl100--laptop {
    padding-left: 100px !important;
  }
  .u-pb100--laptop {
    padding-bottom: 100px !important;
  }
}

@media screen and (min-width: 768px) {
  .u-mt0--pc {
    margin-top: 0px !important;
  }
  .u-mr0--pc {
    margin-right: 0px !important;
  }
  .u-ml0--pc {
    margin-left: 0px !important;
  }
  .u-mb0--pc {
    margin-bottom: 0px !important;
  }
  .u-pt0--pc {
    padding-top: 0px !important;
  }
  .u-pr0--pc {
    padding-right: 0px !important;
  }
  .u-pl0--pc {
    padding-left: 0px !important;
  }
  .u-pb0--pc {
    padding-bottom: 0px !important;
  }
  .u-mt5--pc {
    margin-top: 5px !important;
  }
  .u-mr5--pc {
    margin-right: 5px !important;
  }
  .u-ml5--pc {
    margin-left: 5px !important;
  }
  .u-mb5--pc {
    margin-bottom: 5px !important;
  }
  .u-pt5--pc {
    padding-top: 5px !important;
  }
  .u-pr5--pc {
    padding-right: 5px !important;
  }
  .u-pl5--pc {
    padding-left: 5px !important;
  }
  .u-pb5--pc {
    padding-bottom: 5px !important;
  }
  .u-mt10--pc {
    margin-top: 10px !important;
  }
  .u-mr10--pc {
    margin-right: 10px !important;
  }
  .u-ml10--pc {
    margin-left: 10px !important;
  }
  .u-mb10--pc {
    margin-bottom: 10px !important;
  }
  .u-pt10--pc {
    padding-top: 10px !important;
  }
  .u-pr10--pc {
    padding-right: 10px !important;
  }
  .u-pl10--pc {
    padding-left: 10px !important;
  }
  .u-pb10--pc {
    padding-bottom: 10px !important;
  }
  .u-mt15--pc {
    margin-top: 15px !important;
  }
  .u-mr15--pc {
    margin-right: 15px !important;
  }
  .u-ml15--pc {
    margin-left: 15px !important;
  }
  .u-mb15--pc {
    margin-bottom: 15px !important;
  }
  .u-pt15--pc {
    padding-top: 15px !important;
  }
  .u-pr15--pc {
    padding-right: 15px !important;
  }
  .u-pl15--pc {
    padding-left: 15px !important;
  }
  .u-pb15--pc {
    padding-bottom: 15px !important;
  }
  .u-mt20--pc {
    margin-top: 20px !important;
  }
  .u-mr20--pc {
    margin-right: 20px !important;
  }
  .u-ml20--pc {
    margin-left: 20px !important;
  }
  .u-mb20--pc {
    margin-bottom: 20px !important;
  }
  .u-pt20--pc {
    padding-top: 20px !important;
  }
  .u-pr20--pc {
    padding-right: 20px !important;
  }
  .u-pl20--pc {
    padding-left: 20px !important;
  }
  .u-pb20--pc {
    padding-bottom: 20px !important;
  }
  .u-mt25--pc {
    margin-top: 25px !important;
  }
  .u-mr25--pc {
    margin-right: 25px !important;
  }
  .u-ml25--pc {
    margin-left: 25px !important;
  }
  .u-mb25--pc {
    margin-bottom: 25px !important;
  }
  .u-pt25--pc {
    padding-top: 25px !important;
  }
  .u-pr25--pc {
    padding-right: 25px !important;
  }
  .u-pl25--pc {
    padding-left: 25px !important;
  }
  .u-pb25--pc {
    padding-bottom: 25px !important;
  }
  .u-mt30--pc {
    margin-top: 30px !important;
  }
  .u-mr30--pc {
    margin-right: 30px !important;
  }
  .u-ml30--pc {
    margin-left: 30px !important;
  }
  .u-mb30--pc {
    margin-bottom: 30px !important;
  }
  .u-pt30--pc {
    padding-top: 30px !important;
  }
  .u-pr30--pc {
    padding-right: 30px !important;
  }
  .u-pl30--pc {
    padding-left: 30px !important;
  }
  .u-pb30--pc {
    padding-bottom: 30px !important;
  }
  .u-mt35--pc {
    margin-top: 35px !important;
  }
  .u-mr35--pc {
    margin-right: 35px !important;
  }
  .u-ml35--pc {
    margin-left: 35px !important;
  }
  .u-mb35--pc {
    margin-bottom: 35px !important;
  }
  .u-pt35--pc {
    padding-top: 35px !important;
  }
  .u-pr35--pc {
    padding-right: 35px !important;
  }
  .u-pl35--pc {
    padding-left: 35px !important;
  }
  .u-pb35--pc {
    padding-bottom: 35px !important;
  }
  .u-mt40--pc {
    margin-top: 40px !important;
  }
  .u-mr40--pc {
    margin-right: 40px !important;
  }
  .u-ml40--pc {
    margin-left: 40px !important;
  }
  .u-mb40--pc {
    margin-bottom: 40px !important;
  }
  .u-pt40--pc {
    padding-top: 40px !important;
  }
  .u-pr40--pc {
    padding-right: 40px !important;
  }
  .u-pl40--pc {
    padding-left: 40px !important;
  }
  .u-pb40--pc {
    padding-bottom: 40px !important;
  }
  .u-mt45--pc {
    margin-top: 45px !important;
  }
  .u-mr45--pc {
    margin-right: 45px !important;
  }
  .u-ml45--pc {
    margin-left: 45px !important;
  }
  .u-mb45--pc {
    margin-bottom: 45px !important;
  }
  .u-pt45--pc {
    padding-top: 45px !important;
  }
  .u-pr45--pc {
    padding-right: 45px !important;
  }
  .u-pl45--pc {
    padding-left: 45px !important;
  }
  .u-pb45--pc {
    padding-bottom: 45px !important;
  }
  .u-mt50--pc {
    margin-top: 50px !important;
  }
  .u-mr50--pc {
    margin-right: 50px !important;
  }
  .u-ml50--pc {
    margin-left: 50px !important;
  }
  .u-mb50--pc {
    margin-bottom: 50px !important;
  }
  .u-pt50--pc {
    padding-top: 50px !important;
  }
  .u-pr50--pc {
    padding-right: 50px !important;
  }
  .u-pl50--pc {
    padding-left: 50px !important;
  }
  .u-pb50--pc {
    padding-bottom: 50px !important;
  }
  .u-mt55--pc {
    margin-top: 55px !important;
  }
  .u-mr55--pc {
    margin-right: 55px !important;
  }
  .u-ml55--pc {
    margin-left: 55px !important;
  }
  .u-mb55--pc {
    margin-bottom: 55px !important;
  }
  .u-pt55--pc {
    padding-top: 55px !important;
  }
  .u-pr55--pc {
    padding-right: 55px !important;
  }
  .u-pl55--pc {
    padding-left: 55px !important;
  }
  .u-pb55--pc {
    padding-bottom: 55px !important;
  }
  .u-mt60--pc {
    margin-top: 60px !important;
  }
  .u-mr60--pc {
    margin-right: 60px !important;
  }
  .u-ml60--pc {
    margin-left: 60px !important;
  }
  .u-mb60--pc {
    margin-bottom: 60px !important;
  }
  .u-pt60--pc {
    padding-top: 60px !important;
  }
  .u-pr60--pc {
    padding-right: 60px !important;
  }
  .u-pl60--pc {
    padding-left: 60px !important;
  }
  .u-pb60--pc {
    padding-bottom: 60px !important;
  }
  .u-mt65--pc {
    margin-top: 65px !important;
  }
  .u-mr65--pc {
    margin-right: 65px !important;
  }
  .u-ml65--pc {
    margin-left: 65px !important;
  }
  .u-mb65--pc {
    margin-bottom: 65px !important;
  }
  .u-pt65--pc {
    padding-top: 65px !important;
  }
  .u-pr65--pc {
    padding-right: 65px !important;
  }
  .u-pl65--pc {
    padding-left: 65px !important;
  }
  .u-pb65--pc {
    padding-bottom: 65px !important;
  }
  .u-mt70--pc {
    margin-top: 70px !important;
  }
  .u-mr70--pc {
    margin-right: 70px !important;
  }
  .u-ml70--pc {
    margin-left: 70px !important;
  }
  .u-mb70--pc {
    margin-bottom: 70px !important;
  }
  .u-pt70--pc {
    padding-top: 70px !important;
  }
  .u-pr70--pc {
    padding-right: 70px !important;
  }
  .u-pl70--pc {
    padding-left: 70px !important;
  }
  .u-pb70--pc {
    padding-bottom: 70px !important;
  }
  .u-mt75--pc {
    margin-top: 75px !important;
  }
  .u-mr75--pc {
    margin-right: 75px !important;
  }
  .u-ml75--pc {
    margin-left: 75px !important;
  }
  .u-mb75--pc {
    margin-bottom: 75px !important;
  }
  .u-pt75--pc {
    padding-top: 75px !important;
  }
  .u-pr75--pc {
    padding-right: 75px !important;
  }
  .u-pl75--pc {
    padding-left: 75px !important;
  }
  .u-pb75--pc {
    padding-bottom: 75px !important;
  }
  .u-mt80--pc {
    margin-top: 80px !important;
  }
  .u-mr80--pc {
    margin-right: 80px !important;
  }
  .u-ml80--pc {
    margin-left: 80px !important;
  }
  .u-mb80--pc {
    margin-bottom: 80px !important;
  }
  .u-pt80--pc {
    padding-top: 80px !important;
  }
  .u-pr80--pc {
    padding-right: 80px !important;
  }
  .u-pl80--pc {
    padding-left: 80px !important;
  }
  .u-pb80--pc {
    padding-bottom: 80px !important;
  }
  .u-mt85--pc {
    margin-top: 85px !important;
  }
  .u-mr85--pc {
    margin-right: 85px !important;
  }
  .u-ml85--pc {
    margin-left: 85px !important;
  }
  .u-mb85--pc {
    margin-bottom: 85px !important;
  }
  .u-pt85--pc {
    padding-top: 85px !important;
  }
  .u-pr85--pc {
    padding-right: 85px !important;
  }
  .u-pl85--pc {
    padding-left: 85px !important;
  }
  .u-pb85--pc {
    padding-bottom: 85px !important;
  }
  .u-mt90--pc {
    margin-top: 90px !important;
  }
  .u-mr90--pc {
    margin-right: 90px !important;
  }
  .u-ml90--pc {
    margin-left: 90px !important;
  }
  .u-mb90--pc {
    margin-bottom: 90px !important;
  }
  .u-pt90--pc {
    padding-top: 90px !important;
  }
  .u-pr90--pc {
    padding-right: 90px !important;
  }
  .u-pl90--pc {
    padding-left: 90px !important;
  }
  .u-pb90--pc {
    padding-bottom: 90px !important;
  }
  .u-mt95--pc {
    margin-top: 95px !important;
  }
  .u-mr95--pc {
    margin-right: 95px !important;
  }
  .u-ml95--pc {
    margin-left: 95px !important;
  }
  .u-mb95--pc {
    margin-bottom: 95px !important;
  }
  .u-pt95--pc {
    padding-top: 95px !important;
  }
  .u-pr95--pc {
    padding-right: 95px !important;
  }
  .u-pl95--pc {
    padding-left: 95px !important;
  }
  .u-pb95--pc {
    padding-bottom: 95px !important;
  }
  .u-mt100--pc {
    margin-top: 100px !important;
  }
  .u-mr100--pc {
    margin-right: 100px !important;
  }
  .u-ml100--pc {
    margin-left: 100px !important;
  }
  .u-mb100--pc {
    margin-bottom: 100px !important;
  }
  .u-pt100--pc {
    padding-top: 100px !important;
  }
  .u-pr100--pc {
    padding-right: 100px !important;
  }
  .u-pl100--pc {
    padding-left: 100px !important;
  }
  .u-pb100--pc {
    padding-bottom: 100px !important;
  }
}

/* --------------------------
テキスト装飾
-------------------------- */
.u-text-accent {
  color: #014099;
}

.u-text-important {
  color: #333;
}

.u-text-inverse {
  color: #fff;
}

.u-text-sub {
  color: #a2a2a2;
}

.u-text-sansserif {
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
}

.u-text-bold {
  font-weight: 700;
}

.u-text-normal {
  font-weight: 300;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f4f4f4 70%);
}

.u-text-x-small {
  font-size: 0.75rem;
}

.u-text-small {
  font-size: 0.875rem;
}

.u-text-default {
  font-size: 1rem;
  line-height: 1.625;
}

.u-text-large {
  font-size: 1.125rem;
  line-height: 1.8;
}

.u-text-x-large {
  font-size: 1.25rem;
}

/* --------------------------
幅指定
-------------------------- */
.u-w0 {
  width: 0%;
}

.u-w10 {
  width: 10%;
}

.u-w20 {
  width: 20%;
}

.u-w30 {
  width: 30%;
}

.u-w40 {
  width: 40%;
}

.u-w50 {
  width: 50%;
}

.u-w60 {
  width: 60%;
}

.u-w70 {
  width: 70%;
}

.u-w80 {
  width: 80%;
}

.u-w90 {
  width: 90%;
}

.u-w100 {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .u-w0--pc {
    width: 0%;
  }
  .u-w10--pc {
    width: 10%;
  }
  .u-w20--pc {
    width: 20%;
  }
  .u-w30--pc {
    width: 30%;
  }
  .u-w40--pc {
    width: 40%;
  }
  .u-w50--pc {
    width: 50%;
  }
  .u-w60--pc {
    width: 60%;
  }
  .u-w70--pc {
    width: 70%;
  }
  .u-w80--pc {
    width: 80%;
  }
  .u-w90--pc {
    width: 90%;
  }
  .u-w100--pc {
    width: 100%;
  }
}

@media screen and (max-width: 1180px) {
  .u-w0--laptop {
    width: 0%;
  }
  .u-w10--laptop {
    width: 10%;
  }
  .u-w20--laptop {
    width: 20%;
  }
  .u-w30--laptop {
    width: 30%;
  }
  .u-w40--laptop {
    width: 40%;
  }
  .u-w50--laptop {
    width: 50%;
  }
  .u-w60--laptop {
    width: 60%;
  }
  .u-w70--laptop {
    width: 70%;
  }
  .u-w80--laptop {
    width: 80%;
  }
  .u-w90--laptop {
    width: 90%;
  }
  .u-w100--laptop {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .u-w0--tab {
    width: 0%;
  }
  .u-w10--tab {
    width: 10%;
  }
  .u-w20--tab {
    width: 20%;
  }
  .u-w30--tab {
    width: 30%;
  }
  .u-w40--tab {
    width: 40%;
  }
  .u-w50--tab {
    width: 50%;
  }
  .u-w60--tab {
    width: 60%;
  }
  .u-w70--tab {
    width: 70%;
  }
  .u-w80--tab {
    width: 80%;
  }
  .u-w90--tab {
    width: 90%;
  }
  .u-w100--tab {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .u-w0--sp {
    width: 0%;
  }
  .u-w10--sp {
    width: 10%;
  }
  .u-w20--sp {
    width: 20%;
  }
  .u-w30--sp {
    width: 30%;
  }
  .u-w40--sp {
    width: 40%;
  }
  .u-w50--sp {
    width: 50%;
  }
  .u-w60--sp {
    width: 60%;
  }
  .u-w70--sp {
    width: 70%;
  }
  .u-w80--sp {
    width: 80%;
  }
  .u-w90--sp {
    width: 90%;
  }
  .u-w100--sp {
    width: 100%;
  }
}

.u-h100 {
  height: 100%;
}

/* --------------------------
エクストラナビ（Gナビとは別にヘッダー内に表示するナビゲーション）
-------------------------- */
@media screen and (max-width: 767px) {
  .p-exnav {
    display: none;
  }
}

.p-exnav-list {
  margin: 0 -8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.p-exnav-list-item {
  padding: 0 8px;
}

.p-exnav-list-item a {
  display: block;
  text-decoration: none;
}

.p-exnav-list-item--text::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8.7px;
  border-color: transparent transparent transparent #a2a2a2;
  padding-right: 0.5em;
}

.p-exnav-list-item--button {
  width: 150px;
  background: #333;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  position: relative;
}

.p-exnav-drop {
  position: relative;
}

.p-exnav-drop:hover .p-exnav-drop-list {
  display: block;
}

.p-exnav-drop-list {
  display: none;
  position: absolute;
  padding: 0 8px;
  top: 100%;
  left: 0;
  z-index: 100;
}

.p-exnav-drop-list-item {
  border-top: 1px solid #fff;
}

.p-exnav-drop-list-item a {
  width: 150px;
  background: #333;
  color: #fff;
  padding: 10px 0;
  padding: 10px 0;
  text-align: center;
}

/* --------------------------
フッター
-------------------------- */
.p-fnav {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  padding: 10px 0;
  margin-bottom: 50px;
}

.p-fnav-list {
  margin: 0 -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-fnav-list {
    flex-wrap: wrap;
  }
}

.p-fnav-list-item {
  flex-shrink: 1;
  padding: 0 20px;
  border-right: 1px solid #333;
}

.p-fnav-list-item:first-child {
  border-left: 1px solid #333;
}

.p-fnav-list-item a {
  display: block;
  text-decoration: none;
}

.p-fnav-list-item a:hover {
  text-decoration: underline;
}

/* --------------------------
フッター
-------------------------- */
.p-footer {
  margin-top: 30px;
}

@media screen and (max-width: 1024px) {
  .p-footer {
    margin-top: 20px;
  }
}

.p-footer-pagetop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
}

@media screen and (max-width: 1024px) {
  .p-footer-pagetop {
    position: static;
    border-top: 1px solid #ccc;
  }
}

.p-footer-pagetop a {
  display: block;
  width: 40px;
  height: 40px;
  background: #fff;
  border: 2px solid #014099;
  position: relative;
  transition: all 0.3s;
}

.p-footer-pagetop a::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 3px solid #014099;
  border-right: 3px solid #014099;
  transform: rotate(-45deg);
  position: absolute;
  top: calc(50% - 2.5px);
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.3s;
}

.p-footer-pagetop a:hover {
  background: #014099;
}

.p-footer-pagetop a:hover::before {
  border-color: #fff;
}

@media screen and (max-width: 1024px) {
  .p-footer-pagetop a {
    width: 100%;
    height: 30px;
    border: none;
  }
}

.p-footer-banner {
  margin-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .p-footer-banner {
    margin-bottom: 10px;
  }
}

.p-footer-banner-container {
  width: 100%;
  max-width: 1020px;
  padding: 0 10px;
  margin: 0 auto;
}

.p-footer-banner-list {
  margin: -5px;
}

@media screen and (max-width: 1024px) {
  .p-footer-banner-list {
    flex-wrap: wrap;
  }
}

.p-footer-banner-list-item {
  padding: 5px;
  text-align: center;
}

.p-footer-banner-list-item a {
  display: block;
}

.p-footer-cta {
  background: #014099;
}

.p-footer-cta-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.p-footer-cta-list {
  height: 79px;
  display: flex;
  justify-content: space-between;
  background: #fff;
}

@media screen and (max-width: 1024px) {
  .p-footer-cta-list {
    height: auto;
  }
}

.p-footer-cta-list-item {
  width: 50%;
  border-right: 1px solid #6782a6;
}

.p-footer-cta-list-item:first-child {
  border-left: 1px solid #6782a6;
}

.p-footer-cta-list-item:last-child a {
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .p-footer-cta-list-item:last-child a {
    pointer-events: all;
  }
}

.p-footer-cta-list-item a {
  display: block;
  height: 79px;
  font-size: 1.1875rem;
  color: #fff;
  background: #014099;
}

@media screen and (max-width: 1024px) {
  .p-footer-cta-list-item a {
    height: 100%;
    font-size: 0.6875rem;
    padding: 12px 0;
  }
}

.p-footer-cta-list-item a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.p-footer-cta-list-item a.arrow {
  position: relative;
}

.p-footer-cta-list-item a.arrow::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 6px);
  right: 20px;
}

@media screen and (max-width: 1024px) {
  .p-footer-cta-list-item a.arrow::after {
    width: 8px;
    height: 8px;
    top: calc(50% - 4px);
    right: 10px;
  }
}

.p-footer-cta-list-item-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .p-footer-cta-list-item-inner {
    flex-direction: column;
  }
}

.p-footer-cta-list-item-inner .tel {
  font-family: "Cardo", serif;
  font-size: 1.25rem;
  line-height: 1;
  margin-left: 20px;
}

@media screen and (max-width: 1024px) {
  .p-footer-cta-list-item-inner .tel {
    margin-left: 0;
    font-size: 0.625rem;
    margin-top: 0.5em;
  }
}

.p-footer-cta-list-item-inner .accent {
  font-size: 1.875rem;
  line-height: 1;
}

@media screen and (max-width: 1024px) {
  .p-footer-cta-list-item-inner .accent {
    font-size: 0.8125rem;
  }
}

.p-footer-group {
  background: #f4f4f4;
}

.p-footer-nav {
  padding: 24px 0;
}

.p-footer-nav--upper {
  border-bottom: 1px solid #ccc;
}

.p-footer-nav-fnav {
  display: flex;
}

@media screen and (max-width: 767px) {
  .p-footer-nav-fnav {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: -16px;
  }
}

.p-footer-nav-fnav-item {
  flex-grow: 1;
  width: calc(100% / 8);
  border-left: 1px solid #666;
}

@media screen and (max-width: 1024px) {
  .p-footer-nav-fnav-item {
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  .p-footer-nav-fnav-item {
    width: 50%;
    padding: 8px;
    margin-bottom: 16px;
  }
}

.p-footer-nav-fnav-item a {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.5em 1em;
}

@media screen and (max-width: 767px) {
  .p-footer-nav-fnav-item a {
    padding: 0;
  }
}

.p-footer-nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-footer-nav-list {
    flex-wrap: wrap;
    margin-bottom: -0.25em;
  }
}

.p-footer-nav-list-item {
  border-right: 1px solid #ccc;
}

@media screen and (max-width: 767px) {
  .p-footer-nav-list-item {
    padding: 0.25em 0.5em;
    margin-bottom: 0.25em;
  }
}

.p-footer-nav-list-item a {
  display: block;
  font-size: 0.75rem;
  padding: 0 1em;
}

@media screen and (max-width: 767px) {
  .p-footer-nav-list-item a {
    padding: 0;
  }
}

.p-footer-nav-partners {
  margin-top: 16px;
}

.p-footer-nav-partners-list {
  display: flex;
}

.p-footer-nav-partners-list-item {
  margin-bottom: 8px;
}

.p-footer-nav-partners-list-item::after {
  content: ">";
  display: inline-block;
  margin-right: 0.5em;
}

.p-footer-nav-partners-list-item:last-child::after {
  content: none;
}

.p-footer-nav-partners-list-item a {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
}

.p-footer-nav-partners-detail {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.p-footer-nav-partners-detail-item {
  padding: 4px;
}

.p-footer-nav-partners-detail-item::after {
  content: "/";
  display: inline-block;
}

.p-footer-nav-partners-detail-item:last-child::after {
  content: none;
}

.p-footer-nav-partners-detail-item a {
  display: inline-block;
  font-size: 0.75rem;
}

.p-footer-copyright {
  font-family: "Cardo", serif;
  font-size: 0.75rem;
  color: #fff;
  line-height: 1;
  padding: 18px 0;
  text-align: center;
  background-color: #666;
}

@media screen and (max-width: 767px) {
  .p-footer-copyright {
    font-size: 0.5625rem;
    padding: 10px 0;
  }
}

/* --------------------------
Gナビ
-------------------------- */
.p-gnav {
  flex-shrink: 1;
  width: 100%;
  margin: auto 1em 0;
}

@media screen and (max-width: 1024px) {
  .p-gnav {
    display: none;
    margin: 0;
    width: 100%;
    height: 100vh;
    background: rgba(230, 230, 230, 0.8);
    position: absolute;
    top: 46px;
    right: 0;
    z-index: 100;
  }
}

.p-gnav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .p-gnav-list {
    flex-wrap: wrap;
    flex-direction: column;
    background: #e6e6e6;
  }
}

.p-gnav-list-item {
  flex-grow: 1;
  margin: 0 0.5vw;
}

@media screen and (max-width: 1024px) {
  .p-gnav-list-item {
    margin: 0;
    border-bottom: 1px solid #a2a2a2;
    width: 100%;
  }
}

.p-gnav-list-item.new a {
  position: relative;
}

.p-gnav-list-item.new a::before {
  content: "NEW";
  width: 4em;
  display: block;
  font-size: 0.625rem;
  font-family: sans-serif;
  font-weight: 700;
  color: #fff;
  padding: 0.25em 0.5em;
  line-height: 1.2;
  background: #e60012;
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .p-gnav-list-item.new a::before {
    top: 0;
    left: 0;
    right: auto;
  }
}

.p-gnav-list-item.current a {
  color: #014099;
  border-bottom: 2px solid #014099;
}

@media screen and (max-width: 1024px) {
  .p-gnav-list-item.current a {
    border-bottom: none;
  }
}

.p-gnav-list-item a {
  display: block;
  font-size: clamp(14px, 1vw, 16px);
  letter-spacing: 0.1em;
  text-align: center;
  padding-bottom: 9px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}

@media screen and (max-width: 1024px) {
  .p-gnav-list-item a {
    font-size: 0.75rem;
    border-bottom: none;
    padding: 12px 16px;
    position: relative;
  }
  .p-gnav-list-item a[target="_blank"]::after {
    content: "";
    display: block;
    width: 9px;
    height: 7px;
    background: url(../images/common/icon_blank.png) no-repeat left top;
    position: absolute;
    top: calc(50% - 3.5px);
    right: 20px;
    border: none;
    transform: rotate(0);
  }
  .p-gnav-list-item a::after {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-top: 2px solid #a2a2a2;
    border-right: 2px solid #a2a2a2;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 3.5px);
    right: 20px;
  }
}

.p-gnav-list-item a:hover {
  text-decoration: none;
  border-bottom: 2px solid #014099;
}

@media screen and (max-width: 1024px) {
  .p-gnav-list-item a:hover {
    border-bottom: none;
  }
}

@media screen and (min-width: 1240px) {
  .p-gnav .p-gnav-hide {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .p-gnav .p-gnav-hide {
    display: none;
  }
}

/* --------------------------
ヘッダー
-------------------------- */
.p-header {
  width: 100%;
  height: 100px;
  background: rgba(230, 230, 230, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

@media screen and (max-width: 1024px) {
  .p-header {
    height: 50px;
  }
}

.p-header-inner {
  width: 1480px;
  max-width: 100%;
  margin: 0 auto;
  padding: 27px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .p-header-inner {
    width: 100%;
    padding: 0;
    justify-content: flex-start;
  }
  .p-header-inner--sp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 8px;
  }
  .p-header-inner--sp .p-header-logo:first-child {
    margin-right: 16px;
  }
}

.p-header-logo {
  flex-shrink: 0;
}

.p-header-logo a {
  display: block;
  line-height: 1;
}

.p-header-logo a img {
  display: block;
  height: 46px;
}

@media screen and (max-width: 1024px) {
  .p-header-logo a img {
    height: 30px;
  }
}

.p-mv {
  background-image: url(../images/common/p-mv-bg.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #edeee1;
  height: 400px;
}

@media screen and (max-width: 767px) {
  .p-mv {
    height: 250px;
  }
}

.p-mv--white {
  background-color: #fff;
}

.p-mv-contents {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .p-mv-contents {
    padding-top: 46px;
  }
}

.p-mv-large {
  font-size: 3rem;
  color: #fff;
  letter-spacing: 0.2em;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .p-mv-large {
    font-size: 1.75rem;
    margin-bottom: 30px;
  }
}

.p-mv-small {
  font-size: 2rem;
  line-height: 1.5;
  color: #fff;
  letter-spacing: 0.15em;
}

@media screen and (max-width: 767px) {
  .p-mv-small {
    font-size: 1.25rem;
  }
}

.p-access-panel {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  border: 2px solid #014099;
  height: 200px;
}

@media screen and (max-width: 767px) {
  .p-access-panel {
    height: auto;
  }
}

.p-access-panel-contents {
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .p-access-panel-contents {
    padding: 20px 16px;
    height: auto;
  }
}

.p-access-panel-h {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .p-access-panel-h {
    font-size: 1.125rem;
  }
}

.p-access-panel.icon {
  position: relative;
}

.p-access-panel.icon::after {
  content: "";
  display: block;
  width: 200px;
  height: 160px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .p-access-panel.icon::after {
    width: 150px;
    height: 110px;
  }
}

.p-access-panel.icon--car::after {
  background-image: url(../images/common/icon-car.png);
}

.p-access-panel.icon--train::after {
  background-image: url(../images/common/icon-train.png);
}

.p-detail {
  padding-top: 100px;
  background-color: #edeee1;
}

@media screen and (max-width: 1024px) {
  .p-detail {
    padding-top: 46px;
  }
}

.p-detail-panel {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.p-detail-panel-contents {
  padding-top: 0;
}

.p-detail-mv {
  position: relative;
}

.p-detail-mv img {
  display: block;
}

.p-detail-mv-pickup {
  position: absolute;
  bottom: 0;
  right: 20%;
}

@media screen and (max-width: 767px) {
  .p-detail-mv-pickup {
    width: 20%;
    right: 10%;
  }
}

.p-detail-title {
  font-size: 1.125rem;
  line-height: 1.5;
  border-bottom: 1px solid #333;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .p-detail-title {
    font-size: 1rem;
  }
}

.p-detail-table {
  width: 100%;
  font-size: 1rem;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .p-detail-table {
    font-size: 0.875rem;
    line-height: 1.4;
  }
}

.p-detail-table tbody th {
  width: 30%;
  font-weight: 300;
  color: #014099;
  padding: 4px 0;
}

.p-detail-table tbody td {
  width: 70%;
  padding: 4px 0;
}

.p-detail-map {
  width: 100%;
  height: 450px;
}

.p-image {
  position: relative;
}

.p-image img {
  display: block;
}

.p-image-caption {
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1;
  text-align: right;
  color: #fff;
  text-shadow: 0px 0px 8px black;
  position: absolute;
}

.p-image-caption--top {
  top: 8px;
}

.p-image-caption--bottom {
  bottom: 8px;
}

.p-image-caption--left {
  left: 8px;
}

.p-image-caption--right {
  right: 8px;
}

.p-modal {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.p-modal-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.p-modal-body {
  width: 1152px;
  height: 480px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .p-modal-body {
    width: 80%;
    height: 75%;
    margin-bottom: 25%;
  }
}

.p-modal-contents {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: scroll;
}

.p-modal-close {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 2.5rem;
  line-height: 1;
  text-align: center;
  color: #fff;
  background: #006390;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

.p-modal-close:hover {
  text-decoration: none;
}

.p-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.p-video iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.p-video iframe ~ p {
  display: none;
}

.top-keyvisual {
  position: relative;
}

@media screen and (max-width: 1024px) {
  .top-keyvisual {
    margin-top: 50px;
  }
}

.top-keyvisual-wrapper {
  max-width: 1920px;
  margin: 0 auto;
}

.top-search {
  width: 60%;
  height: 140px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #014099;
  border-radius: 0 100px 100px 0;
}

@media screen and (max-width: 1024px) {
  .top-search {
    position: static;
    width: 100%;
    height: auto;
    border-radius: 0;
  }
}

.top-search-contents {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 15px 2vw;
}

@media screen and (max-width: 1024px) {
  .top-search-contents {
    padding: 15px;
  }
}

@media screen and (max-width: 767px) {
  .top-search-contents {
    flex-wrap: wrap;
  }
}

.top-search-item {
  flex-grow: 1;
  width: 35%;
  margin-right: 20px;
}

@media screen and (max-width: 1024px) {
  .top-search-item {
    width: 100%;
    margin-right: 0;
  }
}

.top-search-title {
  color: #fff;
  padding-left: 15%;
  font-size: 1.125rem;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .top-search-title {
    padding-left: 0;
    text-align: center;
    margin-bottom: 10px;
  }
}

.top-search-title.large {
  font-size: 1.75rem;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .top-search-title.large {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
}

.top-search-nav {
  flex-grow: 1;
  width: 65%;
}

.top-search-nav-list {
  display: flex;
}

.top-search-nav-list-item a {
  display: block;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
}

.top-search-nav-list-item a:hover {
  text-decoration: none;
}

.top-search-nav-list-item a img {
  width: 75%;
}

@media screen and (max-width: 1024px) {
  .top-search-nav-list-item a img {
    width: 50%;
  }
}

.top-h2 {
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
  padding-left: 5%;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
  margin-bottom: 20px;
}

.top-h2 span {
  font-size: 1rem;
  padding-left: 1.5em;
}

.top-cta {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .top-cta {
    flex-wrap: wrap;
  }
}

.top-cta-item {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .top-cta-item {
    width: 100%;
  }
}

.top-cta-item--left {
  padding-right: 15px;
}

.top-cta-item--left .top-cta-contents {
  padding-left: 100px;
  border-radius: 0 50px 50px 0;
  background-image: url(../images/top/mflp-ichikawashiohama.png);
  background-position: center center;
}

@media screen and (max-width: 767px) {
  .top-cta-item--left .top-cta-contents {
    padding-left: 15px;
    height: auto;
  }
}

.top-cta-item--right {
  padding-left: 15px;
}

.top-cta-item--right .top-cta-contents {
  background-color: #003d8b;
  padding-right: 100px;
  border-radius: 50px 0 0 50px;
}

@media screen and (max-width: 767px) {
  .top-cta-item--right .top-cta-contents {
    padding-right: 15px;
  }
}

.top-cta-contents {
  color: #fff;
  background: #a2a2a2;
  height: 350px;
  padding: 30px 0 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .top-cta-contents {
    height: auto;
    padding: 20px 15px;
  }
}

.top-cta-contents .top-h2 {
  border-color: #fff;
}

.top-cta-contents-inner {
  padding-left: 5%;
  padding-right: 100px;
}

@media screen and (max-width: 767px) {
  .top-cta-contents-inner {
    padding-right: 15px;
  }
}

.top-cta-tel {
  display: inline-block;
  font-size: 3rem;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 20px;
  pointer-events: none;
}

.top-cta-tel:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .top-cta-tel {
    font-size: 2rem;
    pointer-events: painted;
  }
}

.top-cta-button {
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #fff;
  padding: 8px 32px;
  background: #000f4c;
  border-radius: 30px;
  box-shadow: 0px 0 3px rgba(0, 0, 0, 0.5);
}

.top-cta-button:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .top-cta-button {
    font-size: 1.25rem;
  }
}

.top-pickup-card {
  display: block;
  color: #333;
  transition: all 0.3s;
}

.top-pickup-card img {
  display: block;
}

.top-pickup-card:hover {
  color: #333;
  text-decoration: none;
}

.top-pickup-card-contents {
  padding: 15px 25px;
  background: #fff;
  position: relative;
}

.top-pickup-card-inner {
  position: relative;
}

.top-pickup-card-title {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

.top-pickup-eyecatch {
  position: absolute;
  bottom: -20%;
  right: 15px;
}

@media screen and (max-width: 1024px) {
  .top-pickup-eyecatch {
    width: 30%;
    bottom: -10%;
  }
}

@media screen and (max-width: 767px) {
  .top-pickup-eyecatch {
    width: 20%;
    bottom: 0;
  }
}

.top-pickup-icon {
  width: 20%;
  position: absolute;
  top: 50%;
  right: 15px;
  z-index: 2;
}

.top-panel {
  display: block;
  height: 400px;
  background-color: #333;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;
}

.top-panel::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(35, 24, 21, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .top-panel {
    height: 200px;
  }
}

.top-panel.statement {
  background-image: url(../images/top/statement.jpg);
}

.top-panel.logistics {
  background-image: url(../images/top/logistics.jpg);
}

.top-panel.labo {
  background-image: url(../images/top/labo.jpg);
}

.top-panel.solution {
  background-image: url(../images/top/labo.jpg);
}

.top-panel.decarbonization {
  background-image: url(../images/top/decarbonization.jpg);
}

.top-panel.contact {
  background-image: url(../images/top/contact.jpg);
}

.top-panel.quality {
  background-image: url(../images/top/quality.jpg);
}

.top-panel:hover {
  text-decoration: none;
}

.top-panel-contents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 2;
}

.top-panel-contents.statement {
  background-image: url(../images/top/statement-bg.png);
}

.top-panel-contents.logistics {
  background-image: url(../images/top/logistics-bg.png);
}

.top-panel-contents.labo {
  background-image: url(../images/top/labo-bg.png);
}

.top-panel-contents.solution {
  background-image: url(../images/top/labo-bg.png);
}

.top-panel-contents.decarbonization {
  background-image: url(../images/top/decarbonization-bg.png);
}

.top-panel-contents.contact {
  background-image: url(../images/top/contact-bg.png);
}

.top-panel-contents.quality {
  background-image: url(../images/top/quality-bg.png);
}

.top-panel-text--ja {
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.top-panel-text--en {
  font-size: 1.25rem;
  letter-spacing: 0.1em;
}

.top-news-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top-news-card:hover {
  text-decoration: none;
}

.top-news-card-contents {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #efefef;
}

.top-news-card-wrapper {
  flex: 1;
  height: 100%;
  min-height: 0%;
  margin-bottom: 1em;
}

.top-news-card-title {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .top-news-card-title {
    flex-wrap: wrap;
  }
}

.top-news-card-view {
  margin-top: auto;
}

.top-news-label {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #fff;
  padding: 4px 8px;
  background: #014099;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .top-news-label {
    width: 100%;
    margin-top: 0.5em;
  }
}

.top-news-arrow {
  width: 35px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  z-index: 10;
  cursor: pointer;
}

.top-news-arrow--prev {
  left: -35px;
}

@media screen and (max-width: 1024px) {
  .top-news-arrow--prev {
    left: 0;
  }
}

.top-news-arrow--next {
  right: -35px;
}

@media screen and (max-width: 1024px) {
  .top-news-arrow--next {
    right: 0;
  }
}

.top-recruiting-item a {
  display: block;
  color: #333;
  background: #fff;
  position: relative;
  transition: all 0.5s;
}

.top-recruiting-item a:hover {
  text-decoration: none;
  color: #fff;
}

.top-recruiting-item a:hover .top-recruiting-thumb img {
  transform: scale(1.2);
}

.top-recruiting-item a:hover .top-recruiting-contents::before {
  transform: scaleX(1);
}

.top-recruiting-item a:hover .top-recruiting-inner::after {
  background-image: url(../images/top/movie-play--over.png);
}

.top-recruiting-thumb {
  overflow: hidden;
}

.top-recruiting-thumb img {
  transition: all 0.5s;
}

.top-recruiting-contents {
  padding: 16px;
  position: relative;
}

.top-recruiting-contents::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #014099;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.5s;
}

.top-recruiting-inner {
  position: relative;
}

.top-recruiting-inner::after {
  content: "";
  display: block;
  width: 4em;
  height: 100%;
  background-image: url(../images/top/movie-play.png);
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;
}

.top-recruiting-name {
  font-size: 1.25rem;
  line-height: 1.5;
}

.labo-keyvisual {
  height: 1400px;
  background-image: url(../images/labo/mv.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

@media screen and (max-width: 767px) {
  .labo-keyvisual {
    height: 600px;
  }
}

.labo-keyvisual::after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  /* (画像の高さ / 画像の横幅) × 100 */
  padding-top: calc((650 / 1920) * 100%);
  background-image: url(../images/labo/section-bg.png);
  background-position: bottom 100% center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 0;
}

.labo-keyvisual-mv {
  background-color: transparent;
}

.labo-keyvisual-catchcopy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

@media screen and (max-width: 767px) {
  .labo-keyvisual-catchcopy {
    padding: 250px 15px 100px;
    height: 600px;
  }
}

.labo-section {
  margin-top: calc(((650 / 1920) * 100%) * -1);
  padding-top: 150px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .labo-section {
    padding-top: 100px;
  }
}

.labo-section::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #edeee1;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.labo-title {
  font-size: 2.25rem;
  text-align: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .labo-title {
    font-size: 1.875rem;
  }
}

.labo-title span {
  font-family: trajan-pro-3, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem;
  line-height: 1;
  color: #014099;
}

.labo-point {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  padding: 40px;
}

@media screen and (max-width: 767px) {
  .labo-point {
    padding: 16px;
  }
}

.labo-point-list {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .labo-point-list {
    max-width: 100%;
  }
}

.labo-point-list-item {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.labo-point-list-item:last-child {
  margin-bottom: 0;
}

.labo-point-number {
  width: 64px;
  flex-shrink: 0;
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  .labo-point-number {
    width: 36px;
    margin-right: 10px;
  }
}

.labo-area-title {
  font-size: 1.75rem;
  line-height: 1.5;
  color: #014099;
  letter-spacing: 0.1em;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .labo-area-title {
    font-size: 1.375rem;
  }
}

.labo-area-contents {
  margin-left: 10%;
}

@media screen and (max-width: 767px) {
  .labo-area-contents {
    margin: 10px 10% 0;
  }
}

.labo-bg {
  background: #e6e6e6;
}

.labo-access-panel {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: #fff;
  background: #204099;
  border: 1.5px solid #333;
  height: 200px;
}

@media screen and (max-width: 767px) {
  .labo-access-panel {
    height: auto;
  }
}

.labo-access-panel-contents {
  padding: 40px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .labo-access-panel-contents {
    padding: 20px 16px;
    height: auto;
  }
}

.labo-access-panel-h {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .labo-access-panel-h {
    font-size: 1.125rem;
  }
}

.labo-access-panel.icon {
  position: relative;
}

.labo-access-panel.icon::after {
  content: "";
  display: block;
  width: 200px;
  height: 160px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .labo-access-panel.icon::after {
    width: 150px;
    height: 110px;
  }
}

.labo-access-panel.icon--car::after {
  background-image: url(../images/labo/icon-car.png);
}

.labo-access-panel.icon--train::after {
  background-image: url(../images/labo/icon-train.png);
}

.logistics-nav {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .logistics-nav {
    margin-bottom: 25px;
  }
}

.logistics-nav-list {
  display: flex;
  justify-content: space-between;
  margin: -10px;
}

@media screen and (max-width: 767px) {
  .logistics-nav-list {
    flex-wrap: wrap;
    margin: -8px;
  }
}

.logistics-nav-list > li {
  width: 100%;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .logistics-nav-list > li {
    width: 50%;
    padding: 8px;
  }
}

.logistics-nav-list > li a {
  display: block;
  color: #fff;
  letter-spacing: 0.02em;
  padding: 10px;
  background: #999;
  text-align: center;
  text-decoration: none;
}

.logistics-tab-nav {
  display: flex;
  justify-content: center;
}

.logistics-tab-nav-item {
  width: 300px;
  margin-right: 50px;
}

.logistics-tab-nav-item.current a {
  color: #014099;
  background-color: #fff;
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  .logistics-tab-nav-item {
    width: calc(100% / 3);
    margin-right: 0;
  }
}

.logistics-tab-nav-item:last-child {
  margin-right: 0;
}

.logistics-tab-nav-item a {
  display: block;
  font-size: 1.25rem;
  line-height: 1.2;
  padding: 20px;
  background-color: #ccc;
  text-align: center;
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .logistics-tab-nav-item a {
    font-size: 0.875rem;
    padding: 12px 8px;
  }
}

.logistics-tab-nav-item a[aria-selected="true"] {
  color: #014099;
  background-color: #fff;
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1);
}

.logistics-tab-contents {
  padding: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 767px) {
  .logistics-tab-contents {
    padding: 25px;
  }
}

.logistics-tab-contents-item {
  display: none;
}

.logistics-tab-contents-item[aria-hidden="false"] {
  display: block;
}

.logistics-section {
  margin-bottom: 50px;
}

.logistics-section-title {
  font-size: 1.125rem;
  line-height: 1.2;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #014099;
  margin-bottom: 20px;
}

.logistics-link {
  display: block;
}

.logistics-link-image {
  position: relative;
  margin-bottom: 10px;
}

.logistics-link-image img {
  display: block;
}

.logistics-link-image-pickup {
  width: 70px;
  position: absolute;
  top: -16px;
  right: -10px;
}

@media screen and (max-width: 767px) {
  .logistics-link-image-pickup {
    width: 45px;
  }
}

.logistics-link-name {
  text-align: left;
  word-break: break-all;
}

.logistics-link-name::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7.5px 0 7.5px 10px;
  border-color: transparent transparent transparent #014099;
  margin-right: 0.5em;
}

.logistics-map {
  width: 100%;
  height: 500px;
}

.logistics-map-list {
  border-top: 1px solid #a2a2a2;
}

.logistics-map-list > li {
  padding: 0.5em 0;
  line-height: 1.2;
  border-bottom: 1px solid #a2a2a2;
  position: relative;
  counter-increment: number;
}

.logistics-map-list > li::before {
  content: counter(number, decimal-leading-zero);
  margin-right: 0.5em;
}

.logistics-map-list > li::after {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  background-image: url(../images/logistics/icon_access.png);
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 1em;
  position: absolute;
  top: 0.5em;
  right: 0;
}

.logistics-map-list > li a span {
  margin-right: 0.5em;
}

.logistics-map-list > li.undone::after {
  background-image: url(../images/logistics/icon_access--undone.png);
}

.logistics-map-icon {
  position: relative;
  padding-left: 1.5em;
}

.logistics-map-icon::before {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.logistics-map-icon.blue::before {
  background-image: url(../images/logistics/icon_access.png);
}

.logistics-map-icon.red::before {
  background-image: url(../images/logistics/icon_access--undone.png);
}

.logistics-popup {
  width: 320px;
  background: #014099;
}

@media screen and (max-width: 767px) {
  .logistics-popup {
    width: 100%;
  }
}

.logistics-popup-image {
  width: 320px;
}

@media screen and (max-width: 767px) {
  .logistics-popup-image {
    width: 100%;
  }
}

.logistics-popup-image img {
  display: block;
  width: 320px;
  height: auto;
}

@media screen and (max-width: 767px) {
  .logistics-popup-image img {
    width: 100%;
  }
}

.logistics-popup-contents {
  padding: 16px 8px;
  color: #fff;
  text-align: center;
}

.logistics-popup-contents h3 {
  font-size: 1.125rem;
  letter-spacing: 0;
  margin-bottom: 0;
}

.logistics-popup-button {
  display: inline-block;
  font-size: 0.875rem;
  color: #014099;
  background: #fff;
  border-radius: 20px;
  padding: 0 20px;
  line-height: 1.5;
  margin-top: 0.5em;
}

.logistics-popup-button:hover {
  text-decoration: none;
}

.gm-style .gm-style-iw-c {
  background: #014099;
  border-radius: 0;
  padding: 0;
}

.gm-style .gm-style-iw-c .gm-style-iw-chr {
  display: none;
}

.gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg, #014099 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}

.pickup-keyvisual {
  position: relative;
}

.pickup-keyvisual img {
  display: block;
}

.pickup-keyvisual-eyecatch {
  position: absolute;
  top: calc(100% - 80px);
  left: 10%;
}

@media screen and (max-width: 1024px) {
  .pickup-keyvisual-eyecatch {
    left: 10%;
  }
  .pickup-keyvisual-eyecatch img {
    width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .pickup-keyvisual-eyecatch img {
    width: 100px;
  }
}

.pickup-keyvisual-pickup {
  position: absolute;
  bottom: -35px;
  right: 10%;
}

@media screen and (max-width: 767px) {
  .pickup-keyvisual-pickup {
    width: 15%;
    right: 10%;
  }
}

.pickup-name {
  font-family: trajan-pro-3, serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.pickup-name--large {
  font-size: 2.5rem;
  line-height: 1.5;
  letter-spacing: 0.2em;
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .pickup-name--large {
    line-height: 1.25;
  }
}

.pickup-name--small {
  font-size: 1rem;
  letter-spacing: 0.15em;
  margin-bottom: 10px;
}

@media screen and (min-width: 1720px) {
  .pickup-name-br {
    display: none;
  }
}

.pickup-access {
  margin-bottom: 10px;
}

.pickup-access-list {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.pickup-access-list > li {
  display: inline-block;
  padding: 4px;
  line-height: 1.5;
}

.pickup-access-list > li::before {
  content: "■";
  display: inline-block;
  padding-right: 0.2em;
}

.pickup-outline {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .pickup-outline {
    margin-bottom: 20px;
  }
}

.pickup-outline-title {
  font-size: 1.125rem;
  line-height: 1.2;
  margin-bottom: 10px;
}

.pickup-outline-wrap {
  display: flex;
}

@media screen and (max-width: 767px) {
  .pickup-outline-wrap {
    flex-wrap: wrap;
  }
}

.pickup-h4 {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: #527586;
  margin-bottom: 16px;
}

.quality-keyvisual {
  position: relative;
  padding-top: calc(((1400 / 1920) * 100%) + 5.5vw);
}

@media screen and (max-width: 1024px) {
  .quality-keyvisual {
    padding-top: calc(((750 / 750) * 100%) + 400px);
  }
}

@media screen and (max-width: 1024px) {
  .quality-keyvisual {
    padding-top: calc(((750 / 750) * 100%) + 150px);
  }
}

.quality-keyvisual::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(../images/quality/hero.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .quality-keyvisual::before {
    background-image: url(../images/quality/hero--sp.jpg);
    top: auto;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .quality-keyvisual::before {
    background-position: center bottom;
    background-size: 100%;
  }
}

.quality-keyvisual-mv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
}

.quality-keyvisual-section-container {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .quality-keyvisual-section-container {
    padding-bottom: 50px;
  }
}

.quality-bg {
  padding-top: 1px;
  position: relative;
  background: #edeee1;
}

.quality-bg::before {
  content: "";
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  height: 15.65vw;
  width: 100%;
  position: absolute;
  bottom: calc(100% - 2px);
  left: 0;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .quality-bg::before {
    height: 16vw;
  }
}

.quality-bg::before {
  content: "";
  background-image: url("../images/quality/curve.svg");
}

.quality-bg--white {
  background: #fff;
}

.quality-bg--white::before {
  content: "";
  background-image: url("../images/quality/curve--white.svg");
}

.quality-section {
  margin-top: -8vw;
  padding-bottom: 20vw;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .quality-section {
    margin-top: -50px;
    padding-bottom: 150px;
  }
}

.quality-eyecatch {
  position: relative;
  padding-top: 10em;
}

@media screen and (max-width: 1024px) {
  .quality-eyecatch {
    padding-top: 0;
  }
}

.quality-eyecatch-text {
  width: 350px;
  height: 300px;
  color: #fff;
  background-image: url(../images/quality/eyecatch.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: absolute;
  top: 0;
}

@media screen and (max-width: 1024px) {
  .quality-eyecatch-text {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: static;
    background-image: none;
    background-color: #014099;
  }
}

.quality-eyecatch-text--right {
  right: 0;
}

.quality-eyecatch-text--left {
  left: 0;
}

.quality-eyecatch-text-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
}

@media screen and (max-width: 1024px) {
  .quality-eyecatch-text-inner {
    height: auto;
    padding: 2em;
  }
}

.notice-list-item {
  margin-bottom: 24px;
}

.notice-list-item p {
  padding: 0 20px;
}

.notice-list-item:last-child {
  margin-bottom: 0;
}

.notice-link {
  padding: 0 20px;
  margin-bottom: 1em;
}

.notice-link--row {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  .notice-link--row {
    flex-wrap: wrap;
  }
}

.notice-link--row .notice-link-item {
  margin-bottom: 0;
  margin: 0 20px;
}

@media screen and (max-width: 767px) {
  .notice-link--row .notice-link-item {
    margin: 0;
    width: 100%;
    padding: 10px 0;
  }
}

.notice-link--row .notice-link-item::before {
  content: "▶︎";
  display: inline-block;
  font-size: 80%;
  color: #014099;
}

.notice-link--row .notice-link-item.current a {
  font-weight: 700;
}

.notice-link-item {
  font-size: 0.875rem;
  margin-bottom: 8px;
}

.notice-link-item:last-child {
  margin-bottom: 0;
}

.notice-title {
  font-size: 1rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #014099;
  border-left: 4px solid #014099;
  padding: 8px 8px 8px 16px;
  background: #f4f4f4;
  margin-bottom: 8px;
}

.notice-order {
  padding: 0 20px;
}

.notice-order-list {
  list-style: none;
  counter-reset: number 0;
}

.notice-order-list li {
  margin-bottom: 20px;
}

.notice-order-list li:last-child {
  margin-bottom: 0;
}

.notice-order-list li h4 {
  font-size: 1rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
  border-left: 4px solid #a2a2a2;
  padding: 8px 8px 8px 16px;
  margin-bottom: 8px;
}

.notice-order-list li h4::before {
  counter-increment: number 1;
  content: counter(number) " ";
  display: inline-block;
  padding-right: 0.5em;
}

.andlogi-h2 {
  font-family: "nobel", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 1.5;
  color: #006390;
  border-bottom: 2px solid #006390;
  margin-bottom: 32px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .andlogi-h2 {
    font-size: 2rem;
    margin-bottom: 24px;
  }
}

.andlogi-h3 {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 16px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .andlogi-h3 {
    font-size: 1.25rem;
  }
}

.andlogi-h4 {
  font-feature-settings: "palt";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #006390;
  margin-bottom: 8px;
}

.andlogi-bg {
  background: #eef1f9;
}

.andlogi-button {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.05em;
  color: #fff;
  padding: 8px 16px;
  border: 1px solid #fff;
  transition: all 0.3s;
}

.andlogi-button:hover {
  text-decoration: none;
  opacity: 0.7;
}

.andlogi-button.mfls {
  background: #006390;
}

.andlogi-button--case {
  padding: 16px 32px;
  border: none;
  position: relative;
  z-index: 2;
  background: #006390;
}

@media screen and (max-width: 767px) {
  .andlogi-button--sp {
    border: none;
  }
}

.andlogi-anchor {
  margin-top: 40px;
}

.andlogi-anchor-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1em;
}

@media screen and (max-width: 1024px) {
  .andlogi-anchor-list {
    flex-wrap: wrap;
    margin: 1em 0;
  }
}

.andlogi-anchor-list > li {
  padding: 0 1em;
  border-right: 1px solid #006390;
}

@media screen and (max-width: 1024px) {
  .andlogi-anchor-list > li {
    padding: 1em 0;
    border-right: none;
    border-bottom: 1px solid #006390;
    width: 100%;
    text-align: center;
  }
}

.andlogi-anchor-list > li:last-child {
  border-right: none;
}

@media screen and (max-width: 1024px) {
  .andlogi-anchor-list > li:last-child {
    border-bottom: none;
  }
}

.andlogi-anchor-list > li a {
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #006390;
}

.andlogi-mfls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .andlogi-mfls {
    display: none;
  }
}

.andlogi-modal {
  padding: 24px 40px 40px 40px;
}

.andlogi-modal-logo {
  max-height: 70%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .andlogi-modal-logo {
    max-height: auto;
  }
}

@media screen and (max-width: 767px) {
  .andlogi-modal {
    padding: 24px;
  }
}

.andlogi-modal-bg {
  background: radial-gradient(#fff, #e5e5e5);
}

.andlogi-modal-image {
  height: 100%;
}

@media screen and (max-width: 767px) {
  .andlogi-modal-image {
    height: auto;
  }
}

.andlogi-modal-image img {
  display: block;
  object-fit: cover;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .andlogi-modal-image img {
    object-fit: none;
    height: auto;
  }
}

.andlogi-modal-h {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  color: #006390;
  margin-bottom: 8px;
}

.andlogi-modal-h.base {
  color: #5ea829;
}

.andlogi-modal-h.efficient {
  color: #dc4833;
}

.andlogi-modal-h.delivery {
  color: #d85803;
}

.andlogi-modal-h.sustainability {
  color: #008771;
}

.andlogi-modal-h.automation {
  color: #78519e;
}

.andlogi-modal-h.human {
  color: #1f62bf;
}

.andlogi-modal-sub {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25;
  color: #006390;
  margin-bottom: 8px;
}

.andlogi-modal-sub.base {
  color: #5ea829;
}

.andlogi-modal-sub.efficient {
  color: #dc4833;
}

.andlogi-modal-sub.delivery {
  color: #d85803;
}

.andlogi-modal-sub.sustainability {
  color: #008771;
}

.andlogi-modal-sub.automation {
  color: #78519e;
}

.andlogi-modal-sub.human {
  color: #1f62bf;
}

.andlogi-contents {
  display: block;
  height: 200px;
  filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.5));
  text-align: center;
  transition: all 0.3s;
}

@media screen and (max-width: 767px) {
  .andlogi-contents {
    height: 120px;
  }
}

.andlogi-contents:hover {
  text-decoration: none;
  opacity: 0.7;
}

.andlogi-contents-item:nth-child(-n + 3) {
  margin-bottom: 40px;
}

@media screen and (max-width: 1024px) {
  .andlogi-contents-item:nth-child(-n + 4) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .andlogi-contents-item:nth-child(-n + 6) {
    margin-bottom: 40px;
  }
}

.andlogi-contents-wrapper {
  height: 100%;
  background: #fff;
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 0% 50%);
  position: relative;
}

.andlogi-contents-clip {
  height: 100%;
  background: radial-gradient(#fff, #e5e5e5);
  clip-path: polygon(calc(15% + 4px) 0%, 85% 0%, 100% 50%, 85% 100%, calc(15% + 4px) 100%, 4px 50%);
}

.andlogi-contents-clip-image {
  height: 100%;
  overflow: hidden;
}

.andlogi-contents-clip-image img {
  height: 100%;
}

.andlogi-contents-clip--initiative {
  padding-top: 8px;
}

.andlogi-contents-clip--solution {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  clip-path: polygon(calc(15% + 4px) 8px, 85% 8px, calc(100% - 8px) 50%, 85% calc(100% - 8px), calc(15% + 4px) calc(100% - 8px), 8px 50%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.andlogi-contents-clip--solution::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.75;
  z-index: -1;
}

.andlogi-contents-clip--solution.base {
  background-image: url(../images/andlogi/support-base-modal.jpg);
}

.andlogi-contents-clip--solution.base::before {
  background: #5ea829;
}

.andlogi-contents-clip--solution.efficient {
  background-image: url(../images/andlogi/support-efficient-modal.jpg);
}

.andlogi-contents-clip--solution.efficient::before {
  background: #dc4833;
}

.andlogi-contents-clip--solution.delivery {
  background-image: url(../images/andlogi/support-delivery-modal.jpg);
}

.andlogi-contents-clip--solution.delivery::before {
  background: #d85803;
}

.andlogi-contents-clip--solution.sustainability {
  background-image: url(../images/andlogi/support-sustainability-modal.jpg);
}

.andlogi-contents-clip--solution.sustainability::before {
  background: #008771;
}

.andlogi-contents-clip--solution.automation {
  background-image: url(../images/andlogi/support-automation-modal.jpg);
}

.andlogi-contents-clip--solution.automation::before {
  background: #78519e;
}

.andlogi-contents-clip--solution.human {
  background-image: url(../images/andlogi/support-human-modal.jpg);
}

.andlogi-contents-clip--solution.human::before {
  background: #1f62bf;
}

.andlogi-parent {
  background-image: url(../images/andlogi/solution-eyecatch.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}

@media screen and (max-width: 1024px) {
  .andlogi-parent {
    background-image: none;
  }
}

.andlogi-parent-item1 {
  margin-top: 80px;
}

@media screen and (max-width: 1024px) {
  .andlogi-parent-item1 {
    margin-top: 0;
  }
}

.andlogi-parent-item3 {
  margin-top: 80px;
}

@media screen and (max-width: 1024px) {
  .andlogi-parent-item3 {
    margin-top: 0;
  }
}

.andlogi-parent-item5 {
  margin-top: 80px;
}

@media screen and (max-width: 1024px) {
  .andlogi-parent-item5 {
    margin-top: 0;
  }
}

.andlogi-parent a {
  display: block;
  text-align: center;
  transition: all 0.3s;
  text-decoration: none;
}

.andlogi-parent a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.andlogi-support-h {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 700;
  color: #006390;
  margin-bottom: 8px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .andlogi-support-h {
    font-size: 1rem;
  }
}

.andlogi-support-list {
  margin-top: 24px;
}

.andlogi-support-list > li {
  line-height: 1.4;
  padding-left: 1.5em;
  margin-bottom: 0.5em;
  position: relative;
}

.andlogi-support-list > li::before {
  content: "●";
  position: absolute;
  top: 0;
  left: 0;
}

.andlogi-support-list > li:last-child {
  margin-bottom: 0;
}

.andlogi-support-list > li.base::before {
  color: #5ea829;
}

.andlogi-support-list > li.efficient::before {
  color: #dc4833;
}

.andlogi-support-list > li.delivery::before {
  color: #d85803;
}

.andlogi-support-list > li.sustainability::before {
  color: #008771;
}

.andlogi-support-list > li.automation::before {
  color: #78519e;
}

.andlogi-support-list > li.human::before {
  color: #1f62bf;
}

.andlogi-archive {
  display: block;
  position: relative;
}

.andlogi-archive:hover {
  text-decoration: none;
}

.andlogi-archive::before {
  content: "";
  display: block;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to top, #fff, #333);
  opacity: 0.6;
  mix-blend-mode: multiply;
}

@media screen and (max-width: 767px) {
  .andlogi-archive::before {
    content: none;
  }
}

@media screen and (max-width: 767px) {
  .andlogi-archive {
    margin-bottom: 16px;
  }
}

.andlogi-archive-contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .andlogi-archive-contents {
    display: block;
    background: #006390;
    position: static;
    padding: 16px 0;
  }
  .andlogi-archive-contents.sp {
    display: none;
  }
}

.andlogi-archive-title {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .andlogi-archive-title {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }
}

.andlogi-archive-text {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.1em;
  text-align: center;
}

.andlogi-archive-case {
  box-shadow: 4px 4px 4px rgba(51, 51, 51, 0.5);
}

.andlogi-archive-case-header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 16px 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.andlogi-archive-case-header.a {
  background-image: url(../images/andlogi/case01-header.jpg);
}

.andlogi-archive-case-header.b {
  background-image: url(../images/andlogi/case02-header.jpg);
}

.andlogi-archive-case-header.c {
  background-image: url(../images/andlogi/case03-header.jpg);
}

.andlogi-archive-case-header.d {
  background-image: url(../images/andlogi/case04-header.jpg);
}

.andlogi-archive-case-header .number {
  font-family: "nobel", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.5rem;
  margin-right: 1em;
}

@media screen and (max-width: 767px) {
  .andlogi-archive-case-header .number {
    font-size: 2rem;
    margin-right: 0.5em;
  }
}

.andlogi-archive-case-header .headline {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .andlogi-archive-case-header .headline {
    font-size: 1.25rem;
  }
}

.andlogi-archive-case-header .detail {
  font-size: 1rem;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .andlogi-archive-case-header .detail {
    font-size: 0.875rem;
  }
}

.andlogi-archive-case-contents {
  padding: 32px 40px;
  background: #fff;
}

@media screen and (max-width: 767px) {
  .andlogi-archive-case-contents {
    padding: 32px 16px;
  }
}

.andlogi-archive-case-wrapper {
  display: flex;
  align-items: center;
  /* 線を中央揃えにしている */
  justify-content: flex-end;
}

.andlogi-archive-case-wrapper::before {
  content: "";
  height: 1px;
  flex-grow: 1;
  background: #045f89;
}

.andlogi-archive-case-link {
  text-decoration: none;
}

.andlogi-archive-case-link:hover {
  text-decoration: none;
  opacity: 0.7;
}

.andlogi-archive-case-top {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .andlogi-archive-case-top {
    flex-wrap: wrap;
  }
}

.andlogi-archive-case-top:hover {
  text-decoration: none;
  opacity: 0.7;
}

.andlogi-archive-case-top img {
  height: 40px;
  align-self: baseline;
}

.andlogi-fixed {
  width: 160px;
  height: 160px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .andlogi-fixed {
    bottom: 20px;
    right: 20px;
  }
}

.andlogi-fixed-button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: #e60012;
  border-radius: 50%;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
}

.andlogi-fixed-button:hover {
  opacity: 0.7;
  text-decoration: none;
}

.andlogi-fixed-button::after {
  content: "";
  display: block;
  width: calc(100% - 7px);
  height: calc(100% - 7px);
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
}

.andlogi-fixed-button span {
  font-family: "nobel", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
}

.andlogi-task {
  display: block;
  height: 200px;
  filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.5));
  text-align: center;
  transition: all 0.3s;
}

@media screen and (max-width: 767px) {
  .andlogi-task {
    height: auto;
  }
  .andlogi-task .andlogi-h4 {
    filter: none !important;
  }
}

.andlogi-task:hover {
  text-decoration: none;
  opacity: 0.7;
}

.andlogi-task-wrapper {
  height: 100%;
  background: #fff;
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 0% 50%);
  position: relative;
}

@media screen and (max-width: 767px) {
  .andlogi-task-wrapper {
    height: 120px;
  }
  .andlogi-task-wrapper.mflp {
    height: 150px;
  }
}

.andlogi-task-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding-top: 8px;
  background: radial-gradient(#fff, #e5e5e5);
  clip-path: polygon(calc(15% + 4px) 0%, 85% 0%, 100% 50%, 85% 100%, calc(15% + 4px) 100%, 4px 50%);
}

.andlogi-task-contents.mflp {
  padding-top: 0;
  display: block;
}

@media screen and (max-width: 767px) {
  .andlogi-task-contents {
    display: block;
    padding-top: 0;
  }
  .andlogi-task-contents-image {
    height: 100%;
  }
  .andlogi-task-contents-image img {
    height: 100%;
  }
}

.andlogi-task-mflp {
  height: 200px;
}

.andlogi-task-button {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.05em;
  color: #fff;
  padding: 8px 16px;
  border: 1px solid #fff;
  transition: all 0.3s;
  position: absolute;
  bottom: 16px;
  right: calc(15% + 4px);
  background: #006390;
}

@media screen and (max-width: 767px) {
  .andlogi-task-button {
    position: static;
    margin-top: 8px;
  }
}

.archive-h1 {
  font-size: 2.5rem;
  line-height: 1.5;
  color: #006390;
  margin-bottom: 32px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .archive-h1 {
    font-size: 1.5rem;
    margin-bottom: 24px;
  }
}

.archive-h1 span {
  font-family: "nobel", sans-serif;
  font-weight: 400;
  font-style: normal;
  border-bottom: 2px solid #006390;
  padding: 0 0.5em;
  margin-right: 0.5em;
}

.archive-h3 {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  color: #006390;
  padding-bottom: 0.25em;
  border-bottom: 2px solid #006390;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .archive-h3 {
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
}

.archive-interview {
  position: relative;
}

.archive-interview-text {
  color: #006390;
}

.archive-interview img {
  display: block;
}

.archive-interview-name {
  position: absolute;
  z-index: 3;
  bottom: 16px;
  color: #fff;
}

.archive-interview-name span {
  font-size: 1.5rem;
}

.archive-interview-name--left {
  left: 16px;
  text-align: left;
}

.archive-interview-name--right {
  right: 16px;
  text-align: right;
}

.archive-mv {
  position: relative;
}

.archive-mv::before {
  content: "";
  display: block;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to top, #fff, #333);
  opacity: 0.6;
  mix-blend-mode: multiply;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .archive-mv::before {
    content: none;
  }
}

.archive-mv-contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  flex-direction: column;
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  .archive-mv-contents {
    position: static;
    display: block;
    background: #006390;
    padding: 16px 0;
  }
  .archive-mv-contents.tab {
    display: block;
  }
  .archive-mv-contents.sp {
    display: none;
  }
}

.archive-mv-contents p {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #fff;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .archive-mv-contents p {
    font-size: 1rem;
  }
}

.archive-mv-title {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .archive-mv-title {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }
}

.archive-bg {
  background: #eef1f9;
}

.archive-flow {
  margin-top: 40px;
}

.archive-flow-list {
  display: flex;
  margin: -5px;
}

@media screen and (max-width: 767px) {
  .archive-flow-list {
    flex-wrap: wrap;
  }
}

.archive-flow-list-item {
  padding: 5px;
  width: 25%;
}

@media screen and (max-width: 767px) {
  .archive-flow-list-item {
    width: 100%;
  }
}

.archive-flow-label {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  color: #006390;
  text-align: center;
}

.archive-flow-contents {
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  padding: 16px 32px;
  background: linear-gradient(to right, #006390 0%, #01628e 30%, #00a0e9 100%);
  position: relative;
}

.archive-flow-contents::before {
  content: "";
  display: block;
  width: 16px;
  height: 100%;
  background: linear-gradient(to bottom left, transparent 50%, #eef1f9 50%) top left/100% 50% no-repeat, linear-gradient(to top left, transparent 50%, #eef1f9 50%) bottom left/100% 50% no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .archive-flow-contents::before {
    width: 10px;
  }
}

.archive-flow-contents::after {
  content: "";
  display: block;
  width: 16px;
  height: 100%;
  background: linear-gradient(to bottom left, transparent 50%, #00a0e9 50%) top left/100% 50% no-repeat, linear-gradient(to top left, transparent 50%, #00a0e9 50%) bottom left/100% 50% no-repeat;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .archive-flow-contents::after {
    width: 10px;
  }
}

.archive-tab-nav {
  display: flex;
  margin: -10px;
}

.archive-tab-nav-item {
  width: calc(100% / 3);
  padding: 10px;
}

.archive-tab-nav-item.current a {
  background: #fff;
  box-shadow: 0px -4px 3px rgba(112, 132, 160, 0.3);
}

.archive-tab-nav-item a {
  display: block;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  color: #006390;
  padding: 16px 8px;
  background: #eef1f9;
  text-align: center;
  transition: all 0.3s;
}

.archive-tab-nav-item a:hover {
  background: #fff;
  box-shadow: 0px -4px 3px rgba(112, 132, 160, 0.3);
  text-decoration: none;
}

.archive-tab-contents {
  padding-top: 40px;
  box-shadow: 0 -8px 3px -3px rgba(112, 132, 160, 0.3);
}

.archive-button {
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  padding: 16px 32px;
  background: #006390;
  transition: all 0.3s;
}

.archive-button:hover {
  text-decoration: none;
  opacity: 0.7;
}

.cform-h2 {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.5;
  color: #014099;
  text-align: center;
  margin-bottom: 24px;
}

.cform-h3 {
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  color: #014099;
  margin-bottom: 16px;
}

.cform-link {
  transition: all 0.3s;
}

.cform-link:hover {
  text-decoration: none;
  opacity: 0.7;
}

.cform-link-line {
  display: flex;
  align-items: center;
  /* 線を中央揃えにしている */
  justify-content: flex-end;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  color: #014099;
  margin-bottom: 8px;
}

.cform-link-line::after {
  content: "";
  height: 1px;
  flex-grow: 1;
  background: #045f89;
  margin-left: 0.5em;
}

.cform-link-list > li {
  margin-bottom: 40px;
}

.cform-link-list > li:last-child {
  margin-bottom: 0;
}

.cform-list {
  margin: 40px 0;
  counter-reset: count 0;
}

.cform-list > li {
  line-height: 1.5;
  margin-bottom: 1em;
  padding-left: 1.5em;
  position: relative;
}

.cform-list > li::before {
  content: counter(count) ".";
  counter-increment: count 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.cform-list > li:last-child {
  margin-bottom: 0;
}

.cform-border-link {
  color: #014099;
  text-decoration: underline;
  transition: all 0.3s;
}

.cform-border-link:hover {
  opacity: 0.7;
}

.cform-button {
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  color: #fff;
  padding: 0.75em 3em;
  background: #014099;
  text-decoration: none;
  transition: all 0.3s;
}

.cform-button:hover {
  opacity: 0.7;
  text-decoration: none;
}

.solution-h2 {
  font-family: trajan-pro-3, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.5rem;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #014099;
  margin-bottom: 16px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .solution-h2 {
    font-size: 2rem;
  }
}

.solution-h2-catchcopy {
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #014099;
  margin-bottom: 16px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .solution-h2-catchcopy {
    font-size: 1rem;
  }
}

.solution-h3 {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #fff;
  padding: 16px;
  background: #014099;
  margin-bottom: 32px;
  text-align: center;
}

.solution-h4 {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #014099;
  text-align: center;
  margin-bottom: 40px;
}

.solution-map {
  position: relative;
}

.solution-map-wrapper {
  position: relative;
}

@media screen and (max-width: 767px) {
  .solution-map-wrapper {
    position: static;
  }
}

.solution-map--sp {
  display: none;
}

@media screen and (max-width: 767px) {
  .solution-map--sp {
    display: block;
  }
}

.solution-map--pc {
  display: block;
}

@media screen and (max-width: 767px) {
  .solution-map--pc {
    display: none;
  }
}

.solution-map-image {
  display: block;
  position: relative;
  z-index: -2;
}

.solution-map-list-item {
  width: calc(160 / 1920 * 100%);
  height: calc(160 / 1150 * 100%);
  position: absolute;
}

.solution-map-list-item::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: btn 1.25s linear infinite;
  z-index: -1;
}

@keyframes btn {
  0% {
    transform: scale(1, 1);
    background-color: rgba(1, 64, 153, 0.5);
  }
  100% {
    transform: scale(1.25, 1.25);
    background-color: rgba(1, 64, 153, 0);
  }
}

.solution-map-list-item.storing {
  top: calc(735 / (1150 - 80) * 100%);
  left: calc(1005 / (1920 - 80) * 100%);
}

.solution-map-list-item.carryin {
  top: calc(625 / (1150 - 80) * 100%);
  left: calc(1200 / (1920 - 80) * 100%);
}

.solution-map-list-item.arrival_inspection {
  top: calc(810 / (1150 - 80) * 100%);
  left: calc(1340 / (1920 - 80) * 100%);
}

.solution-map-list-item.conveyance {
  top: calc(550 / (1150 - 80) * 100%);
  left: calc(1515 / (1920 - 80) * 100%);
}

.solution-map-list-item.storage {
  top: calc(190 / (1150 - 80) * 100%);
  left: calc(1325 / (1920 - 80) * 100%);
}

.solution-map-list-item.picking {
  top: calc(230 / (1150 - 80) * 100%);
  left: calc(1110 / (1920 - 80) * 100%);
}

.solution-map-list-item.sorting {
  top: calc(430 / (1150 - 80) * 100%);
  left: calc(1060 / (1920 - 80) * 100%);
}

.solution-map-list-item.shipping_inspection {
  top: calc(370 / (1150 - 80) * 100%);
  left: calc(485 / (1920 - 80) * 100%);
}

.solution-map-list-item.packing {
  top: calc(240 / (1150 - 80) * 100%);
  left: calc(660 / (1920 - 80) * 100%);
}

.solution-map-list-item.carrying_out {
  top: calc(340 / (1150 - 80) * 100%);
  left: calc(285 / (1920 - 80) * 100%);
}

.solution-map-list-item.delivery {
  top: calc(540 / (1150 - 80) * 100%);
  left: calc(270 / (1920 - 80) * 100%);
}

.solution-map-list-item.system {
  top: calc(840 / (1150 - 80) * 100%);
  left: calc(155 / (1920 - 80) * 100%);
}

.solution-map-list-item.other {
  top: calc(840 / (1150 - 80) * 100%);
  left: calc(350 / (1920 - 80) * 100%);
}

.solution-map-list-item a {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}

.solution-map-list-item a::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #003d8b;
  mix-blend-mode: multiply;
  border-radius: 50%;
}

.solution-map-list-item a::after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 2px solid #fff;
  border-radius: 50%;
}

.solution-map-list-item a:hover {
  text-decoration: none;
  transform: scale(1.2);
}

.solution-map-list-item a span {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 700;
  font-size: 0.83vw;
  letter-spacing: 0.05em;
  color: #fff;
  position: relative;
  z-index: 2;
}

.solution-tab {
  display: none;
  max-width: 1200px;
  position: absolute;
  top: calc(100% - 24px);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  padding-top: 24px;
}

@media screen and (max-width: 767px) {
  .solution-tab[aria-hidden="false"] {
    display: block;
    position: static;
    width: 100%;
    max-width: auto;
    padding-top: 0;
  }
}

.solution-tab-nav {
  padding: 24px 0;
  background: rgba(1, 64, 153, 0.5);
  position: relative;
}

@media screen and (max-width: 1024px) {
  .solution-tab-nav {
    padding: 16px 0;
  }
}

@media screen and (max-width: 767px) {
  .solution-tab-nav {
    padding: 0;
  }
}

.solution-tab-nav-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .solution-tab-nav-wrapper {
    position: static;
  }
}

.solution-tab-nav-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .solution-tab-nav-inner {
    flex-wrap: wrap;
    width: 100%;
  }
  .solution-tab-nav-inner .solution-tab-nav-list {
    width: 100%;
  }
}

.solution-tab-nav-title {
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #fff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 767px) {
  .solution-tab-nav-title {
    font-size: 1rem;
    text-align: center;
    padding: 16px;
    width: 100%;
    background: #ccd9eb;
    line-height: 1.5;
  }
}

.solution-tab-nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .solution-tab-nav-list-item {
    border-bottom: 4px solid #014099;
  }
}

.solution-tab-nav-list-item:not(:last-child) > a {
  border-right: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  .solution-tab-nav-list-item:not(:last-child) > a {
    border-right: none;
  }
}

.solution-tab-nav-list-item:hover > a::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 24px 12px;
  border-color: transparent transparent #231815 transparent;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.3s;
}

@media screen and (max-width: 767px) {
  .solution-tab-nav-list-item:hover > a::after {
    content: none;
  }
}

.solution-tab-nav-list-item:hover .solution-tab {
  display: block;
}

@media screen and (max-width: 767px) {
  .solution-tab-nav-list-item {
    width: 50%;
  }
}

.solution-tab-nav-list-item > a {
  display: block;
  padding: 0 24px;
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #fff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  text-align: center;
  position: relative;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .solution-tab-nav-list-item > a {
    font-size: 1rem;
    padding: 16px;
    pointer-events: all;
  }
}

.solution-tab-nav-list-item > a:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .solution-tab-nav-list-item > a {
    background: #4b7ebf;
  }
  .solution-tab-nav-list-item > a[aria-selected="true"] {
    background-color: #014099;
  }
}

.solution-drop-wrapper {
  padding: 24px;
  background: #231815;
  border-radius: 16px;
}

@media screen and (max-width: 767px) {
  .solution-drop-wrapper {
    padding: 24px 15px;
    background: #ccd9eb;
    border-radius: 0;
  }
}

.solution-drop-link {
  display: block;
  font-size: 1.5rem;
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 700;
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .solution-drop-link {
    display: none;
  }
}

.solution-drop-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;
}

@media screen and (max-width: 767px) {
  .solution-drop-list {
    margin: -4px;
  }
}

.solution-drop-list-item {
  padding: 0.5em;
}

@media screen and (max-width: 767px) {
  .solution-drop-list-item {
    width: 50%;
    padding: 4px;
  }
}

.solution-drop-list-item:last-child a::after {
  content: none;
  margin-left: 0;
}

.solution-drop-list-item a {
  display: inline-block;
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 700;
  color: #fff;
  position: relative;
}

@media screen and (max-width: 767px) {
  .solution-drop-list-item a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4em;
    font-size: 0.75rem;
    line-height: 1.25;
    padding: 8px;
    text-align: center;
    letter-spacing: 0.05em;
    background-color: #014099;
    border-radius: 8px;
  }
}

.solution-drop-list-item a::after {
  content: "/";
  display: inline-block;
  margin-left: 1em;
}

@media screen and (max-width: 767px) {
  .solution-drop-list-item a::after {
    content: none;
  }
}

.solution-nav-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;
}

.solution-nav-list-item {
  padding: 0.5em;
}

.solution-nav-list-item:last-child a::after {
  content: none;
  margin-left: 0;
}

.solution-nav-list-item a {
  display: inline-block;
  position: relative;
}

.solution-nav-list-item a::after {
  content: "/";
  display: inline-block;
  margin-left: 1em;
}

.solution-filter {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.solution-filter-item {
  width: 20%;
  padding: 4px;
}

@media screen and (max-width: 1024px) {
  .solution-filter-item {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 767px) {
  .solution-filter-item {
    width: 50%;
  }
}

.solution-checkbox-input {
  display: none;
}

.solution-checkbox-input:checked + .solution-checkbox-span {
  color: #fff;
  background: #014099;
}

.solution-checkbox-span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4em;
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  padding: 8px;
  color: #014099;
  text-align: center;
  letter-spacing: 0.05em;
  background-color: #fff;
  border: 1px solid #014099;
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .solution-checkbox-span {
    font-size: 0.75rem;
  }
}

.solution-checkbox-span:hover {
  opacity: 0.6;
}

.solution-search-title {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 40px;
}

.solution-search-title span {
  font-size: 2.5rem;
  color: #014099;
}

.solution-search-product {
  padding: 40px 0;
  background: #fff;
  border-bottom: 4px solid #006390;
  margin-bottom: 80px;
}

.solution-search-product:last-child {
  margin-bottom: 0;
}

.solution-search-device {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.05em;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .solution-search-device {
    font-size: 1.5rem;
    margin-bottom: 0.5em;
  }
}

.solution-search-vendor {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
}

@media screen and (max-width: 767px) {
  .solution-search-vendor {
    font-size: 1rem;
  }
}

.solution-search-label {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #fff;
  padding: 8px 16px;
  background: #014099;
  border-radius: 4px;
  margin-bottom: 8px;
}

.solution-search-labels {
  margin-bottom: 8px;
}

.solution-search-result {
  margin-bottom: 32px;
}

.solution-search-result-headline {
  font-size: 1rem;
  line-height: 1.5;
  padding-bottom: 8px;
  border-bottom: 2px solid #014099;
  margin-bottom: 0.5em;
}

.solution-search-result-list > li {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.5em;
  padding-left: 1em;
  position: relative;
}

.solution-search-result-list > li::before {
  content: "・";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.solution-search-slider {
  margin-bottom: 0 !important;
}

.solution-search-slider-dots {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution-search-slider-dots > li {
  padding: 0 4px;
}

.solution-search-slider-dots > li button {
  display: block;
  width: 8px;
  height: 8px;
  font-size: 0;
  padding: 0;
  color: transparent;
  background: #c6c6c6;
  border-radius: 50%;
  border: none;
}

.solution-search-slider-dots > li.slick-active button {
  background: #9b9999;
}

.solution-button {
  font-family: "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  display: block;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #fff;
  padding: 1em;
  text-align: center;
  transition: all 0.3s;
}

@media screen and (max-width: 767px) {
  .solution-button {
    font-size: 1rem;
  }
}

.solution-button.download {
  background: #595757;
}

.solution-button.download-none {
  pointer-events: none;
  opacity: 0.25;
}

.solution-button.faq {
  background: #014099;
}

.solution-button.movie {
  background: #014099;
}

.solution-button:hover {
  text-decoration: none;
  opacity: 0.6;
}

.error {
  height: 100%;
}

.error-body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
}

.error-main {
  flex: 1;
}

.error-section {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .error-section {
    padding: 40px 0;
  }
}

.error-container {
  width: 800px;
  padding: 0 20px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .error-container {
    width: 100%;
  }
}

.error-header {
  margin-bottom: 48px;
}

@media screen and (max-width: 767px) {
  .error-header {
    margin-bottom: 32px;
  }
}

.error-header-logo {
  display: inline-block;
}

.error-header-logo:first-child {
  margin-right: 16px;
}

.error-header-logo:hover {
  text-decoration: none;
}

.error-header-logo img {
  height: 46px;
}

@media screen and (max-width: 767px) {
  .error-header-logo img {
    height: 30px;
  }
}

.error-text {
  font-size: 1.5rem;
  line-height: 1.35;
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .error-text {
    margin-bottom: 16px;
  }
}

.error-title {
  font-size: 2.75rem;
  line-height: 1.35;
  color: #014099;
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  .error-title {
    font-size: 2rem;
    margin-bottom: 24px;
  }
}

.new-search {
  position: fixed;
  bottom: 100px;
  right: 0;
  width: 240px;
  background: #014099;
  border-radius: 8px 0 0 8px;
  z-index: 100;
}

@media screen and (max-width: 767px) {
  .new-search {
    position: static;
    width: 100%;
    border-radius: 0;
  }
}

.new-search-contents {
  padding: 16px;
}

@media screen and (max-width: 767px) {
  .new-search-contents {
    padding: 16px 8px;
  }
}

.new-search-title {
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #fff;
  margin-bottom: 0.5em;
}

.new-search-title-small {
  font-size: 0.875rem;
  line-height: 1.25;
  color: #fff;
  text-align: center;
  margin-bottom: 1em;
}

@media screen and (max-width: 767px) {
  .new-search-nav-list {
    display: flex;
    margin: -8px;
  }
}

.new-search-nav-list-item {
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 767px) {
  .new-search-nav-list-item {
    width: calc(100% / 3);
    padding: 8px;
    margin-bottom: 0;
    border-bottom: none;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.new-search-nav-list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

@media screen and (max-width: 767px) {
  .new-search-nav-list-item:last-child {
    border-right: none;
  }
}

.new-search-nav-list-item a {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.25;
  color: #fff;
  transition: all 0.3s;
}

@media screen and (max-width: 767px) {
  .new-search-nav-list-item a {
    display: block;
    text-align: center;
    font-size: 0.875rem;
  }
}

.new-search-nav-list-item a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.new-search-nav-icon {
  margin-right: 16px;
}

@media screen and (max-width: 767px) {
  .new-search-nav-icon {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.new-search-nav-icon img {
  width: 75px;
}

.new-search-icon {
  margin-right: 16px;
}

@media screen and (max-width: 767px) {
  .new-search-icon {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.new-search-icon img {
  width: 75px;
}
