.p-image {
  position: relative;

  img {
    display: block;
  }

  &-caption {
    font-size: rem(12px);
    letter-spacing: 0;
    line-height: 1;
    text-align: right;
    color: $color-white;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
    position: absolute;

    &--top {
      top: 8px;
    }

    &--bottom {
      bottom: 8px;
    }

    &--left {
      left: 8px;
    }

    &--right {
      right: 8px;
    }
  }
}
