/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  background-color: $color-gray-light;

  &-list {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;

    @include view-at(sp) {
      flex-wrap: wrap;
    }

    li {
      font-size: rem(12px);
      &::after {
        content: ">";
        padding: 0 1em;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
