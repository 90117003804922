/* --------------------------
タブ
-------------------------- */

.c-tab {
  &-nav {
    display: flex;
    justify-content: center;
    margin: -10px;

    @include view-at(tab) {
      justify-content: flex-start;
      margin: -8px;
      flex-wrap: wrap;
    }

    &-item {
      width: 25%;
      padding: 10px;

      @include view-at(tab) {
        width: 50%;
        padding: 8px;
      }

      a {
        display: block;
        font-size: rem(16px);
        color: $color-black;
        line-height: 1.2;
        padding: 8px 16px;
        border: 1px solid $color-black;
        background-color: $color-white;
        text-align: center;
        text-decoration: none;

        @include view-at(sp) {
          font-size: rem(14px);
          padding: 12px 8px;
        }

        &[aria-selected="true"] {
          color: $color-white;
          background-color: #204099;
        }
      }
    }
  }

  &-contents {
    padding: 40px;
    background-color: $color-white;

    @include view-at(sp) {
      padding: 20px;
    }

    &-item {
      display: none;

      &[aria-hidden="false"] {
        display: block;
      }
    }
  }
}
