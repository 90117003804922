.cform {
  &-h2 {
    font-size: rem(32px);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height: 1.5;
    color: $color-blue;
    text-align: center;
    margin-bottom: 24px;
  }

  &-h3 {
    font-size: rem(24px);
    letter-spacing: 0.05em;
    line-height: 1.5;
    color: $color-blue;
    margin-bottom: 16px;
  }

  &-link {
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }

    &-line {
      display: flex;
      align-items: center; /* 線を中央揃えにしている */
      justify-content: flex-end;
      font-size: rem(24px);
      letter-spacing: 0.05em;
      line-height: 1.5;
      color: $color-blue;
      margin-bottom: 8px;

      &::after {
        content: "";
        height: 1px;
        flex-grow: 1;
        background: #045f89;
        margin-left: 0.5em;
      }
    }

    &-list {
      > li {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-list {
    margin: 40px 0;
    counter-reset: count 0;

    > li {
      line-height: 1.5;
      margin-bottom: 1em;
      padding-left: 1.5em;
      position: relative;

      &::before {
        content: counter(count) ".";
        counter-increment: count 1;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-border {
    &-link {
      color: $color-blue;
      text-decoration: underline;
      transition: all 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &-button {
    display: inline-block;
    font-size: rem(16px);
    letter-spacing: 0.05em;
    line-height: 1.5;
    color: $color-white;
    padding: 0.75em 3em;
    background: $color-blue;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
      text-decoration: none;
    }
  }
}
