.pickup {
  &-keyvisual {
    position: relative;

    img {
      display: block;
    }

    &-eyecatch {
      position: absolute;
      top: calc(100% - 80px);
      left: 10%;

      @include view-at(tab) {
        left: 10%;

        img {
          width: 70%;
        }
      }

      @include view-at(sp) {
        img {
          width: 100px;
        }
      }
    }

    &-pickup {
      position: absolute;
      bottom: -35px;
      right: 10%;

      @include view-at(sp) {
        width: 15%;
        right: 10%;
      }
    }
  }
  &-name {
    font-family: trajan-pro-3, serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;

    &--large {
      font-size: rem(40px);
      line-height: 1.5;
      letter-spacing: 0.2em;
      margin-bottom: 20px;

      @include view-at(tab){
        line-height: 1.25;
      }
    }

    &--small {
      font-size: rem(16px);
      letter-spacing: 0.15em;
      margin-bottom: 10px;
    }

    &-br{
      @media screen and (min-width: 1720px) {
        display: none;
      }
    }
  }

  &-access {
    margin-bottom: 10px;
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -4px;

      > li {
        display: inline-block;
        padding: 4px;
        line-height: 1.5;

        &::before {
          content: "■";
          display: inline-block;
          padding-right: 0.2em;
        }
      }
    }
  }

  &-outline {
    margin-bottom: 40px;

    @include view-at(sp) {
      margin-bottom: 20px;
    }

    &-title {
      font-size: rem(18px);
      line-height: 1.2;
      margin-bottom: 10px;
    }

    &-wrap {
      display: flex;

      @include view-at(sp) {
        flex-wrap: wrap;
      }
    }
  }

  &-h4 {
    font-size: rem(20px);
    line-height: 1.5;
    letter-spacing: 0.05em;
    color: #527586;
    margin-bottom: 16px;
  }

  
}
