.error {
  height: 100%;

  &-body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
  }

  &-main {
    flex: 1;
  }

  &-section {
    padding: 80px 0;

    @include view-at(sp) {
      padding: 40px 0;
    }
  }

  &-container {
    width: 800px;
    padding: 0 20px;
    margin: 0 auto;

    @include view-at(sp) {
      width: 100%;
    }
  }

  &-header {
    margin-bottom: 48px;

    @include view-at(sp) {
      margin-bottom: 32px;
    }

    &-logo {
      &:first-child {
        margin-right: 16px;
      }

      display: inline-block;

      &:hover {
        text-decoration: none;
      }

      img {
        height: 46px;

        @include view-at(sp) {
          height: 30px;
        }
      }
    }
  }

  &-text {
    font-size: rem(24px);
    line-height: 1.35;
    margin-bottom: 8px;

    @include view-at(sp) {
      margin-bottom: 16px;
    }
  }

  &-title {
    font-size: rem(44px);
    line-height: 1.35;

    color: $color-blue;
    margin-bottom: 32px;

    @include view-at(sp) {
      font-size: rem(32px);
      margin-bottom: 24px;
    }
  }
}
