/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  width: 100%;
  height: 100px;
  background: rgba(230, 230, 230, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  @include view-at(tab) {
    height: 50px;
  }

  &-inner {
    width: 1480px;
    max-width: 100%;
    margin: 0 auto;
    padding: 27px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include view-at(tab) {
      width: 100%;
      padding: 0;
      justify-content: flex-start;

      &--sp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 8px;

        .p-header-logo:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  &-logo {
    flex-shrink: 0;
    a {
      display: block;
      line-height: 1;

      img {
        display: block;
        height: 46px;

        @include view-at(tab) {
          height: 30px;
        }
      }
    }
  }
}
