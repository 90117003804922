/* --------------------------
フッター
-------------------------- */

.p-footer {
  margin-top: 30px;

  @include view-at(tab) {
    margin-top: 20px;
  }

  &-pagetop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 100;

    @include view-at(tab) {
      position: static;
      border-top: 1px solid $color-gray-snow;
    }

    a {
      display: block;
      width: 40px;
      height: 40px;
      background: $color-white;
      border: 2px solid $color-blue;
      position: relative;
      transition: all 0.3s;

      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-top: 3px solid $color-blue;
        border-right: 3px solid $color-blue;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(50% - 2.5px);
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: all 0.3s;
      }

      &:hover {
        background: $color-blue;

        &::before {
          border-color: $color-white;
        }
      }

      @include view-at(tab) {
        width: 100%;
        height: 30px;
        border: none;
      }
    }
  }

  &-banner {
    margin-bottom: 30px;

    @include view-at(tab) {
      margin-bottom: 10px;
    }

    &-container {
      width: 100%;
      max-width: 1020px;
      padding: 0 10px;
      margin: 0 auto;
    }

    &-list {
      margin: -5px;

      @include view-at(tab) {
        flex-wrap: wrap;
      }

      &-item {
        padding: 5px;
        text-align: center;

        a {
          display: block;
        }
      }
    }
  }

  &-cta {
    background: $color-blue;

    &-container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }

    &-list {
      height: 79px;
      display: flex;
      justify-content: space-between;
      background: $color-white;

      @include view-at(tab) {
        height: auto;
      }

      &-item {
        width: 50%;
        border-right: 1px solid $color-blue-dark;

        &:first-child {
          border-left: 1px solid $color-blue-dark;
        }

        &:last-child {
          a {
            pointer-events: none;

            @include view-at(sp) {
              pointer-events: all;
            }
          }
        }

        a {
          display: block;
          height: 79px;
          font-size: rem(19px);
          color: $color-white;
          background: $color-blue;

          @include view-at(tab) {
            height: 100%;
            font-size: rem(11px);
            padding: 12px 0;
          }

          &:hover {
            opacity: 0.7;
            text-decoration: none;
          }

          &.arrow {
            position: relative;

            &::after {
              content: "";
              display: block;
              width: 12px;
              height: 12px;
              border-top: 2px solid $color-white;
              border-right: 2px solid $color-white;
              transform: rotate(45deg);
              position: absolute;
              top: calc(50% - 6px);
              right: 20px;

              @include view-at(tab) {
                width: 8px;
                height: 8px;
                top: calc(50% - 4px);
                right: 10px;
              }
            }
          }
        }

        &-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          @include view-at(tab) {
            flex-direction: column;
          }

          .tel {
            font-family: "Cardo", serif;
            font-size: rem(20px);
            line-height: 1;
            margin-left: 20px;

            @include view-at(tab) {
              margin-left: 0;
              font-size: rem(10px);
              margin-top: 0.5em;
            }
          }

          .accent {
            font-size: rem(30px);
            line-height: 1;

            @include view-at(tab) {
              font-size: rem(13px);
            }
          }
        }
      }
    }
  }

  &-group {
    background: $color-gray-light;

    // .p-footer-nav:first-child {
    //   border-bottom: 1px solid $color-gray-snow;
    // }
  }

  &-nav {
    padding: 24px 0;

    &--upper {
      border-bottom: 1px solid $color-gray-snow;
    }

    &-fnav {
      display: flex;

      @include view-at(sp) {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: -16px;
      }

      &-item {
        flex-grow: 1;
        width: calc(100% / 8);
        border-left: 1px solid #666;

        @include view-at(tab) {
          width: auto;
        }

        @include view-at(sp) {
          width: 50%;
          padding: 8px;
          margin-bottom: 16px;
        }

        a {
          display: block;
          font-size: rem(12px);
          font-weight: $bold;
          padding: 0.5em 1em;

          @include view-at(sp) {
            padding: 0;
          }
        }
      }
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: center;

      @include view-at(sp) {
        flex-wrap: wrap;
        margin-bottom: -0.25em;
      }

      &-item {
        border-right: 1px solid #ccc;

        @include view-at(sp) {
          padding: 0.25em 0.5em;
          margin-bottom: 0.25em;
        }

        a {
          display: block;
          font-size: rem(12px);
          padding: 0 1em;

          @include view-at(sp) {
            padding: 0;
          }
        }
      }
    }

    &-partners {
      margin-top: 16px;

      &-list {
        display: flex;

        &-item {
          margin-bottom: 8px;

          &::after {
            content: ">";
            display: inline-block;
            margin-right: 0.5em;
          }

          &:last-child {
            &::after {
              content: none;
            }
          }

          a {
            display: inline-block;
            font-size: rem(12px);
            font-weight: $bold;
          }
        }
      }

      &-detail {
        display: flex;
        flex-wrap: wrap;
        margin: -4px;

        &-item {
          padding: 4px;

          &::after {
            content: "/";
            display: inline-block;
          }

          &:last-child {
            &::after {
              content: none;
            }
          }

          a {
            display: inline-block;
            font-size: rem(12px);
          }
        }
      }
    }
  }

  &-copyright {
    font-family: "Cardo", serif;
    font-size: rem(12px);
    color: $color-white;
    line-height: 1;
    padding: 18px 0;
    text-align: center;
    background-color: $color-gray-dark;

    @include view-at(sp) {
      font-size: rem(9px);
      padding: 10px 0;
    }
  }
}
