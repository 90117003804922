.p-detail {
  padding-top: 100px;
  background-color: $color-section;

  @include view-at(tab) {
    padding-top: 46px;
  }

  &-panel {
    background-color: $color-white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

    &-contents {
      padding-top: 0;
    }
  }

  &-mv {
    position: relative;

    img {
      display: block;
    }

    &-pickup {
      position: absolute;
      bottom: 0;
      right: 20%;

      @include view-at(sp) {
        width: 20%;
        right: 10%;
      }
    }
  }

  &-title {
    font-size: rem(18px);
    line-height: 1.5;
    border-bottom: 1px solid $color-black;
    letter-spacing: 0.1em;
    margin-bottom: 10px;

    @include view-at(sp) {
      font-size: rem(16px);
    }
  }

  &-table {
    width: 100%;
    font-size: rem(16px);
    line-height: 1.6;

    @include view-at(sp) {
      font-size: rem(14px);
      line-height: 1.4;
    }

    tbody {
      th {
        width: 30%;
        font-weight: $normal;
        color: $color-blue;
        padding: 4px 0;
      }

      td {
        width: 70%;
        padding: 4px 0;
      }
    }
  }

  &-map {
    width: 100%;
    height: 450px;
  }
}
