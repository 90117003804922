/* --------------------------
パネル
-------------------------- */

.c-panel {
  font-family: $sans-serif;
  background-color: #204099;
  border: 2px solid $color-black;

  &.height {
    display: flex;
    height: 195px;

    .c-panel-contents {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }

  &-h {
    font-size: rem(24px);
    line-height: 1.5;
    margin-bottom: 10px;
  }

  // &-section {
  //   background-color: $color-gray-snow;

  //   &-h {
  //     padding: 20px;
  //     background: $color-black;
  //     color: $color-white;
  //   }

  //   &-contents {
  //     padding: 20px;
  //   }
  // }

  &-contents {
    color: $color-white;
    padding: 40px 24px;

    @include view-at(sp) {
      padding: 20px;
    }

    &--narrow {
      padding: 15px;
    }
  }
}
