.quality {
  &-keyvisual {
    position: relative;
    padding-top: calc(((1400 / 1920) * 100%) + 5.5vw);

    @include view-at(tab) {
      padding-top: calc(((750 / 750) * 100%) + 400px);
    }

    @include view-at(tab) {
      padding-top: calc(((750 / 750) * 100%) + 150px);
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-image: url(../images/quality/hero.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;

      @include view-at(tab) {
        background-image: url(../images/quality/hero--sp.jpg);
        top: auto;
        bottom: 0;
      }

      @include view-at(sp) {
        background-position: center bottom;
        background-size: 100%;
      }
    }

    &-mv {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: transparent;
    }

    &-section {
      &-container {
        padding-bottom: 100px;

        @include view-at(sp) {
          padding-bottom: 50px;
        }
      }
    }
  }

  &-bg {
    padding-top: 1px;
    position: relative;
    background: #edeee1;

    &::before {
      content: "";
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
      display: inline-block;
      height: 15.65vw;
      width: 100%;
      position: absolute;
      bottom: calc(100% - 2px);
      left: 0;
      z-index: 1;

      @include view-at(tab) {
        height: 16vw;
      }
    }

    &::before {
      content: "";
      background-image: url("../images/quality/curve.svg");
    }

    &--white {
      background: $color-white;

      &::before {
        content: "";

        background-image: url("../images/quality/curve--white.svg");
      }
    }
  }

  &-section {
    margin-top: -8vw;
    padding-bottom: 20vw;
    position: relative;
    z-index: 1;

    @include view-at(tab) {
      margin-top: -50px;
      padding-bottom: 150px;
    }
  }

  &-eyecatch {
    position: relative;
    padding-top: 10em;

    @include view-at(tab) {
      padding-top: 0;
    }

    &-text {
      width: 350px;
      height: 300px;
      color: $color-white;
      background-image: url(../images/quality/eyecatch.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      position: absolute;
      top: 0;

      @include view-at(tab) {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        position: static;
        background-image: none;
        background-color: $color-blue;
      }

      &--right {
        right: 0;
      }

      &--left {
        left: 0;
      }

      &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 300px;

        @include view-at(tab) {
          height: auto;
          padding: 2em;
        }
      }
    }
  }
}
