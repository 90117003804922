/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: block;
  font-size: rem(28px);
  line-height: 1.75;
  color: $color-white;
  letter-spacing: 0.1em;
  padding: 8px 16px;
  background: #003d8b;
  border-radius: 50px;
  text-align: center;

  @include view-at(sp) {
    font-size: rem(22px);
  }

  &:hover {
    opacity: 0.7;
    color: $color-white;
  }

  &--large {
    width: 100%;
  }
}
