html {
  font-size: 16px;
}

body {
  font-family: $serif;
  color: $color-black;
  font-size: rem(16px);
  line-height: 1.625;
  letter-spacing: 0.02em;
}

a {
  color: $color-black;
  text-decoration: none;

  &:hover {
    // color: $color-blue;
    // opacity: 0.6;
    text-decoration: underline;
  }
}

p {
  font-weight: $normal;
  line-height: 1.625;
  letter-spacing: 0.02em;
  text-align: justify;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  &--base {
    padding-top: 100px;

    @include view-at(tab) {
      padding-top: 50px;
    }
  }
}
