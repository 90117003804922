.solution {
  &-h2 {
    font-family: trajan-pro-3, serif;
    font-weight: 400;
    font-style: normal;
    font-size: rem(56px);
    line-height: 1;
    letter-spacing: 0.1em;
    color: $color-blue;
    margin-bottom: 16px;
    text-align: center;

    @include view-at(sp) {
      font-size: rem(32px);
    }

    &-catchcopy {
      font-size: rem(24px);
      line-height: 1.5;
      letter-spacing: 0.1em;
      color: $color-blue;
      margin-bottom: 16px;
      text-align: center;

      @include view-at(sp) {
        font-size: rem(16px);
      }
    }
  }

  &-h3 {
    font-family: $sans-serif;
    font-size: rem(24px);
    font-weight: $bold;
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: $color-white;
    padding: 16px;
    background: $color-blue;
    margin-bottom: 32px;
    text-align: center;
  }

  &-h4 {
    font-family: $sans-serif;
    font-size: rem(20px);
    line-height: 1.5;
    font-weight: $bold;
    letter-spacing: 0.1em;
    color: $color-blue;
    text-align: center;
    margin-bottom: 40px;
  }

  &-map {
    position: relative;

    &-wrapper {
      position: relative;

      @include view-at(sp) {
        position: static;
      }
    }

    &--sp {
      display: none;

      @include view-at(sp) {
        display: block;
      }
    }

    &--pc {
      display: block;

      @include view-at(sp) {
        display: none;
      }
    }

    &-image {
      display: block;
      position: relative;
      z-index: -2;
    }

    &-list {
      &-item {
        width: calc(160 / 1920 * 100%);
        height: calc(160 / 1150 * 100%);
        position: absolute;

        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          border-radius: 50%;
          animation: btn 1.25s linear infinite;
          z-index: -1;

          @keyframes btn {
            0% {
              transform: scale(1, 1);
              background-color: rgba($color-blue, 0.5);
            }

            100% {
              transform: scale(1.25, 1.25);
              background-color: rgba($color-blue, 0);
            }
          }
        }

        &.storing {
          top: calc(735 / (1150 - 80) * 100%);
          left: calc(1005 / (1920 - 80) * 100%);
        }
        &.carryin {
          top: calc(625 / (1150 - 80) * 100%);
          left: calc(1200 / (1920 - 80) * 100%);
        }
        &.arrival_inspection {
          top: calc(810 / (1150 - 80) * 100%);
          left: calc(1340 / (1920 - 80) * 100%);
        }
        &.conveyance {
          top: calc(550 / (1150 - 80) * 100%);
          left: calc(1515 / (1920 - 80) * 100%);
        }
        &.storage {
          top: calc(190 / (1150 - 80) * 100%);
          left: calc(1325 / (1920 - 80) * 100%);
        }
        &.picking {
          top: calc(230 / (1150 - 80) * 100%);
          left: calc(1110 / (1920 - 80) * 100%);
        }
        &.sorting {
          top: calc(430 / (1150 - 80) * 100%);
          left: calc(1060 / (1920 - 80) * 100%);
        }
        &.shipping_inspection {
          top: calc(370 / (1150 - 80) * 100%);
          left: calc(485 / (1920 - 80) * 100%);
        }
        &.packing {
          top: calc(240 / (1150 - 80) * 100%);
          left: calc(660 / (1920 - 80) * 100%);
        }
        &.carrying_out {
          top: calc(340 / (1150 - 80) * 100%);
          left: calc(285 / (1920 - 80) * 100%);
        }
        &.delivery {
          top: calc(540 / (1150 - 80) * 100%);
          left: calc(270 / (1920 - 80) * 100%);
        }

        &.system {
          top: calc(840 / (1150 - 80) * 100%);
          left: calc(155 / (1920 - 80) * 100%);
        }
        &.other {
          top: calc(840 / (1150 - 80) * 100%);
          left: calc(350 / (1920 - 80) * 100%);
        }

        a {
          display: block;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          transition: all 0.3s;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #003d8b;
            mix-blend-mode: multiply;
            border-radius: 50%;
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 4px;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            border: 2px solid $color-white;
            border-radius: 50%;
          }

          &:hover {
            text-decoration: none;
            transform: scale(1.2);
          }

          span {
            font-family: $sans-serif;
            font-weight: $bold;
            font-size: 0.83vw;
            letter-spacing: 0.05em;
            color: $color-white;
            position: relative;
            z-index: 2;
          }
        }
      }
    }
  }

  &-tab {
    display: none;
    max-width: 1200px;
    position: absolute;
    top: calc(100% - 24px);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    padding-top: 24px;

    @include view-at(sp) {
      &[aria-hidden="false"] {
        display: block;
        position: static;
        width: 100%;
        max-width: auto;
        padding-top: 0;
      }
    }

    &-nav {
      padding: 24px 0;
      background: rgba($color-blue, 0.5);
      position: relative;

      @include view-at(tab) {
        padding: 16px 0;
      }

      @include view-at(sp) {
        padding: 0;
      }

      &-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;

        @include view-at(sp) {
          position: static;
        }
      }

      &-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include view-at(tab) {
          flex-wrap: wrap;
          width: 100%;

          .solution-tab-nav-list {
            width: 100%;
          }
        }
      }

      &-title {
        font-size: rem(24px);
        line-height: 1;
        letter-spacing: 0.1em;
        color: $color-white;
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);

        @include view-at(sp) {
          font-size: rem(16px);
          text-align: center;
          padding: 16px;
          width: 100%;
          background: #ccd9eb;
          line-height: 1.5;
        }
      }

      &-list {
        display: flex;
        align-items: center;
        justify-content: center;

        &-item {
          @include view-at(sp) {
            border-bottom: 4px solid $color-blue;
          }
          &:not(:last-child) > a {
            border-right: 1px solid $color-white;

            @include view-at(sp) {
              border-right: none;
            }
          }

          &:hover {
            > a::after {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 12px 24px 12px;
              border-color: transparent transparent #231815 transparent;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              margin: 0 auto;
              transition: all 0.3s;

              @include view-at(sp) {
                content: none;
              }
            }

            .solution-tab {
              display: block;
            }
          }

          @include view-at(sp) {
            width: 50%;
          }

          > a {
            display: block;
            padding: 0 24px;
            font-family: $sans-serif;
            font-size: rem(24px);
            font-weight: $bold;
            line-height: 1;
            letter-spacing: 0.1em;
            color: $color-white;
            text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
            text-align: center;
            position: relative;
            pointer-events: none;

            @include view-at(sp) {
              font-size: rem(16px);
              padding: 16px;
              pointer-events: all;
            }

            &:hover {
              text-decoration: none;
            }

            @include view-at(sp) {
              background: #4b7ebf;

              &[aria-selected="true"] {
                background-color: $color-blue;
              }
            }
          }
        }
      }
    }
  }

  &-drop {
    &-wrapper {
      padding: 24px;
      background: #231815;
      border-radius: 16px;

      @include view-at(sp) {
        padding: 24px 15px;
        background: #ccd9eb;
        border-radius: 0;
      }
    }

    &-link {
      display: block;
      font-size: rem(24px);
      font-family: $sans-serif;
      font-weight: $bold;
      color: $color-white;
      margin-bottom: 16px;

      @include view-at(sp) {
        display: none;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -0.5em;

      @include view-at(sp) {
        margin: -4px;
      }

      &-item {
        padding: 0.5em;

        @include view-at(sp) {
          width: 50%;
          padding: 4px;
        }

        &:last-child {
          a {
            &::after {
              content: none;
              margin-left: 0;
            }
          }
        }

        a {
          display: inline-block;
          font-family: $sans-serif;
          font-weight: $bold;
          color: $color-white;
          position: relative;

          @include view-at(sp) {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4em;
            font-size: rem(12px);
            line-height: 1.25;
            padding: 8px;
            text-align: center;
            letter-spacing: 0.05em;
            background-color: $color-blue;
            border-radius: 8px;
          }

          &::after {
            content: "/";
            display: inline-block;
            margin-left: 1em;

            @include view-at(sp) {
              content: none;
            }
          }
        }
      }
    }
  }

  &-nav {
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -0.5em;

      &-item {
        padding: 0.5em;

        &:last-child {
          a {
            &::after {
              content: none;
              margin-left: 0;
            }
          }
        }

        a {
          display: inline-block;
          position: relative;

          &::after {
            content: "/";
            display: inline-block;
            margin-left: 1em;
          }
        }
      }
    }
  }

  &-filter {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;

    &-item {
      width: 20%;
      padding: 4px;

      @include view-at(tab) {
        width: calc(100% / 3);
      }

      @include view-at(sp) {
        width: 50%;
      }
    }
  }

  &-checkbox {
    &-input {
      display: none;

      &:checked + .solution-checkbox-span {
        color: $color-white;
        background: $color-blue;
      }
    }

    &-span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4em;
      font-family: $sans-serif;
      font-size: rem(16px);
      font-weight: $bold;
      line-height: 1.25;
      padding: 8px;
      color: $color-blue;
      text-align: center;
      letter-spacing: 0.05em;
      background-color: $color-white;
      border: 1px solid $color-blue;
      border-radius: 8px;
      transition: all 0.3s;
      cursor: pointer;

      @include view-at(sp) {
        font-size: rem(12px);
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &-search {
    &-title {
      font-family: $sans-serif;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      font-size: rem(24px);
      font-weight: $bold;
      line-height: 1.5;
      margin-bottom: 40px;

      span {
        font-size: rem(40px);
        color: $color-blue;
      }
    }

    &-product {
      padding: 40px 0;
      background: $color-white;
      border-bottom: 4px solid #006390;
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-device {
      font-family: $sans-serif;
      font-size: rem(32px);
      font-weight: $bold;
      line-height: 1.25;
      letter-spacing: 0.05em;
      margin-bottom: 16px;

      @include view-at(sp) {
        font-size: rem(24px);
        margin-bottom: 0.5em;
      }
    }

    &-vendor {
      font-family: $sans-serif;
      font-size: rem(24px);
      line-height: 1.5;
      letter-spacing: 0.1em;
      margin-bottom: 1em;

      @include view-at(sp) {
        font-size: rem(16px);
      }
    }

    &-label {
      display: inline-block;
      font-size: rem(14px);
      line-height: 1.25;
      color: $color-white;
      padding: 8px 16px;
      background: $color-blue;
      border-radius: 4px;
      margin-bottom: 8px;

      &s {
        margin-bottom: 8px;
      }
    }

    &-result {
      margin-bottom: 32px;

      &-headline {
        font-size: rem(16px);
        line-height: 1.5;
        padding-bottom: 8px;
        border-bottom: 2px solid $color-blue;
        margin-bottom: 0.5em;
      }

      &-list {
        > li {
          font-size: rem(14px);
          line-height: 1.5;
          margin-bottom: 0.5em;
          padding-left: 1em;
          position: relative;

          &::before {
            content: "・";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    &-slider {
      margin-bottom: 0 !important;
      &-dots {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        > li {
          padding: 0 4px;

          button {
            display: block;
            width: 8px;
            height: 8px;
            font-size: 0;
            padding: 0;
            color: transparent;
            background: #c6c6c6;
            border-radius: 50%;
            border: none;
          }

          &.slick-active {
            button {
              background: #9b9999;
            }
          }
        }
      }
    }
  }

  &-button {
    font-family: $sans-serif;
    display: block;
    font-size: rem(20px);
    font-weight: $bold;
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: $color-white;
    padding: 1em;
    text-align: center;
    transition: all 0.3s;

    @include view-at(sp) {
      font-size: rem(16px);
    }

    &.download {
      background: #595757;

      &-none {
        pointer-events: none;
        opacity: 0.25;
      }
    }

    &.faq {
      background: $color-blue;
    }

    &.movie {
      background: $color-blue;
    }

    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
  }
}
