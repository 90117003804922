/* --------------------------
見出し
-------------------------- */

.c-h1 {
  font-size: rem(36px);
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 40px;

  @include view-at(sp) {
    font-size: rem(24px);
    margin-bottom: 20px;
  }
}

.c-h2 {
  font-size: rem(32px);
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 40px;

  @include view-at(sp) {
    font-size: rem(20px);
    margin-bottom: 24px;
  }
}

.c-h3 {
  font-size: rem(28px);
  line-height: 1.75;
  color: $color-white;
  letter-spacing: 0.1em;
  padding: 8px 16px;
  background: #003d8b;
  border-radius: 50px;
  box-shadow: 7px 7px 5px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-bottom: 40px;

  @include view-at(sp) {
    font-size: rem(22px);
    line-height: 1.5;
  }
}

.c-h4 {
  font-size: rem(24px);
  line-height: 1.5;
  color: #204099;
  letter-spacing: 0.1em;
  margin-bottom: 10px;

  @include view-at(sp) {
    font-size: rem(18px);
  }
}

.c-h5 {
  font-size: rem(18px);
  line-height: 1.5;
  border-bottom: 1px solid $color-black;
  letter-spacing: 0.1em;
  margin-bottom: 10px;

  @include view-at(sp) {
    font-size: rem(16px);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}
